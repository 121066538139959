import { APPROVE_SELLER_FAILED } from "redux/actions/types";
import { POST_SUBSCRIPTION_FAILED } from "redux/actions/types";
import { POST_SUBSCRIPTION_SUCCESS } from "redux/actions/types";
import { APPROVE_SELLER_SUCCESS } from "redux/actions/types";
import {
    GET_SELLER_USER_SUCCESS,
    GET_SELLER_USER_FAILED,
    GET_SELLER_SERVICE_SUCCESS,
    GET_SELLER_SERVICE_FAILED,
    GET_SELLER_STAFF_SUCCESS,
    GET_SELLER_STAFF_FAILED,
} from "redux/actions/types";

const initialState = {
    error: null,
    getAllSellerUser: null,
    getServiceSeller: null,
    getServiceSellerError: null,
    approveSeller: null,
    getSellerStaff:null,
    errorSellerStaff:null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SELLER_USER_SUCCESS:
            return {
                getAllSellerUser: action.payload,
                error: null
            }
        case GET_SELLER_USER_FAILED:
            return {
                error: action.payload,
            }
        case GET_SELLER_SERVICE_SUCCESS:
            return {
                getServiceSeller: action.payload,
                error: null,
            }
        case GET_SELLER_SERVICE_FAILED:
            return {
                getServiceSellerError: action.payload,
                // error: action.payload
            }
        case APPROVE_SELLER_SUCCESS:
            return {
                ...state,
                approveSeller: action.payload,
                error: null
            }
        case APPROVE_SELLER_FAILED:
            return {
                ...state,
                error: action.payload
            }
        case GET_SELLER_STAFF_SUCCESS:
            return {
                getSellerStaff: action.payload,
                error: null,
            }
        case GET_SELLER_STAFF_FAILED:
            return {
                errorSellerStaff: action.payload,
                // error: action.payload
            }
        case POST_SUBSCRIPTION_SUCCESS: 
            return {
                subscription: action.payload,
                error:null
            }
        case POST_SUBSCRIPTION_FAILED:
            return {
                error: action.payload,
            }
        default: return state;
    }
};
