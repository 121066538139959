import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { notValid } from "utils/validations";
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid'; 
import { createSubcription, getOneSubcriptions , updateSubcription } from "redux/actions/subcription.action";


const initialFields = { name: '', amount: '' , discount : '' ,  subscriptionTenure: '', stripeSubscriptionPlanId : '' , status:'' }

function CreateOrUpdateSubcription() {
  /**************************  Routing Mangement *********************************/
  const { id } = useParams();
  /**************************  state Management  ***********************************/
  const [subcription , setSubcription] = useState(initialFields) ;
  const [errors , setErrors] = useState({}) ;
  const [ isEditable, setIsEditable ] = useState(id ?  true : false);
  const [ loading, setLoading ] = useState({ init: id ? true: false, createUpdate: false});
  /**************************   Redux Management *************************************/
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /*************************    Reducer State  ************************************/
  const subcriptionState = useSelector(state => state.subcription);
  /*************************   component life_cycle  *******************************************/

  useEffect(() => {
    if(id){ 
        setIsEditable(true);
        dispatch(getOneSubcriptions(id));
    }else{
        setIsEditable(false);
    }
  }, [id])

  useEffect(()=>{
    if( subcriptionState.error ){
      toast.error(subcriptionState.error.message) 
      setLoading({ createUpdate: false, init: false })
    }
    if(!subcriptionState.error){
      const {getOneSubcription , addSubcription , updateSubcription} = subcriptionState
      if(getOneSubcription &&  getOneSubcription.success){
        setSubcription({...getOneSubcription.subscriptionPlan});
        setLoading({ init: false });
      }
      if(addSubcription && addSubcription.success){  
        toast.success(addSubcription.message);
        setLoading({ createUpdate: false })
        setSubcription({...initialFields});
      }
      if(updateSubcription && updateSubcription.success){
        toast.success(updateSubcription.message);
        setLoading({ createUpdate: false })
      }
    }

  },[subcriptionState])

  /**************************************   Form handling functions ******************************************************/

  const handleChange = (e)=>{
    const {value , name} = e.target ;
    setErrors({ ...errors, [name]: ''});
    if (value === '') {
      setSubcription((prevSubscription) => ({
        ...prevSubscription,
        [name]: value,
      }));
    } else if ((name === 'amount' || name === 'discount') && !/^\d+(\.\d{0,2})?$/.test(value)) {  
      // setErrors({ ...errors, [name]: `Please enter a valid ${name}` });
      setSubcription((prevSubscription) => ({
        ...prevSubscription
      }));
    } else {
      setSubcription((prevSubscription) => ({
        ...prevSubscription,
        [name]: value,
      }));
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isValid()) {
        setLoading({ createUpdate: true});
        const amount = parseInt(subcription.amount);
        const discount = parseInt(subcription.discount);
        if(isEditable){
          const apiData = {
            ...subcription,
            amount: amount,
            discount: discount,
          };
          dispatch(updateSubcription(id, apiData) );
        }else{
          const apiData = {
            ...subcription,
            // stripeSubscriptionPlanId: generateSubscriptionPlanId(),
            amount: amount,
            discount: discount,
          };
          dispatch(createSubcription(apiData))
        }
    }
  }


  /*******************************   Helping functions  *********************************************/

  const generateSubscriptionPlanId = () => {
    // Generate a unique identifier using uuid
    return `price_${uuidv4()}`;
  };

  const isValid = () => {
    let isValid = true
    let err = { ...errors }
    const check = [null, undefined, 'null', 'undefined', '']
    if (notValid(subcription.name)) {
      err.name = "Please provide subcription name"
      isValid = false
    }
    if(check.includes(subcription.discount)){
      err.discount = "Please provide  subcription discount "
      isValid = false 
    }
    if (check.includes(subcription.amount)) {
      err.amount = "Please provide subcription amount "
      isValid = false
    }
    if (notValid(subcription.status)) {
      err.status = "Please select subcription status "
      isValid = false
    }
    if (notValid(subcription.subscriptionTenure)) {
      err.subscriptionTenure = "Please select subcription tenure "
      isValid = false
    }
    setErrors({ ...err })
    return isValid
  }

  return (
    <div className='main-add-service'>
      <h2>Add Subcription</h2>
      <Button onClick={() => navigate('/subcriptions/list')}>View Services</Button>
      <div className="form-add-service">
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Subcription Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter subscription name"
              name="name"
              value={subcription.name || ''}
              onChange={(e)=>handleChange(e)}
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Subcription Amount</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter subscription amount"
              name="amount"
              value={subcription.amount || ''}
              onChange={handleChange}
            />
            {errors.amount && <span className="error">{errors.amount}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Subcription Discount</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter subscription discount"
              name="discount"
              value={subcription.discount || ''}
              onChange={handleChange}
            />
            {errors.discount && <span className="error">{errors.discount}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Subcription Tenure</Form.Label>
              <Form.Select 
                aria-label="Default select example"
                name="subscriptionTenure"
                value={subcription.subscriptionTenure || ''}
                onChange={handleChange}
              >
                <option>Select Tenure</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </Form.Select>
              {errors.subscriptionTenure && <span className="error">{errors.subscriptionTenure}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Subcription Status</Form.Label>
              <Form.Select 
                aria-label="Default select example"
                name="status"
                value={subcription.status || ''}
                onChange={handleChange}
              >
                <option>Select Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
              {errors.status && <span className="error">{errors.status}</span>}
          </Form.Group>
          <div className="text-center add-service-btn">
            <Button 
              variant="primary" 
              type="submit" 
              disabled={loading.createUpdate}
            >
              {loading.createUpdate 
                ? 'Please Wait...' 
                : isEditable 
                  ? 'Update' 
                  : 'Add'
              }
            </Button>
        </div>
        </Form>
      </div>
    </div>
  )
}

export default CreateOrUpdateSubcription