import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Form, Accordion, Card,Dropdown } from "react-bootstrap";
import { getAllsettings, updateSettings } from "../../redux/actions/setting.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";

import { RotatingLines } from 'react-loader-spinner'
import { BiEdit } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingView from "components/LoadingView";
import { showPecentage } from "utils/validations";

function List() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [serviceData, setServicesData] = useState([])  //////  services all Data

    const settingState = useSelector(state => state.settings);

    // const ServicesDataAll = useSelector(state => state.settings.getAllSettings)
    const fetchSettings = () => {
        dispatch(getAllsettings())
    }
    useEffect(() => {
        fetchSettings()
    }, [])

    useEffect(() => {
        if(settingState.error){
            toast.error(settingState.error.message || 'Network Error'); 
            setLoading(false)        
        }

        if(!settingState.error){
            if(settingState.all && settingState.all.success){
                setServicesData(settingState.all.settings);
                setLoading(false)
            }

            if( settingState.updated && settingState.updated.success){
                toast.success(settingState.updated.message)
                setLoading(false)
                fetchSettings()
                // setTimeout(()=>{
                //     navigate('/setting/list');
                // }, 1000);
            }
        }

    }, [settingState])

    const handleUpdateActiveStatus = (item) => {
        let status = !item.is_active
        setServicesData(serviceData.map(set => {
            if (set._id === item._id) {
                // Update the status of the item with the matching _id
                set.is_active = status ; // Replace 'updatedStatus' with the desired status
            }
            return set;
        }));
        // console.log( item )
        dispatch(updateSettings({ name: item.name, is_active: status}))
    }

    return (
        <section className="popular--Wrapper edit--page services-page-all">
            <Container fluid>
                <Row>
                    <Col sm={12} className="px-0">
                        <div className="sidebar--wrapper">
                            <div className="admin--content">
                                <Container>
                                    <Row>
                                        <div className="all-servicess-Wrap">
                                            <h2>Settings</h2>
                                        </div>
                                        {loading 
                                            ?   <LoadingView />
                                            :   <Accordion defaultActiveKey="0" className="services-main-wraper">
                                                    {(serviceData && serviceData?.length) 
                                                        ?   <Table striped responsive="sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Label</th>
                                                                        {/* <th>Unique Key</th> */}
                                                                        <th>Value</th>
                                                                        <th>Status</th>
                                                                        <th className="edit-service">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {serviceData.map((item, ind) => {
                                                                    return (
                                                                        <tr key={ind}>
                                                                            <td>{ind+1}</td>
                                                                            <td>{item?.label}</td>
                                                                            {/* <td>{item?.name}</td> */}
                                                                            <td>{item.is_fees_field 
                                                                                ? `${item.value}${showPecentage(item?.type)}`
                                                                                : '-'}</td>
                                                                            <td>
                                                                            <Form.Check // prettier-ignore
                                                                                type="switch"
                                                                                id="custom-switch"
                                                                                label={item.is_active ? 'Active' : "Inactive"}
                                                                                checked={item.is_active}
                                                                                onChange={() => handleUpdateActiveStatus(item)}
                                                                            />
                                                                            </td>
                                                                            <td className="edit-service"> 
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                                        <AiOutlineEye />
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item 
                                                                                            onClick={() => navigate(`/update/setting/:${item._id}`,
                                                                                            { state: { item }}
                                                                                            )}
                                                                                        >
                                                                                            <BiEdit /> Edit
                                                                                        </Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </td>
                                                                        </tr>
                                                                        )   
                                                                    })}
                                                                 </tbody>
                                                            </Table>
                                                        :   <div>
                                                                <h2>No Records Found</h2>
                                                            </div>
                                                    }
                                                </Accordion>
                                        }
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default List;