    import { GET_CATEGORY_SUCCESS } from "redux/actions/types";
import { RESET_CATEGORY_STATE } from "redux/actions/types";
import { GET_CATEGORY_FAILED } from "redux/actions/types";
import {
        GET_CATEGORIES_SUCCESS,
        GET_CATEGORIES_FAILED,
        POST_CATEGORY_SUCCESS,
        POST_CATEGORY_FAILED,
        PUT_CATEGORY_SUCCESS,
        PUT_CATEGORY_FAILED,
        DELETE_CATEGORY_SUCCESS,
        DELETE_CATEGORY_FAILED            
    } from "redux/actions/types";
    
    const initialState = {
        error: null,
        all: null,
        update: null,
        create: null,
        deleted: null
    };
    
    export default (state = initialState, action) => {
        switch (action.type) {
            case GET_CATEGORIES_SUCCESS:
                return {
                    all: action.payload,
                    error: null
                }
            case GET_CATEGORIES_FAILED:
                return {
                    error: action.payload
                }
            case GET_CATEGORY_SUCCESS:
                return {
                    getOne: action.payload,
                    error: null
                }
            case GET_CATEGORY_FAILED:
                return {
                    error: action.payload
                }
            case POST_CATEGORY_SUCCESS:
                return {
                    create: action.payload,
                    error: null
                }
            case POST_CATEGORY_FAILED:
                return {
                    error: action.payload
                }
            case PUT_CATEGORY_SUCCESS:
                return {
                    update: action.payload,
                    error: null
                }
            case PUT_CATEGORY_FAILED:
                return {
                    error: action.payload
                }
            case DELETE_CATEGORY_SUCCESS:
                return {
                    deleted: action.payload,
                    error: null
                }
            case DELETE_CATEGORY_FAILED:
                return {
                    error: action.payload
                }
            case RESET_CATEGORY_STATE:
                return {
                    error: null,
                    all: null,
                    update: null,
                    create: null,
                    deleted: null
                }
            default: return state;
        }
    };
    