import React, { useState, useEffect } from 'react';
import CustomMap from 'commonComponents/CustomMap';
// import userServices from 'utils/userServices.json' //mock data
import API from 'helpers/api';
import LoadingView from 'components/LoadingView';

const MapView = () => {
    const [loader, setLoader] = useState(true);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        (async () => {
            await getUsersWithServices()
        })()
    }, [])
   
    // const services = userServices.results.map(user => { 
    //     return { 
    //         name:user.name, 
    //         email:user.email, 
    //         address:user.address, 
    //         category: user.services.map(us => us.category.name ) 
    //     }
    // }) // mock data

    const getUsersWithServices = async () => {
        try {
            const response = await API.apiGet('userServices', '/specialities');
            if(response.data && response.data.success){
                let responseData = response.data.results.map(user => { 
                    console.log('user.services.', user.services)
                    return { 
                        ...user,
                        // category: 'Unknown' 
                        category: user.services.map(us => us?.category?.name || 'unknown name' ) 
                    }
                })
                console.log('responseData',responseData);
                setUsers(responseData);
            }else{
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.log('error',error);
            let message = ''
            if(error.response.data && error.response.data.message){
                message = error.response.data.message
            }else if(error.message){
                message = error.message
            }else{
                message = 'An Unknown Error Occured'
            }
        }finally{
            setLoader(false);
        }
    }

    console.log( users )
    if(users.length === 0) {
        <h1>loading..</h1>
    }
    return (
        <section 
            className="popular--Wrapper edit--page services-page-all"
        >
            {loader
                ?   <LoadingView />
                :   <CustomMap 
                        data={users}
                    />
            }
        </section>
    )
}

export default MapView;