import React, { useState } from "react";
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent, SubMenu } from "react-pro-sidebar";
import { FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { BiRocket } from "react-icons/bi";
import * as auth from 'helpers/auth'
// import { RiUserSettingsLine } from "react-icons/ri";
// import { HiOutlineUserGroup } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/actions/auth.actions"
const logo = require('assets/images/logos/main.png');
const smallLogo = require('assets/images/logos/main.png');

const Sidebar = (props) => {
    const { routes } = props;
    const employeeMetaSuccess = useSelector((state) => state?.meta?.employeeMeta);
    const [menuCollapse, setMenuCollapse] = useState(false)
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    
    //create a custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    const activeRoute = (menu) => {
        let route = pathname
        const path = [route.split('/').join('')]
        const result = path.some((name) => name.match(menu))
        return result
    }

    const activeSubRoute = (route) => {
        return route === pathname
    }

 

    // const renderRoutes = routes.map(({ type, name, icon, title, key, route, collapse, displayTo, access }) => {
    //     let returnValue;
    //     if(displayTo && auth.notAllowAccess(displayTo, employeeMetaSuccess?.role.title)) return;
    //     if( type === "sub-page") return;
    //     if (type === "title" && collapse.length > 0 ) {
    //       returnValue =  (
    //         <SubMenu 
    //             title={title} 
    //             icon={icon} 
    //             key={key}
    //         >
    //             {collapse.map((menu) =>{

    //                 if(menu.access && auth.notAllowAccess(menu.access, employeeMetaSuccess?.role.title)) return ;
    //                 if( menu.type === "sub-page" || menu.type === 'other') return;
                  
    //                 else return (
    //                     <MenuItem
    //                         // icon={menu.icon}
    //                         key={menu.key}
    //                         // active={activeSubRoute(menu.route)}
    //                     >
    //                         {menu.name}
    //                         <Link to={menu.route}/>
    //                     </MenuItem> 
    //                 )   
    //             })}
    //         </SubMenu>
    //       )
    //     } else if (type === 'page'){
    //         const activeRouteName = route.split('/').filter(item => item !== '')[0]
    //         returnValue = (
    //             <MenuItem 
    //                 active={activeRoute(activeRouteName)} 
    //                 icon={<BiRocket />}
    //                 key={key}
    //             >  
    //                 {name} 
    //                 <Link to={route}/>
    //             </MenuItem>
    //         )
    //     }else if (type === "divider") {
    //       returnValue = null
    //     }
    
    //     return returnValue;
    //   });

    const renderRoutes = routes.map(({ type, name, icon, title, key, route, collapse, displayTo, access }) => {
        let returnValue;
        if(displayTo && auth.notAllowAccess(displayTo, employeeMetaSuccess?.role.title)) return;
        if (type === "sub-page") return;
        if (type === "title" && collapse.length > 0 ) {
            returnValue =  (
                <SubMenu 
                    title={title} 
                    icon={icon} 
                    key={key}
                >
                    {collapse.map((menu) =>{
                        if(menu.access && auth.notAllowAccess(menu.access, employeeMetaSuccess?.role.title)) return ;
                        if( menu.type === "sub-page" || menu.type === 'other') return;
                        else if (menu.type === "title" && menu.collapse.length > 0) {
                            return (
                                <SubMenu 
                                    title={menu.title} 
                                    icon={menu.icon} 
                                    key={menu.key}
                                >
                                    {menu.collapse.map((nestedMenu) => {
                                        if(nestedMenu.access && auth.notAllowAccess(nestedMenu.access, employeeMetaSuccess?.role.title)) return ;
                                        if(nestedMenu.type === "sub-page" || nestedMenu.type === 'other') return;
                                        return (
                                            <MenuItem
                                                key={nestedMenu.key}
                                            >
                                                {nestedMenu.name}
                                                <Link to={nestedMenu.route}/>
                                            </MenuItem>
                                        );
                                    })}
                                </SubMenu>
                            );
                        } else {
                            return (
                                <MenuItem
                                    key={menu.key}
                                >
                                    {menu.name}
                                    <Link to={menu.route}/>
                                </MenuItem> 
                            );
                        }  
                    })}
                </SubMenu>
            );
        } else if (type === 'page'){
            const activeRouteName = route.split('/').filter(item => item !== '')[0];
            returnValue = (
                <MenuItem 
                    active={activeRoute(activeRouteName)} 
                    icon={<BiRocket />}
                    key={key}
                >  
                    {name} 
                    <Link to={route}/>
                </MenuItem>
            );
        } else if (type === "divider") {
            returnValue = null;
        }
    
        return returnValue;
    });

    
    return (
        <>
            <ProSidebar collapsed={menuCollapse}>
                <SidebarHeader>
                    <div className="logotext">
                        <img src={menuCollapse ? smallLogo : logo} className='App-logo' alt='logo' />
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="square">
                        {renderRoutes}
                    </Menu>
                </SidebarContent>
                {/* <SidebarFooter>
                    <Menu iconShape="square">
                        <MenuItem icon={<FiLogOut />} onClick={() => dispatch(logout())}>Logout</MenuItem>
                    </Menu>
                </SidebarFooter> */}
            </ProSidebar>
                <div className="closemenu" onClick={menuIconClick}>
                    {menuCollapse ? (
                        <FaAngleDoubleRight />
                    ) : (
                        <FaAngleDoubleLeft />
                    )}
                </div>
        
        </>
    );
};

export default Sidebar;