import React, {useState, useEffect} from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/actions/auth.actions"
// import {capitalizeFirstLetterEachWord} from "utils/validations";
// import { getCompanyMeta, getEmplyeeMeta } from "redux/actions/meta.actions";
// import { BiBell } from "react-icons/bi";

const TopBar = () => {
    const dispatch =  useDispatch();
    
    const [company, setCompanyMeta] = useState(null)
    const [employee, setEmployeeMeta] = useState(null)
    // const companyMetaSuccess = useSelector((state) => state.meta.companyMeta);
    // const employeeMetaSuccess = useSelector((state) => state.meta.employeeMeta);

    //     useEffect(() => {
    //     dispatch(getCompanyMeta())
    //     dispatch(getEmplyeeMeta())
    //   }, [])
    
    //   useEffect(() => {
    //     if(companyMetaSuccess){
    //         setCompanyMeta(companyMetaSuccess);
    //     }
    //     if(employeeMetaSuccess){
    //         setEmployeeMeta(employeeMetaSuccess);
    //     }
    //   }, [companyMetaSuccess, employeeMetaSuccess]);

    return (
        <div className="topBarNav">
            <ul>
                <li>
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <img src={require('assets/images/profile.png')} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {/* <Dropdown.Header className="text-capitalise">{employee?.role?.title || 'Employee'} Details</Dropdown.Header> */}
                            {/* <Dropdown.Divider /> */}
                            {/* <Dropdown.Item className="text-capitalise">Name: {employee.name}</Dropdown.Item> */}
                            <Dropdown.Item onClick={() => dispatch(logout())}>Log Out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
        </div>
    )
}

export default TopBar;