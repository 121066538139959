export const orderStatus = [
    {value:'pending', label:'Pending'}, 
    {value:'initiated', label: 'Initiated'}, 
    {value:'cancelled', label: 'Cancelled'}, 
    {value:'accepted', label: 'Accepted'}, 
    {value:'completed', label: 'Completed'},
];

export const transactionStatus = [
    {value:'pending', label: 'Pending'}, 
    {value:'cancelled', label: 'Cancelled'}, 
    {value:'completed', label: 'Completed'}, 
    {value:'refund_initiated',label: 'Refund Initiated'}, 
    {value:'refund_processing', label: 'Refund Processing'}, 
    {value:'refund', label: 'Refund'},
    {value:'offline', label: 'Offline'},
    {value:'failed', label: 'Failed'},
];

export const orderSearchBy = [
    {value:'order_id', label: 'Order Id'}, 
    {value:'tnxId', label: 'Transaction Id'}, 
    {value:'order_date', label: 'Order Date'}, 
    {value:'appointment_date',label: 'Appointment Date'}, 
]