import { get } from 'lodash';
import API from '../../helpers/api';

/**   import Subcriptions types */
import {
    GET_SUBSCRIPTIONS_SUCCESS,
    GET_SUBSCRIPTIONS_FAILED ,
    CREATE_SUBCRIPTION_SUCCESS,
    CREATE_SUBCRIPTION_FAILED,
    UPDATE_SUBCRIPTION_SUCCESS,
    UPDATE_SUBCRIPTION_FAILED,
    GET_ONE_SUBCRIPTION_SUCCESS,
    GET_ONE_SUBCRIPTION_FAILED,
    DELETE_ONE_SUBCRIPTION_SUCCESS,
    DELETE_ONE_SUBCRIPTION_FAILED,
    UPDATE_SUBCRIPTION_STATUS_SUCCESS,
    UPDATE_SUBCRIPTION_STATUS_FAILED
} from './types' ;


function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: {message: data.error.message},
        });
    } else {
        dispatch({
            type: type,
            payload: {message: data.message},
        });
    }
}

/**
 * 
 * @param {*} query using for paginations 
 * @returns subcriptions
 */
export const getAllSubcriptions = (query) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('subcription', query);
            /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: GET_SUBSCRIPTIONS_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_SUBSCRIPTIONS_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_SUBSCRIPTIONS_FAILED);
        }
    }
}

/**
 * 
 * @param {*} using for Add new subcription
 * @returns success message , error message 
 */

export const getOneSubcriptions = (id) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('subcription', `/${id}`);
            /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: GET_ONE_SUBCRIPTION_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_ONE_SUBCRIPTION_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_ONE_SUBCRIPTION_FAILED);
        }
    }
}

/**
 * 
 * @param {subcription id } using for get one subcription by id
 * @returns one subcription 
 */

export const createSubcription = (payload) => {
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('subcription', `/add`, payload);
            if (response.data && response.data.success) {
                await dispatch({ type: CREATE_SUBCRIPTION_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: CREATE_SUBCRIPTION_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, CREATE_SUBCRIPTION_FAILED);
        }
    }
}

/**
 * using for update one subscription 
 * @param {*} id subcription id 
 * @param {*} payload amount , status , tenure , discount , name
 * @returns 
 */


export const updateSubcription = (id,payload) => {
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('subcription',`/edit/${id}`, payload);
            /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: UPDATE_SUBCRIPTION_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: UPDATE_SUBCRIPTION_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, UPDATE_SUBCRIPTION_FAILED);
        }
    }
}

export const updateSubcriptionStatus = (id,payload) => {
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('subcription',`/change_status/${id}`, payload);
            /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: UPDATE_SUBCRIPTION_STATUS_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: UPDATE_SUBCRIPTION_STATUS_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, UPDATE_SUBCRIPTION_STATUS_FAILED);
        }
    }
}

/**
 * using for delele one subcription
 * @param {*} id 
 * @returns 
 */

export const deleteOneSubcription = (id) => {
    return async (dispatch) => {
      try {
        const response = await API.apiDelete('subcription', `/delete/${id}`);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: DELETE_ONE_SUBCRIPTION_SUCCESS, payload: response.data });
        } else {
            await dispatch({ type: DELETE_ONE_SUBCRIPTION_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, DELETE_ONE_SUBCRIPTION_FAILED);
      }
    }
}

