import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { notValid,amountValidation } from "utils/validations";
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { updateSettings } from "../../redux/actions/setting.action";
import { showPecentage } from "utils/validations";


function UpdateSetting() {
    const check = [undefined, null, 'null', 'undefined', '']
    const location = useLocation();
    const item = location.state.item;
    const { id } = useParams();
    const navigate =  useNavigate();
    const dispatch = useDispatch();
    const [service, setService] = useState({ label: '', name: '', value: '', type:'percent' });
    const [error, setError] = useState({ label:'',  name: '', value: '', type:'' });
    const [loading, setLoading] = useState(false);

    const settingState = useSelector(state => state.settings );

    useEffect(() => {
        if (!check.includes(item)) {
            setService({
                ...service,
                name: item?.name,
                is_fees_field: item?.is_fees_field,
                label: item?.label,
                value: item?.value,
                type: item?.type
            });
        }
    }, [item, id])


    const handleChangeInput = ({ name, value }) => {
        setService({ ...service, [name]: value })
        setError({ ...error, [name]: '' })
    }

    const isValid = () => {
        let isValid = true
        let nameErrorMessage = "Please provide name";
        let decErrorMessage = "Please provide value";
        let AmountValid = "Please provide valid value";

        if (notValid(service.name)) {
            setError({ ...error, ['name']: nameErrorMessage })
            isValid = false
        }

        if (notValid(service.value)) {
            setError({ ...error, ['value']: decErrorMessage })
            isValid = false
        }else if(!amountValidation(service.value)){
            setError({ ...error, ['value']: AmountValid })
            isValid = false 
        }
    
        return isValid
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (isValid()) {
            setLoading(true)
            dispatch(updateSettings(service))
        }
    }

    useEffect(() => {
        // ----- If Error ----- // 
        if(settingState.error ){
            toast.error(settingState.error.message); 
            setLoading(false)
        }

        // ---- If Not Error ---- //
        if( !settingState.error ){
            if( settingState.updated && settingState.updated.success){
                toast.success(settingState.updated.message)
                setLoading(false)
                setTimeout(()=>{
                    navigate('/setting/list');
                }, 1000);
            }
        }

    }, [settingState])


    return (
        <div className='main-add-service'>
            <h2 className="mb-3">Update Comission</h2>

            <div className="form-add-service">
                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Label</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Label"
                            name="label"
                            value={service.label || ''}
                            onChange={({ target }) => handleChangeInput(target)}
                        />
                        {error.label && <span className="error">{error.label}</span>}
                    </Form.Group>
                    {service.is_fees_field &&
                    <Form.Group className="mb-3">
                        <Form.Label>Charge</Form.Label>
                        <Form.Control
                            as={'select'}
                            placeholder="Select Charge Type"
                            name="type"
                            value={service.type || ''}
                            onChange={({ target }) => handleChangeInput(target)}
                        >
                            <option value='percent'>In Percent</option>
                            <option value='price'>In Price</option>
                        </Form.Control>
                        {error.type && <span className="error">{error.type}</span>}
                    </Form.Group>}

                    {/* <Form.Group className="mb-3">
                        <Form.Label>Unique Key</Form.Label>
                        <Form.Control
                            disabled
                            type="text"
                            name="name"
                            value={service.name || ''}
                            onChange={({ target }) => handleChangeInput(target)}
                        />
                        <span style={{ color: 'blue', fontSize:10}}>This Unique Key Will Remain Unchange</span>
                    </Form.Group> */}

                  {service.is_fees_field &&  
                  <Form.Group className="form-group col-sm-12">
                        <Form.Label>Commission Value ({showPecentage(service.type)})</Form.Label>
                        <Form.Control 
                            placeholder="Enter amount"
                            name="value"
                            value={service.value || ''}
                            // aria-posinset={'%'}
                            onChange={({ target }) => handleChangeInput(target)}
                        />
                         {error.value && <span className="error">{error.value}</span>}
                    </Form.Group>}
                    <div className="text-center add-service-btn">
                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Please Wait...' : 'Update Comission'}
                        </Button>
                    </div>
                </Form>

            </div>
        </div>
    )
}

export default UpdateSetting