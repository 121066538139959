import { get } from 'lodash';
import API from '../../helpers/api';
import {
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAILED,
    PUT_CATEGORY_SUCCESS,
    PUT_CATEGORY_FAILED,
    POST_CATEGORY_SUCCESS,
    POST_CATEGORY_FAILED,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILED,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAILED,
    POST_CATEGORY_RESTRICTION_SUCCESS,
    POST_CATEGORY_RESTRICTION_FAILED,
    PUT_CATEGORY_RESTRICTION_SUCCESS,
    PUT_CATEGORY_RESTRICTION_FAILED,
    DELETE_CATEGORY_RESTRICTION_SUCCESS,
    DELETE_CATEGORY_RESTRICTION_FAILED,
    GET_ALL_CATEGORY_RESTRICTION_SUCCESS,
    GET_ALL_CATEGORY_RESTRICTION_FAILED,
    RESET_CATEGORY_STATE
} from './types' ;

function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: { message: data.error.message },
        });
    } else {
        dispatch({
            type: type,
            payload: { message: data.message },
        });
    }
}

export const getCategories = (limit, page, query) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('category', {limit, page, ...query});
        if (response.data && response.data.success) {
            await dispatch({ 
                type: GET_CATEGORIES_SUCCESS, 
                payload: response.data
            });
        } else {
            await dispatch({ 
                type: GET_CATEGORIES_FAILED, 
                payload: response.data 
            });
        }
      } catch (error) {
            errorRequest(error, dispatch, GET_CATEGORIES_FAILED);
      }
    }
}

export const getOneCategory = (categoryId) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('category', `/${categoryId}`);
        if (response.data && response.data.success) {
            await dispatch({ 
                type: GET_CATEGORY_SUCCESS, 
                payload: response.data
            });
        } else {
            await dispatch({ 
                type: GET_CATEGORY_FAILED, 
                payload: response.data 
            });
        }
      } catch (error) {
            errorRequest(error, dispatch, GET_CATEGORY_FAILED);
      }
    }
}

export const createCategory = (data) => {
    return async (dispatch) => {
      try {
        const response = await API.apiPost('category', data);
        if (response.data && response.data.success) {
            await dispatch({ 
                type: POST_CATEGORY_SUCCESS, 
                payload: response.data
            });
        } else {
            await dispatch({ 
                type: POST_CATEGORY_FAILED, 
                payload: response.data 
            });
        }
      } catch (error) {
            errorRequest(error, dispatch, POST_CATEGORY_FAILED);
      }
    }
}

export const updateCategory = (categoryId, data) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPutUrl('category', `/${categoryId}`, data);
            if( response.data && response.data.success ){
                await dispatch({ 
                    type: PUT_CATEGORY_SUCCESS, 
                    payload: response.data
                });
            }else{
                await dispatch({ 
                    type: PUT_CATEGORY_FAILED, 
                    payload: response.data
                });
            }
        } catch (error) {
            errorRequest(error, dispatch, PUT_CATEGORY_FAILED)
        }
    }
}

/**
 * 
 * @param {ObjectId} categoryId  
 */
export const deleteCategory = (categoryId) => {
    return async (dispatch) => {
        try {
            const response = await API.apiDelete('category', `/${categoryId}`);
            if( response.data && response.data.success ){
                await dispatch({ 
                    type: DELETE_CATEGORY_SUCCESS, 
                    payload: response.data
                });
            }else{
                await dispatch({ 
                    type: DELETE_CATEGORY_FAILED, 
                    payload: response.data
                });
            }
        } catch (error) {
            errorRequest(error, dispatch, DELETE_CATEGORY_FAILED);
        }
    }
}

export const getAllCategoryRestrictions = (categoryId) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('categoryRestrictions', `/${categoryId}`);
            if( response.data && response.data.success ){
                await dispatch({ 
                    type: GET_ALL_CATEGORY_RESTRICTION_SUCCESS, 
                    payload: response.data
                });
            }else{
                await dispatch({ 
                    type: GET_ALL_CATEGORY_RESTRICTION_FAILED, 
                    payload: response.data
                });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_ALL_CATEGORY_RESTRICTION_FAILED);
        }
    }
}

export const createCategoryRestriction = (payload) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPost('categoryRestrictions', payload);
            if( response.data && response.data.success ){
                await dispatch({ 
                    type: POST_CATEGORY_RESTRICTION_SUCCESS, 
                    payload: response.data
                });
            }else{
                await dispatch({ 
                    type: POST_CATEGORY_RESTRICTION_FAILED, 
                    payload: response.data
                });
            }
        } catch (error) {
            errorRequest(error, dispatch, POST_CATEGORY_RESTRICTION_FAILED);
        }
    }
}

export const updateCategoryRestrictions = (id, payload) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPutUrl('categoryRestrictions', `/${id}`, payload);
            if( response.data && response.data.success ){
                await dispatch({ 
                    type: PUT_CATEGORY_RESTRICTION_SUCCESS, 
                    payload: response.data
                });
            }else{
                await dispatch({ 
                    type: PUT_CATEGORY_RESTRICTION_FAILED, 
                    payload: response.data
                });
            }
        } catch (error) {
            errorRequest(error, dispatch, PUT_CATEGORY_RESTRICTION_FAILED);
        }
    }
}


export const deleteCategoryRestrictions = (id) => {
    return async (dispatch) => {
        try {
            const response = await API.apiDelete('categoryRestrictions', `/${id}`);
            if( response.data && response.data.success ){
                await dispatch({ 
                    type: DELETE_CATEGORY_RESTRICTION_SUCCESS, 
                    payload: response.data
                });
            }else{
                await dispatch({ 
                    type: DELETE_CATEGORY_RESTRICTION_FAILED, 
                    payload: response.data
                });
            }
        } catch (error) {
            errorRequest(error, dispatch, DELETE_CATEGORY_RESTRICTION_FAILED);
        }
    }
}

export const resetCategoryState = () => {
    return async (dispatch) => {
        await dispatch({ 
            type: RESET_CATEGORY_STATE, 
        });
    }
}

