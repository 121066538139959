import moment from "moment";
import momentTz from "moment-timezone"

const Email = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
const Dateformat= new RegExp(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i);
const Alphanumeric = new RegExp(/^[A-Za-z\d\s]+$/);
const Aphabeticals = new RegExp(/^[a-zA-Z ]*$/);
const Numeric = new RegExp(/^[0-9]+$/);
const Amount = new RegExp(/^\$?[\d,]+(\.\d*)?$/);

export const removeExtraSpaces = (string) => {
    if(typeof string !== 'string') return string;
    return string.replace(/\s{2,}/g, ' ').trim()
}

export const notValid = string => {
    return [null, undefined, 'null', 'undefined', ''].includes(removeExtraSpaces(string))
}

export const emailValidation = email => Email.test(email);

export const capitalizeFirstLetterEachWord = (str) => {
    if(!str) return '';
    str = str.split(" ");
    for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }
    return str.join(" ");
}

export const dateFormatValidation = date => Dateformat.exec(date);
export const alphanumeicValidation = text => Alphanumeric.test(text);
export const aphabeticalsValidation = text => Aphabeticals.test(text);
export const numericValidation = number => Numeric.test(number);
export const amountValidation = amount => Amount.test(amount);
export const removeCommaInDigit = (value) => {
    if(!value) return ;
    return parseFloat(value.split(',').join(''))
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const extractDate = (date) => {
    if(!date instanceof Date) return date;
    return date.split('T')[0];
}

export const formatPrice = (value) => {
    if(!value) return value;
    if(value <= 1000) return value;
    var nStr = value + '';
    nStr = nStr.replace(/\,/g, "");
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return`(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`;
}

export function kFormatter(num) {
    if(!num) return num;
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}


export const momentFormat = {
    dateTimeWithTz:(dateTime) => {
        const userTimeZone = momentTz.tz.guess();
        return momentTz(dateTime).tz(userTimeZone).format('MMMM DD, YYYY h:mm A z');
    },
    dateTimeFormat: (dateTime) => {
        return moment(dateTime).format('lll')
    },
    timeFormat: (time) => {
        return moment(time).format('LT')
    },   
    timeInHHmm: time => {
        return moment(time).format('HH:mm');
    },
    dateFormat: (date) => {
        return moment(date).format('YYYY-MM-DD');
    },
    fullDate: (date) => {
        return moment(date).format('LL');
    }, 
    formatReactCalendarDate: (date) => {
        date = new Date(date)
        let year = date.getYear();
        let month = date.getMonth();
        let dt = date.getDate();
        return new Date(year, month, dt);
    },
    formatReactCalendarTime: (date) => {
        date = new Date(date)
        let hr = date.getHours();
        let min = date.getMinutes();

        hr  = hr > 9 ? hr : `0${hr}`;
        min = min > 9 ? min : `0${min}`;

        return `${hr}:${min}`
    },
    correctDate: (date) => {
        date.setDate(date.getDate()-1);
        return date;
    },
    combineDateTime:  (date, time) => {
        if( time ){
            let _time = time.split(':');
            date.setHours(_time[0]);
            date.setMinutes(_time[1]);
        }
        return date;
    },
    formatWeekDate: (date) => {
        return moment(date).format('dddd, DD MMMM, YY');
    },
    increaseDateByOne: (date) => {
        date.setDate(date.getDate()+1);
        return date;
    },
    convertTimeSlots: (data) => {
        let times = []
        data.forEach(d => {
            let start = moment(d.start).format('HH:mm')
            let end = moment(d.end).format('HH:mm')

            times.push({ start, end})
        })

        return times;
    }
}

export function displayAddress(address) {
    let formattedAddress = 'N/A';

    function cleanString(str) {
        return str ? str.replace(/,\s*$/, '') : '';
    }

    // Initialize formattedAddress to an empty string
    formattedAddress = '';

    // Check each key in the address object, clean it, and add non-blank values to the formatted address
    if (address.street) {
        formattedAddress = `${cleanString(address.street)}, `;
    }

    if (address.landmark) {
        formattedAddress += `${cleanString(address.landmark)}, `;
    }

    if (address.city) {
        formattedAddress += `${cleanString(address.city)}, `;
    }

    if (address.state) {
        formattedAddress += `${cleanString(address.state)}, `;
    }

    if (address.country) {
        formattedAddress += `${cleanString(address.country)}, `;
    }

    if (address.postalCode) {
        formattedAddress += `${cleanString(address.postalCode)}`;
    }

    // Trim any trailing commas and spaces from the final formatted address
    formattedAddress = formattedAddress.replace(/,\s*$/, '');

    // If formattedAddress is empty, set it to 'N/A'
    if (!formattedAddress) {
        formattedAddress = 'N/A';
    }

    return formattedAddress;
}

export function displayShortAddress(address) {
    let formattedAddress = 'N/A';

    function cleanString(str) {
        return str ? str.replace(/,\s*$/, '') : '';
    }

    // Initialize formattedAddress to an empty string
    formattedAddress = '';

    // Check each key in the address object, clean it, and add non-blank values to the formatted address
    // if (address.street) {
    //     formattedAddress = `${cleanString(address.street)}, `;
    // }

    // if (address.landmark) {
    //     formattedAddress += `${cleanString(address.landmark)}, `;
    // }

    if (address.city) {
        formattedAddress += `${cleanString(address.city)}, `;
    }

    if (address.state) {
        formattedAddress += `${cleanString(address.state)}, `;
    }

    // if (address.country) {
    //     formattedAddress += `${cleanString(address.country)}, `;
    // }

    // if (address.postalCode) {
    //     formattedAddress += `${cleanString(address.postalCode)}`;
    // }

    // Trim any trailing commas and spaces from the final formatted address
    formattedAddress = formattedAddress.replace(/,\s*$/, '');

    // If formattedAddress is empty, set it to 'N/A'
    if (!formattedAddress) {
        formattedAddress = 'N/A';
    }

    return formattedAddress;
}

export const serviceTypeName = (type) => {
    if(type === 'service'){
        return 'Service'
    }

    return 'Class'
}


export const showPecentage = (type) => {
    return ['percent'].includes(type) ? '%' : '$'
 }

 function extractLetters(inputString) {
    let result = "";

    // Split the input string into words
    const words = inputString.split(/\s+/);

    // Define a set of words to exclude
    const excludeWords = new Set(["and", "or", "for", "not", "nor"]);

    // Loop through each word to find the first valid letter
    let count = 0;
    for (let word of words) {
        // Remove special characters and numbers from the word
        const cleanedWord = word.replace(/[^a-zA-Z]/g, "");
        // Check if the cleaned word is not empty and not in the exclude list
        if (cleanedWord.length > 0 && !excludeWords.has(cleanedWord.toLowerCase())) {
            // Take the first letter and append it to the result
            result += cleanedWord[0].toUpperCase();
            count++;
            // If we have found the first two valid words, exit the loop
            if (count === 2) {
                break;
            }
        }
    }

    return result;
}

export function generateLetterSVG(letter) {
    const backgroudColor = '#eeeeee70';
    const fontColor = '#aed9e0';
    const fontWeight = 'bold';
    const fontSize = '45px'
   const svgString =`
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <rect width="100" height="100" fill="${backgroudColor}" />
            <text x="50%" y="55%" alignment-baseline="middle" text-anchor="middle" fill="${fontColor}" font-size="${fontSize}" font-weight="${fontWeight}" font-family="cursive">${extractLetters(letter)}</text>
        </svg>
    `;

    return generateDataURI(svgString);
}

function generateDataURI(svgString) {
    return 'data:image/svg+xml;base64,' + btoa(svgString);
}