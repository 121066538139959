
import {
    GET_SUBSCRIPTIONS_SUCCESS,
    GET_SUBSCRIPTIONS_FAILED ,
    CREATE_SUBCRIPTION_SUCCESS,
    CREATE_SUBCRIPTION_FAILED,
    UPDATE_SUBCRIPTION_SUCCESS,
    UPDATE_SUBCRIPTION_FAILED,
    GET_ONE_SUBCRIPTION_SUCCESS,
    GET_ONE_SUBCRIPTION_FAILED,
    DELETE_ONE_SUBCRIPTION_SUCCESS,
    DELETE_ONE_SUBCRIPTION_FAILED,
    UPDATE_SUBCRIPTION_STATUS_SUCCESS ,
    UPDATE_SUBCRIPTION_STATUS_FAILED
}from "redux/actions/types";

const initialState = {
    error: null,
    getAllSubcriptions: null,
};


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBSCRIPTIONS_SUCCESS:
            return {
                getAllSubcriptions: action.payload,
                error: null, 
            }
        case GET_SUBSCRIPTIONS_FAILED:
            return {
                error: action.payload,
            }
        case GET_ONE_SUBCRIPTION_SUCCESS:
            return{
                getOneSubcription: action.payload,
                error: null 
            }
        case GET_ONE_SUBCRIPTION_FAILED:
            return{
                error: action.payload
            }
        case CREATE_SUBCRIPTION_SUCCESS:
            return{
                addSubcription : action.payload ,
                error : null
            }
        case CREATE_SUBCRIPTION_FAILED:
            return{
                error: action.payload ,
            }
        case UPDATE_SUBCRIPTION_SUCCESS:
            return{
                updateSubcription : action.payload ,
                error : null
            }
        case UPDATE_SUBCRIPTION_FAILED:
            return{
                error : action.payload ,
            }
        case DELETE_ONE_SUBCRIPTION_SUCCESS:
            return{
                ...state ,
                deleteSubcription : action.payload ,
                error : null
            }
        case DELETE_ONE_SUBCRIPTION_FAILED:
            return{
                ...state ,
                error:action.payload
            }
        case UPDATE_SUBCRIPTION_STATUS_SUCCESS:
            return{
                ...state,
                updateStatus: action.payload,
                error: null
            }
        case UPDATE_SUBCRIPTION_STATUS_FAILED:
            return{
                ...state,
                error: action.payload
            }
        default: return state;
    }
}