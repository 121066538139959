import React, { useState } from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { login } from 'redux/actions/auth.actions';
import { AiOutlineSolution,AiOutlineUserAdd } from "react-icons/ai";
import { FiUserCheck } from "react-icons/fi";
import { FaRegHandPointRight, FaHandPointRight } from "react-icons/fa";

const LoginCompaniesList = ({ show, handleClose, companies, user }) => {
    const dispatch = useDispatch();
    const [ selected, setSelected ] = useState('');
    const [loader, setLoader] = useState(false)

    const handleSubmit = async () => {
        setLoader(true);
        await dispatch(login({ ...user, companyId:selected }))
        setLoader(false);
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="sm"
            backdrop="static"
            keyboard={false}
            centered
            style={{ width:'50%', left:'25%' }}
        >
            <Modal.Header closeButton>
              <Modal.Title className='model-title-wraper' >
                Select Company To Login
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '40vh', textAlign:'center' }}>
                <ListGroup className='select-company-login'>
                    {companies.map((company, index) => {
                        return (
                            <>
                            <div className='broker-login-wraper'>
                                <div className='pointer-icon-broker-login'>
                                    {selected === company._id
                                        ? <FaHandPointRight size={32}/>
                                        : <FaRegHandPointRight />
                                    }
                                </div>
                                <ListGroup.Item 
                                    // action
                                    onClick={() => setSelected(company._id)}
                                    className={`${selected === company._id ? 'login-company-list-active' : 'login-company-list'}`}
                                >
                                    {company.name}
                                </ListGroup.Item>
                            </div>
                            </>
                        )
                    })}
                </ListGroup>
            </Modal.Body>
              <Modal.Footer className="text-center">
                    <Button
                        onClick={() => !selected ? null : handleSubmit()}
                        disabled={!selected}
                    >
                        {loader ? 'Logging...' : 'Submit'}
                    </Button>
              </Modal.Footer>
      </Modal>
    )
}

export default LoginCompaniesList;