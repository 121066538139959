module.exports = {
    /** -- COOMON -- */
    GET_UPLOAD_PROGRESS:"GET_UPLOAD_PROGRESS",
    
    /** -- AUTH -- */
    REQUEST_OTP_SUCCESS: "REQUEST_OTP_SUCCESS",
    REQUEST_OTP_FAILED: "REQUEST_OTP_FAILED",
    VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
    VERIFY_OTP_FAILED: "VERIFY_OTP_FAILED",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
    POST_JOIN_BROKER_SUCCESS:"POST_JOIN_BROKER_SUCCESS",
    POST_JOIN_BROKER_FAILED:"POST_JOIN_BROKER_FAILED",
    
    LOGIN_LIST_BROKER_COMPANIES: 'LOGIN_LIST_BROKER_COMPANIES',
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILED: "LOGIN_FAILED",

    POST_VERIFY_INVITE_SUCCESS: 'POST_VERIFY_INVITE_SUCCESS',
    POST_VERIFY_INVITE_FAILED:'POST_VERIFY_INVITE_FAILED',

    
    LOGIN_FAILED_UPDATE_PASSWORD: "LOGIN_FAILED_UPDATE_PASSWORD",
    LOGIN_SUCCESS_UNPAID_SUBSCRIPTION: "LOGIN_SUCCESS_UNPAID_SUBSCRIPTION",
    
    PATCH_FIRST_NEW_PASSWORD_SUCCESS: "PATCH_FIRST_NEW_PASSWORD_SUCCESS",
    PATCH_FIRST_NEW_PASSWORD_FAILED: "PATCH_FIRST_NEW_PASSWORD_FAILED",
    
    LOGOUT: "LOGOUT",
    AUTH_COMMON_ERROR:"AUTH_COMMON_ERROR",

    /** -- SUBSCRIPTION -- */
    GET_COMPANY_SUBSCRIPTION_SUCCESS: "GET_COMPANY_SUBSCRIPTION_SUCCESS",
    GET_COMPANY_SUBSCRIPTION_FAILED: "GET_COMPANY_SUBSCRIPTION_FAILED",
    POST_PAYMENT_INTENT_SUCCESS: "POST_PAYMENT_INTENT_SUCCESS",
    POST_PAYMENT_INTENT_FAILED: "POST_PAYMENT_INTENT_FAILED",
    PATCH_PAYMENT_STATUS_SUCCESS: "PATCH_PAYMENT_STATUS_SUCCESS",
    PATCH_PAYMENT_STATUS_FAILED: "PATCH_PAYMENT_STATUS_FAILED",
    SUBSCRIPTION_COMMON_ERROR:"SUBSCRIPTION_COMMON_ERROR",
    GET_SUBSCRIPTIONS_SUCCESS:"GET_SUBSCRIPTIONS_SUCCESS",
    GET_SUBSCRIPTIONS_FAILED:"GET_SUBSCRIPTIONS_FAILED",
    CREATE_SUBCRIPTION_SUCCESS:"CREATE_SUBCRIPTION_SUCCESS",
    CREATE_SUBCRIPTION_FAILED:"CREATE_SUBCRIPTION_FAILED",
    UPDATE_SUBCRIPTION_SUCCESS:"UPDATE_SUBCRIPTION_SUCCESS",
    UPDATE_SUBCRIPTION_FAILED:"UPDATE_SUBCRIPTION_FAILED",
    GET_ONE_SUBCRIPTION_SUCCESS:" GET_ONE_SUBCRIPTION_SUCCESS",
    GET_ONE_SUBCRIPTION_FAILED:" GET_ONE_SUBCRIPTION_FAILED",
    DELETE_ONE_SUBCRIPTION_SUCCESS:"DELETE_ONE_SUBCRIPTION_SUCCESS",
    DELETE_ONE_SUBCRIPTION_FAILED:"DELETE_ONE_SUBCRIPTION_FAILED",
    UPDATE_SUBCRIPTION_STATUS_SUCCESS:"UPDATE_SUBCRIPTION_STATUS_SUCCESS",
    UPDATE_SUBCRIPTION_STATUS_FAILED:"UPDATE_SUBCRIPTION_STATUS_FAILED",
    /** -- COMPANY META DATA -- */
    GET_COMPANY_META_SUCCESS:'GET_COMPANY_META_SUCCESS',
    GET_COMPANY_META_FAILED:'GET_COMPANY_META_FAILED',

    /** -- EMPLOYEE META DATA -- */
    GET_EMPLOYEE_META_SUCCESS:'GET_EMPLOYEE_META_SUCCESS',
    GET_EMPLOYEE_META_FAILED:'GET_EMPLOYEE_META_FAILED',
    META_COMMON_ERROR:"META_COMMON_ERROR",

    /** -- DASHBOARD -- */
    GET_DASHBOARD_STATS_SUCCESS: "GET_DASHBOARD_STATS_SUCCESS",
    GET_DASHBOARD_STATS_FAILED: "GET_DASHBOARD_STATS_FAILED",
    GET_BROKER_STATS_SUCCESS:"GET_BROKER_STATS_SUCCESS",
    GET_BROKER_STATS_FAILED:"GET_BROKER_STATS_FAILED",
    DASHBOARD_COMMON_ERROR: "DASHBOARD_COMMON_ERROR",



    /** -- CLIENT -- */
    GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
    GET_CLIENTS_FAILED: "GET_CLIENTS_FAILED",
    POST_CLIENT_SUCCESS:"POST_CLIENT_SUCCESS",
    POST_CLIENT_FAILED: "POST_CLIENT_FAILED",
    PUT_CLIENT_SUCCESS:"PUT_CLIENT_SUCCESS",
    PUT_CLIENT_FAILED:"PUT_CLIENT_FAILED",
    GET_ONE_CLIENT_SUCCESS:"GET_ONE_CLIENT_SUCCESS",
    GET_ONE_CLIENT_FAILED:"GET_ONE_CLIENT_FAILED",
    DELETE_CLIENT_SUCCESS:"DELETE_CLIENT_SUCCESS",
    DELETE_CLIENT_FAILED:"DELETE_CLIENT_FAILED",
    PATCH_ONE_CLIENT_SUCCESS:"PATCH_ONE_CLIENT_SUCCESS",
    PATCH_ONE_CLIENT_FAILED:"PATCH_ONE_CLIENT_FAILED",
    // GET_CLIENT_JOB_HISTORY_SUCCESS:"GET_CLIENT_JOB_HISTORY_SUCCESS",
    // GET_CLIENT_JOB_HISTORY_FAILED:"GET_CLIENT_JOB_HISTORY_FAILED",
    CLIENT_COMMON_ERROR:"CLIENT_COMMON_ERROR",



    /** ------ Role  */

    ROLE_LIST_SUCCESS:"ROLE_LIST_SUCCESS",
    ROLE_LIST_FAILED:"ROLE_LIST_FAILED",
    POST_ROLE_SUCCESS:"POST_ROLE_SUCCESS",
    POST_ROLE_FAILED:"POST_ROLE_FAILED",
    DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
    GET_ONE_ROLE_SUCCESS:"GET_ONE_ROLE_SUCCESS",
    GET_ONE_ROLE_FAILED:"GET_ONE_ROLE_FAILED",
    DELETE_ROLE_FAILED: "DELETE_ROLE_FAILED",
    PATCH_ROLE_SUCCESS: "PATCH_ROLE_SUCCESS",
    PATCH_ROLE_FAILED: "PATCH_ROLE_FAILED",
    ROLE_COMMON_ERROR:"ROLE_COMMON_ERROR",


    /***      Service BB */

    GET_SERVICES_BB_SUCCESS:"GET_SERVICES_BB_SUCCESS",
    GET_SERVICES_BB_FAILED:"GET_SERVICES_BB_FAILED",
    DELETE_SERVICES_BB_SUCCESS:"DELETE_SERVICES_BB_SUCCESS",
    DELETE_SERVICES_BB_FAILED:"DELETE_SERVICES_BB_FAILED",
    ADD_SERVICES_BB_SUCCESS:"ADD_SERVICES_BB_SUCCESS",
    ADD_SERVICES_BB_FAILED:"ADD_SERVICES_BB_FAILED",
    UPDATE_SERVICES_BB_SUCCESS:"UPDATE_SERVICES_BB_SUCCESS",
    UPDATE_SERVICES_BB_FAILED:"UPDATE_SERVICES_BB_FAILED",
    GET_ONE_BBSERVICE_SUCCESS: 'GET_ONE_BBSERVICE_SUCCESS',
    GET_ONE_BBSERVICE_FAILED: 'GET_ONE_BBSERVICE_FAILED',


    
    /***      Settings */

    GET_SETTINGS_SUCCESS:"GET_SETTINGS_SUCCESS",
    GET_SETTINGS_FAILED:"GET_SETTINGS_FAILED",
    UPDATE_SETTINGS_SUCCESS:"UPDATE_SETTINGS_SUCCESS",
    UPDATE_SETTINGS_FAILED:"UPDATE_SETTINGS_FAILED",


        /***      Buyer User */

    GET_BUYER_USER_SUCCESS:"GET_BUYER_USER_SUCCESS",
    GET_BUYER_USER_FAILED:"GET_BUYER_USER_FAILED",


       /***      Seller User */


    GET_SELLER_USER_SUCCESS:"GET_SELLER_USER_SUCCESS",
    GET_SELLER_USER_FAILED:"GET_SELLER_USER_FAILED",
    GET_SELLER_SERVICE_SUCCESS:"GET_SELLER_SERVICE_SUCCESS",
    GET_SELLER_SERVICE_FAILED:"GET_SELLER_SERVICE_FAILED",
    APPROVE_SELLER_SUCCESS: 'APPROVE_SELLER_SUCCESS',
    APPROVE_SELLER_FAILED: 'APPROVE_SELLER_FAILED',
    GET_SELLER_STAFF_SUCCESS:"GET_SELLER_STAFF_SUCCESS",
    GET_SELLER_STAFF_FAILED:"GET_SELLER_STAFF_FAILED",

    /* ---- ORDERS / BOOKINGS ------ */
    GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
    GET_ORDERS_FAILED: 'GET_ORDERS_FAILED',
    

    POST_SUBSCRIPTION_SUCCESS: "POST_SUBSCRIPTION_SUCCESS",
    POST_SUBSCRIPTION_FAILED: 'POST_SUBSCRIPTION_FAILED',

    /** ----------- CATEGORY ----------- */
    POST_CATEGORY_SUCCESS: "POST_CATEGORY_SUCCESS",
    POST_CATEGORY_FAILED: "POST_CATEGORY_FAILED",
    PUT_CATEGORY_SUCCESS: "PUT_CATEGORY_SUCCESS",
    PUT_CATEGORY_FAILED: 'PUT_CATEGORY_FAILED', 
    GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAILED: "GET_CATEGORIES_FAILED",
    DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
    DELETE_CATEGORY_FAILED: 'DELETE_CATEGORY_FAILED',
    GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
    GET_CATEGORY_FAILED: 'GET_CATEGORY_FAILED',


    /** --------- CATEGORY RESTRICTIONS --------- */
    POST_CATEGORY_RESTRICTION_SUCCESS: 'POST_CATEGORY_RESTRICTION_SUCCESS',
    POST_CATEGORY_RESTRICTION_FAILED: 'POST_CATEGORT_RESTRICTION_FAILED',
    PUT_CATEGORY_RESTRICTION_SUCCESS: 'PUT_CATEGORY_RESTRICTION_SUCCESS',
    PUT_CATEGORY_RESTRICTION_FAILED: 'PUT_CATEGORY_RESTRICTION_FAILED',
    DELETE_CATEGORY_RESTRICTION_SUCCESS: 'DELETE_CATEGORY_RESTRICTION_SUCCESS',
    DELETE_CATEGORY_RESTRICTION_FAILED: 'DELETE_CATEGORY_RESTRICTION_FAILED',
    GET_ALL_CATEGORY_RESTRICTION_SUCCESS: 'GET_ALL_CATEGORY_RESTRICTION_SUCCESS',
    GET_ALL_CATEGORY_RESTRICTION_FAILED: 'GET_ALL_CATEGORY_RESTRICTION_FAILED',
    RESET_CATEGORY_STATE: 'RESET_CATEGORY_STATE'
}  