import React, { useState, useEffect } from 'react';
import { Dropdown, Modal, Table, Form, Row, Button } from 'react-bootstrap';
import { AiOutlineEye } from 'react-icons/ai';
import { BiEdit, BiSolidTrash, BiTrash } from 'react-icons/bi';
import locations from '../../utils/locations';
import { useDispatch, useSelector } from 'react-redux';
import { createCategoryRestriction, updateCategoryRestrictions, getAllCategoryRestrictions, deleteCategoryRestrictions } from 'redux/actions/category.action';
import LoadingView from 'components/LoadingView';
import toast from 'react-hot-toast';

const RestrictCategoryModal = ({ 
    showRestrictedModal, 
    closeRestrictModal, 
    category, 
}) => {

    const initialCategoryRestriction = { location: { state: ''}, limit: '', category: category._id  }
    const dispatch = useDispatch();
    const [ categoryRestriction , setCategoryRestriction ] = useState( JSON.parse(JSON.stringify( initialCategoryRestriction)) );
    
    const [ restrictedLocations, setRestrictedLocations ] = useState([]);

    const [ loader, setLoader ] = useState({ init: true, action: false, delete: false });

    const [ editable, setEditable ] = useState(false);

    const restrictionState = useSelector(state => state.categoryRestriction);

    const fetchAllCategoryRestrictions = () => {
        dispatch(getAllCategoryRestrictions(category._id))
    }

    useEffect(() => {
        fetchAllCategoryRestrictions()
    }, [])

    useEffect(() => {
        if( restrictionState.error ){
            setLoader({ init: false, action: false, delete: false });
            toast.error(restrictionState.error.message)
        }

        if( !restrictionState.error ){
            if( restrictionState.all && restrictionState.all.success){
                setRestrictedLocations(restrictionState.all.data);
                setLoader({...loader,  init: false });
            }

            if( restrictionState.post && restrictionState.post.success){
                toast.success(restrictionState.post.message);
                setLoader({...loader,  action: false });
                setCategoryRestriction(JSON.parse(JSON.stringify( initialCategoryRestriction)))
                fetchAllCategoryRestrictions();
            }

            if(restrictionState.put && restrictionState.put.success){
                toast.success(restrictionState.put.message);
                setLoader({...loader,  action: false });
                setCategoryRestriction(JSON.parse(JSON.stringify( initialCategoryRestriction)))
                setEditable(false);
                fetchAllCategoryRestrictions();
            }

            if(restrictionState.delete && restrictionState.delete.success){
                toast.success(restrictionState.delete.message)
                setLoader({...loader,  delete: false });
                fetchAllCategoryRestrictions();
            }
        }

    }, [restrictionState]);

    
    const handleChange = (e) => {
        const { name, value } = e.target;

        let data = {}

        if( name === 'location'){
            data.location = {...categoryRestriction.location}
            data.location.state = value
        }else if(name === 'limit'){
            data[name] = Number(value)
        }

        setCategoryRestriction({ ...categoryRestriction, ...data })
    }

    // console.log('categoryRestriction', categoryRestriction );

    const handleOnSubmit = () => {
        // console.log( categoryRestriction );
        setLoader({ ...loader, action: true })
        if(editable){
            let data = {
                location: categoryRestriction.location,
                limit: categoryRestriction.limit,
                category: categoryRestriction.category
            }
            dispatch(updateCategoryRestrictions(categoryRestriction._id, data));
        }else{
            dispatch(createCategoryRestriction(categoryRestriction));
        }
    }


    const handleUpdate = (data) => {
        setEditable(true);
        setCategoryRestriction({ 
            ...data
        })
    }       

    const handleCancelUpdate = () => {
        setEditable(false);
        setCategoryRestriction(JSON.parse(JSON.stringify( initialCategoryRestriction)));
    }


    const handleDelete = (id) => {
        setLoader({ ...loader, delete: true });
        dispatch(deleteCategoryRestrictions(id))
    }

    return (
        <Modal
            size="lg"
            show={showRestrictedModal}
            onHide={() => closeRestrictModal()}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title className='order-id-popup-o'>{category.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{editable ? 'Update' : 'Create'}</h4>
                <Row className='mb-3'>
                    <Form.Group className="col-sm-4">
                        {/* <Form.Label>Location</Form.Label> */}
                        <Form.Select 
                            aria-label="service type"
                            name="location"
                            onChange={(e)=>handleChange(e)}
                            value={categoryRestriction.location.state}
                        >
                            <option value="">Select</option>
                            {locations && locations.states.map((state, stateIndex) => <option key={stateIndex}>{state.display_name}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='col-sm-3'>
                        {/* <Form.Label>Limit</Form.Label> */}
                        <Form.Control
                            type="text"
                            placeholder="Enter Limit"
                            name="limit"
                            value={categoryRestriction.limit}
                            onChange={(e) => handleChange(e)}
                        />
                    </Form.Group>
                    <Form.Group className='col-sm-5'>
                        <Button style={{ marginRight: '1px'}} onClick={() => handleOnSubmit()}>
                            {loader.action ? 'Wait...' : editable ? 'Update' : 'Submit'}
                        </Button>
                        {editable && <Button onClick={() => handleCancelUpdate()}>Cancel</Button>}
                    </Form.Group>
                </Row>

                <h4>Restricted Locations</h4>
               {loader.init
               ? <LoadingView />

               : (restrictedLocations && restrictedLocations.length > 0) 
                    ?   <Table striped responsive="sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Location</th>
                                    <th>Limit</th>
                                    <th className="edit-service">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {restrictedLocations.map((restrictedLocation, restrictedLocationIndex) => (
                                    <tr key={restrictedLocationIndex}>
                                        <td>{restrictedLocationIndex + 1}</td>
                                        <td>
                                            {restrictedLocation.location?.state}
                                        </td>
                                        <td>
                                            {restrictedLocation.limit}
                                        </td>
                                        <td className="edit-service"> 
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    <AiOutlineEye />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item 
                                                        onClick={() => loader.action ?  null : handleUpdate(restrictedLocation)}
                                                    >
                                                        <BiEdit /> Update
                                                    </Dropdown.Item>
                                                    <Dropdown.Item 
                                                        onClick={() => loader.delete ? null : handleDelete(restrictedLocation._id)}
                                                    >
                                                        {loader.delete 
                                                        ?   <>
                                                                <BiSolidTrash /> Deleting...
                                                            </>
                                                        :   <>
                                                                <BiTrash /> Delete
                                                            </>
                                                        }
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    :   <h5 className='text-center'>No Record Found</h5> 
                }

            </Modal.Body>
        </Modal>
    )
}

export default RestrictCategoryModal;