import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Container, Dropdown, Accordion, Button, Form, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { AiOutlineEye } from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteCategory } from 'redux/actions/category.action';
import { updateCategory } from 'redux/actions/category.action';
import { getCategories } from 'redux/actions/category.action';
import swal from 'sweetalert';
import { checkScrolledToBottom } from 'utils/scroll';
import { momentFormat,generateLetterSVG } from 'utils/validations';
import RestrictCategoryModal from './restrictCategoryModal';
import { resetCategoryState } from 'redux/actions/category.action';

const limitPerPage = process.env.REACT_APP_LISTING_LIMIT;


const CategoriesList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    /** ---- Loader ---- */
    const [loading, setLoading] = useState({ init: true, loadMore:false, delete: false, update: false })
    const [ showRestrictedModal, setShowRestrictedModal ] = useState(false);
    const [ category, setCategory ] = useState(null);

    const [categories, setCategories] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    let [page, setPage] = useState(0);

    const categoryState = useSelector(state => state.category);

    const listCategories = () => {
        dispatch( getCategories(limitPerPage, page));    
    }

  
    useEffect(() => {
        /** --- List Categories ---- */
        listCategories();
        /** --- Add Event Listener --- */
        // window.addEventListener("scroll", handleScroll);
        /** --- Remove Event Listener --- */
        // return () => window.removeEventListener("scroll", handleScroll);

        return () => {
            dispatch(resetCategoryState())
        }
    }, []);

   

    useEffect(() => {
        if(page < totalPages){
            listCategories();
        }else{
            setLoading({ ...loading,  loadMore: false  });
        }
    }, [page])

    /** ---- Get More Categories ---- */
    useEffect(() => {
        if(!loading.loadMore) return;

        if(page < totalPages){
            setTimeout(() => {
                setPage(page + 1)
            }, 1200);
        }else{
            setLoading({ ...loading, loadMore: false  });
        } 
    }, [loading.loadMore]);


    useEffect(() => {
        if(categoryState.error){
            toast.error(categoryState.error.message);
            setLoading({ ['init']: false, ['loadMore']: false, ['delete']: false, update: false });
        }

        if(!categoryState.error){
            if( categoryState.all && categoryState.all.success){
                if((categories.length < categoryState.all.totalCount)){
                    setCategories([ ...categories, ...categoryState.all.categories]);
                }else{
                    setCategories(categoryState.all.categories);
                }
                setTotalPages(Math.ceil(categoryState.all.totalCount/limitPerPage));
                setLoading({ init: false, loadMore: false, update: false });
                if(page > 0 ){
                    window.scrollTo(0, document.documentElement.scrollHeight - 10);
                    // window.scrollTo(0, scrollHeight - 10);
                }
            }
            
            // if( categoryState.update && categoryState.update.success){
            //     toast.success(categoryState.update.message)
            //     setLoading({ update: false });
            //     listCategories()
            // }

            if( categoryState.deleted && categoryState.deleted.success){
                toast.success(categoryState.deleted.message);
                setLoading({ ['delete']: false });
            }
        }

    }, [categoryState]);

    /** ---- Handle Scroll ---- */
    function handleScroll() {
        const bottomOfWindow =  checkScrolledToBottom() ; 
        // console.log(document.documentElement.offsetHeight - )
        
        if (bottomOfWindow) {
            setLoading({ loadMore: true});
        }
    }


    const handleDelete = (id) => {
        swal({
            title: "Are you sure ?",
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: true,
        })
        .then( async(willDelete) => {
            if (willDelete) {
                setLoading({ ['delete']: true });
                dispatch(deleteCategory(id));
                let updatedCategories = categories.filter(category => category._id !== id);
                setCategories(updatedCategories);
            } 
        });
    }

    const handleUpdateActiveStatus = (category) => {
        setLoading({ update: true })
        let status = !category.is_active
        let data = { is_active: status }

        setCategories(categories.map(cat => {
            if (cat._id === category._id) {
                // Update the status of the category with the matching _id
                cat.is_active =  data.is_active; // Replace 'updatedStatus' with the desired status
            }
            return cat;
        }));
        dispatch( updateCategory(category._id, data) );
    }

    const openRestrictModal = (_category) => {
        setShowRestrictedModal(true);
        setCategory(_category);
    }


    const closeRestrictModal = () => {
        setShowRestrictedModal(false);
        setCategory(null);
    }

    const renderTable = () => {
        return (
        <>
            <Table striped responsive="sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Cover</th>
                        <th>Name</th>
                        <th>Product</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Restricted Locations</th>
                        <th className="edit-service">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map((category, categoryIndex) => (
                        <tr key={categoryIndex}>
                            <td>{categoryIndex + 1}</td>
                            <td>
                                {/* {category.profile ? <>
                                <img src={category.profile.url} height={40} width={40} style={{ borderRadius:'50%'}}/></> 
                                    : generateLetterSVG(category.name, 36)
                                } */}
                                <img 
                                    height={40} 
                                    width={40} 
                                    style={{ borderRadius:'50%'}}
                                    src={category.profile
                                        ? category.profile.url
                                        : generateLetterSVG(category.name, 36)
                                        }
                                    alt='profile picture' 
                                />
                            </td>
                            <td>{category.name}</td>
                            <td>{category.bbservice  
                                ? typeof category.bbservice === 'object' 
                                    ? category.bbservice.name 
                                    :  category.bbservice
                                : 'N/A'
                                }</td>
                            <td>{momentFormat.dateTimeFormat(category.createdAt)}</td>
                            <td>
                                {/* <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch"
                                    label={category.is_active ? 'Active' : "Inactive"}
                                    checked={category.is_active}
                                    onChange={() => handleUpdateActiveStatus(category)}
                                /> */}
                                {category.is_active ? 'Active' : "Inactive"}
                            </td>
                            <td>
                                <Button size='sm' onClick={() => openRestrictModal(category)}>View</Button>
                            </td>
                            <td className="edit-service"> 
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <AiOutlineEye />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item 
                                            onClick={() => navigate(`/category/${category._id}`,
                                            { state: { category }}
                                            )}
                                        >
                                            <BiEdit /> Update
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item 
                                            onClick={() => handleDelete(category._id)}
                                        >
                                            <BiTrash /> Delete
                                        </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {loading.loadMore ? <h4> Loading... </h4> : null}
        </>
           
    )}

    const showAddButton = () => {
        return (
            <Button className="ms-auto" onClick={() => navigate('/category/add')}>Add Category</Button>
        )
    }

    
    return (
        <section className="popular--Wrapper edit--page services-page-all">
            <Container fluid>
                <Row>
                    <Col sm={12} className="px-0">
                        <div className="sidebar--wrapper">
                            <div className="admin--content">
                                <Container>
                                    <Row>
                                        <div className="all-servicess-Wrap">
                                            <h2>Categories</h2>
                                            {categories.length !== 0 && showAddButton()}
                                        </div>
                                        {loading.init
                                        ?   <div className="loader-main">
                                                <RotatingLines
                                                    strokeColor="grey"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="80"
                                                    visible={true}
                                                />
                                            </div>
                                        :   <Accordion 
                                                defaultActiveKey="0" 
                                                className="services-main-wraper"
                                            >
                                                {(categories.length > 0)
                                                ? renderTable()
                                                : <h3>No Record Available</h3>
                                                } 

                                                {(categories.length === 0 && !loading.init) &&  showAddButton()}
                                            </Accordion>
                                        }
                                    </Row>
                                </Container>
                                {showRestrictedModal && 
                                    <RestrictCategoryModal 
                                        category={category}
                                        closeRestrictModal={closeRestrictModal}
                                        showRestrictedModal={showRestrictedModal}
                                        // restrictedLoader={}
                                    />
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default CategoriesList;