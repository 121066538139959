import React, { useEffect, useRef, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import TagsInput from "react-tagsinput";
import { createCategory, updateCategory } from 'redux/actions/category.action';
import { getOneCategory } from 'redux/actions/category.action';
import 'react-tagsinput/react-tagsinput.css';
import { getAllBBServices } from 'redux/actions/serviceBB.action';
import { serviceTypeName } from 'utils/validations';
import { notValid } from 'utils/validations';
import { uploadFilesToCloud } from 'redux/actions/cloudinary.action';
import { resetCategoryState } from 'redux/actions/category.action';
const defaultImg = require('../../assets/images/placeholder.png')

let initialValues = { name:null, bbservice:null, profile: null }
const CategoriesAddOrUpdate = () => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const [ category, setCategory ] = useState({ ...initialValues});
    const [ error, setError ] = useState({ name: ''});
    const [ isDisable, setIsDisable ] = useState(false);
    
    const [ uploadProgress, setUploadProgress] = useState(0);
    const [ fileLoading , setFileLoading ] = useState(false);

    const [ bbService, setBBService] = useState([]);

    const [ isEditable, setIsEditable ] = useState(id ?  true : false);
    const [ loading, setLoading ] = useState({ init: id ? true: false, createUpdate: false});

    const categoryState = useSelector(state => state.category);
    /** --- Reducer State --- */
    const serviceState = useSelector(state => state.serviceBB);

    useEffect(() => {
        dispatch(getAllBBServices({page:0, limit:1000, is_active: true}))
    }, [])

    useEffect(() => {
        if(id){ 
            setIsEditable(true);
            dispatch(getOneCategory(id));
        }else{
            setIsEditable(false);
        }
    }, [id])

    useEffect(() => {
        // console.log( serviceState.getAllServiceBB )
        if(!serviceState.error){
            const { getAllServiceBB } = serviceState;
            if (getAllServiceBB && getAllServiceBB.success) {
                setBBService(getAllServiceBB.bbService)
            }
        }
    }, [serviceState])


    const handleChangeInput = (target) => {
        const { value, name } = target
        setError({ [name]: ''});
        // checkForErrors(name, value);
        setCategory({ ...category, [name]: value });
    }

    // const handleUploadFile = (e) => {
    //     setError({ ...error, ['profile']: ''});
    //     // checkForErrors('profile')
    //     const _files = e.target.files
    //     const filePromises = [];

    //     for (let i = 0; i < _files.length; i++) {
    //         const file = _files[i];
    //         const reader = new FileReader();
    //         const promise = new Promise((resolve, reject) => {
    //             if( file.size > (1024 * 1024) * 10){
    //                 reject(file.name + ' size should be less than 10mb' );
    //             }
    //             reader.onload = (e) => {
    //                 const newFile = {
    //                     name: file.name,
    //                     fileType: file.type,
    //                     url: e.target.result,
    //                 }
              
    //                 resolve(newFile);
    //             };
        
    //             reader.onerror = (error) => {
    //                 reject(error);
    //             };

    //         });

    //         filePromises.push(promise);
    //         reader.readAsDataURL(file);
    //     }
  
    //     Promise.all(filePromises)
    //     .then((file) => {
    //         console.log( 'DDF', file )
    //         setCategory({ ...category, profile: file[0]});
    //     })
    //     .catch((err) => {
    //         setError({ ...error, ['profile']:  'Please try uploading another Cover Photo'});
    //         console.error(err);
    //     });
    // }

    const handleUploadFile = async (event, field) => {
        setError({ ...error, [field]: '' });
    
        const files = event.target.files;
        const formData = new FormData();
        let hasError = false;
    
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if( !['image/jpeg' , 'image/png' , 'image/jpg'].includes(file.type)){
              setError({ ...error, [field]: file.name + ' file type is not supported' });
              hasError = true; // Set hasError to true if there's an error
              break; // Exit the loop early if there's an error
            }
  
  
            if (file.size > (1024 * 1024) * 10) {
                setError({ ...error, [field]: file.name + ' size should be less than 10mb' });
                hasError = true; // Set hasError to true if there's an error
                break; // Exit the loop early if there's an error
            }
            
            formData.append('files', file);
    
        }
    
        if (!hasError && formData.has('files') ) { // Proceed with upload only if there are no errors
            const response = await uploadFilesToCloud(formData, setUploadProgress, setFileLoading);
            if (response && response.data && response.data.success) {
                setCategory({ ...category, profile: response.data.files[0]});
            }
        }
    };

    const isValid = () => {
        let isValid = true
        let err = { ...error }

        if (notValid(category.name)) {
            err.name = "Please provide service name"
            isValid = false
        }

        if(notValid(category.bbservice)){
            err.bbservice = "Please select product"
            isValid = false 
        }

        // if(notValid(category.profile)){
        //     err.profile = "Please upload cover photo"
        //     isValid = false 
        // }

        setError({ ...err });
        return isValid
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if(isValid()){
            setLoading({ createUpdate: true});
            if(isEditable){
                let data = { name: category.name, profile: category.profile, bbservice: category.bbservice }
    
                dispatch( updateCategory(id, data) );
            }else{
                dispatch( createCategory(category) );
            }
        }
    }


    useEffect(() => {
        if( categoryState.error ){
            toast.error(categoryState.error.message)
            setLoading({ init: false, createUpdate: false });
        }

        if( !categoryState.error ){
            if( categoryState.create && categoryState.create.success){
                toast.success(categoryState.create.message)
                setLoading({ init: false, createUpdate: false });
                setCategory(initialValues);
            }

            if( categoryState.update && categoryState.update.success){
                toast.success(categoryState.update.message)
                setLoading({ init: false, createUpdate: false });
                navigate('/categories')
            }

            if( categoryState.getOne && categoryState.getOne.success){
                setCategory(categoryState.getOne.category);
            }
        }   
    }, [categoryState])

    const handleClickImage = () => {
        fileInputRef.current.click();
    };


    return (
        <div className='main-add-service'>
            <h2>{isEditable ? 'Update': 'Add'} Category</h2>
            <Button className="mt-3 mb-3" onClick={() => {
                dispatch(resetCategoryState())
                navigate('/categories')
            }}>
                Back
            </Button>
            <div className="form-add-service">
                <Form onSubmit={onSubmit}>
                    
                    <Form.Label>Cover Photo</Form.Label>
                    <Form.Group className="mb-3">
                        <img 
                            src={category.profile ? category.profile.url : defaultImg} 
                            height={125} 
                            width={125} 
                            style={{ borderRadius: '50%', cursor:'pointer'}}
                            onClick={() => handleClickImage()}
                            
                        />
                        <Form.Control
                            type="file"
                            name="profile"
                            ref={fileInputRef}
                            // value={category.profile category.profile.name}
                            style={{ display: 'none'}}
                            hidden
                            onChange={(e) => handleUploadFile(e, 'profile')}
                            disabled={fileLoading}
                        />
                         {/* {uploadProgress > 0 && <p>Uploaded {uploadProgress}%</p>} */}
                         {uploadProgress > 0 && <p >{uploadProgress === 100 ? 'Uploaded' : 'Uploading...'} {uploadProgress}%</p>}
                        {error.profile && <span className="error">{error.profile}</span>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Category Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Category Name"
                            name="name"
                            value={category?.name || ''}
                            onChange={({ target }) => handleChangeInput(target)}
                        />
                        {error.name && <span className="error">{error.name}</span>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Select Product</Form.Label>
                        {/* <TagsInput
                            id=' tags'
                            value={category?.sub_category || [] }
                            // inputProps={{ placeholder: "Add Categories / Tags" }}
                            className='form-control input'
                            onChange={(e) => setCategory({ ...category, ['sub_category']: e })}
                            renderInput={(props) => <input {...props} placeholder="Type and press Enter" />}
                            inputValue={''} // This clears the input after selecting a suggestion
                            addOnBlur={true}
                            addKeys={[9, 13, 32]} // Allow Tab, Enter, and Space to add tags
                        /> */}

                        <Form.Select 
                            aria-label="bbservice"
                            name="bbservice"
                            className="filter--service--type"
                            onChange={({ target }) => handleChangeInput(target)}
                            value={category.bbservice 
                                    ?  typeof category.bbservice === 'object'
                                        ?  category.bbservice._id 
                                        : category.bbservice
                                    : ''}

                        >
                            <option value="">Select Product</option>
                            {bbService.map((_bbService, _bbServiceIndex) => (
                                <option value={_bbService._id}>{_bbService.name}</option>
                            ))}
                        </Form.Select>
                        {error.bbservice && <span className="error">{error.bbservice}</span>}
                    </Form.Group>
                    <div className="text-center add-service-btn">
                        <Button 
                            variant="primary" 
                            type="submit" 
                            disabled={loading.createUpdate}
                        >
                            {loading.createUpdate ? 'Please Wait...' : isEditable ? 'Update' : 'Create'}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default CategoriesAddOrUpdate;