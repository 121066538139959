import { get } from 'lodash';
import API from 'helpers/api';
import * as auth from 'helpers/auth';
import { 
  LOGIN_FAILED, 
  LOGIN_SUCCESS,
  AUTH_COMMON_ERROR, 
  POST_JOIN_BROKER_SUCCESS,
  POST_JOIN_BROKER_FAILED,

  LOGIN_LIST_BROKER_COMPANIES,
  LOGIN_FAILED_UPDATE_PASSWORD,
  PATCH_FIRST_NEW_PASSWORD_SUCCESS,
  PATCH_FIRST_NEW_PASSWORD_FAILED,
  LOGIN_SUCCESS_UNPAID_SUBSCRIPTION,
  POST_VERIFY_INVITE_SUCCESS,
  POST_VERIFY_INVITE_FAILED,

  REQUEST_OTP_SUCCESS,
  REQUEST_OTP_FAILED,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
} from "./types";

// import { setSubscriptionSession } from 'helpers/auth';

const config = {
  headers: {
    'Content-Type': "application/json; charset=utf-8"
  }
}

function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if(data.error){
        dispatch({
            type: AUTH_COMMON_ERROR,
            payload: data.error.message,
          });
    }else{
        if(data.status === 426){  // used for Initial Update Password 
            dispatch({
              type: LOGIN_FAILED_UPDATE_PASSWORD,
              payload: data.message,
            })
        }else if(data.status === 402){   // used for company -> unpaid subscription 
            auth.logout()
        }else{
          dispatch({
              type: AUTH_COMMON_ERROR,
              payload: data.message,
          });
        }

    }
}

// export const login = (payload)  => {
//     return async (dispatch) => {
//       try {
//         const response = await API.apiPost('login', payload, config);
//         /** -- If Response Success -- */
//         if (response.data && response.data.success) {
//           /** -- If Response with token -- */
//           if(response.data.accessToken){
//             auth.login(response.data.accessToken);
//             /** -- If Response with token and unpaidSubscription -- */
//             if(response.data.unpaidSubscription){
//               auth.setSubscriptionSession(true)
//               await dispatch({ type: LOGIN_SUCCESS_UNPAID_SUBSCRIPTION, payload: response.data })
//             }else{
//               await dispatch({ type: LOGIN_SUCCESS, payload: response.data.accessToken });
//             }
//           }else{
//             /* -- If Response with no access token and contains Broker List -- */
//             await dispatch({ type:LOGIN_LIST_BROKER_COMPANIES, payload: response.data })
//           }
//         }else{
//           await dispatch({ type: LOGIN_FAILED, payload: response.data.message });
//         }
//       } catch (err) {
//         errorRequest(err, dispatch);
//       }
//     };
// }

export const joinBroker = (payload) => {
  return async (dispatch) => {
    try {
        const response = await API.apiPostUrl('login', '/join/broker', payload);
        if (response.data && response.data.success) {
          await dispatch({ type:POST_JOIN_BROKER_SUCCESS, payload: response.data })
        }else{
          await dispatch({ type:POST_JOIN_BROKER_FAILED, payload: response.data.message })
        }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}

export const verifyInviteLink = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('login', '/invite/verify', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: POST_VERIFY_INVITE_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: POST_VERIFY_INVITE_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const createNewPassword = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPatchUrl('login', '/password', payload)
      if (response.data && response.data.success) {
        await dispatch({ type: PATCH_FIRST_NEW_PASSWORD_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: PATCH_FIRST_NEW_PASSWORD_FAILED, payload: response.data.message });
      }
    } catch (err) {
      errorRequest(err, dispatch);
    }
  };
}

export function logout() {
    return (dispatch) => {
     try {
        auth.logout();
     } catch (err) {
         errorRequest(err, dispatch);
     }
    };
}
  


////////////////////////////////////////////////// new code BB admin /////////////////////////////


export const login = (payload)  => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl("auth","/" , payload);
        /** -- If Response Success -- */
        if(response.data.accessToken){
        /** -- If Response with token and unpaidSubscription -- */
          auth.login(response.data.accessToken);
          await dispatch({ type: LOGIN_SUCCESS, payload: response.data.accessToken });
        }else{
          await dispatch({ type: LOGIN_FAILED, payload: response.data.message });
        }
    } catch (err) {
      errorRequest(err, dispatch);
    }
  };
}

export const requestOtp = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('otpRequest', '/', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: REQUEST_OTP_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: REQUEST_OTP_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}

export const verifyOtp = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('otpVerify', '/', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: VERIFY_OTP_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: VERIFY_OTP_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}

export const resetPassword = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPatchUrl('resetPass', '/', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: RESET_PASSWORD_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}