/**
 *  Defined Each API URL as keys to use in project
 */
const apiKeys = {
    auth: "/auth",
    otpRequest:"/auth/otp/request",
    otpVerify:"/auth/otp/verify",
    resetPass:"/auth/password/reset",
    bbServices:'/bbServices',
    settings:"/settings",
    buyerUser:"/users?role=buyer",
    sellerUser:"/users?role=seller",
    buyerUserEdit:"/users/edit",
    userServices:"/userServices",
    users:'/users',
    userStaff:'/users/staff',
    orders: '/orders',
    places:'/places',
    category: '/category',
    subcription: '/subscriptionPlans',
    cloudinary:'/cloudinary',
    transaction:'/transaction',
    categoryRestrictions: '/categoryRestrictions'
};
  
export default apiKeys;
  