import {
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILED,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAILED,
} from "redux/actions/types";

const initialState = {
    error: null,
    all: null,
    updated: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SETTINGS_SUCCESS:
            return {
                all: action.payload,
                error: null
            }
        case GET_SETTINGS_FAILED:
            return {
                error: action.payload,
            }
        case UPDATE_SETTINGS_SUCCESS:
            return {
                updated: action.payload,
                error: null
            }
        case UPDATE_SETTINGS_FAILED:
            return {
                error: action.payload
            }
        default: return state;
    }
};
