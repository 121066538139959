import React, { useEffect, useState } from "react";
import { Container, Row, Form, Button, Col } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createNewPassword } from "redux/actions/auth.actions";
const logo = require('assets/images/logos/main.png');

const UpdatePassword = (props) => {
    const dispatch = useDispatch();
    const [loader, setLoader ] = useState( false )
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordInput, setPasswordInput]= useState({
        password:'',
        confirmPassword:''
    })

    /** ---- Redux State -- */
    const updateMessage = useSelector(state => state.auth.updateMessage )
    const apiError = useSelector(state => state.auth.error)

    const handlePasswordChange =(evnt)=>{
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        const NewPasswordInput = { ...passwordInput, [passwordInputFieldName]: passwordInputValue}
        setPasswordInput(NewPasswordInput);
    }

    const handleValidation= ({ target : { name, value }})=>{
        value = value.trim();
        const passwordInputValue = value;
        const passwordInputFieldName = name;

        /** -- For Password -- */
        if(passwordInputFieldName === 'password'){
            // const uppercaseRegExp   = /(?=.*?[A-Z])/;
            // const lowercaseRegExp   = /(?=.*?[a-z])/;
            // const digitsRegExp      = /(?=.*?[0-9])/;
            // const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            // const minLengthRegExp   = /.{8,}/;

            const passwordLength =      passwordInputValue.length;
            /*   -----If Needed We Can include this ----- */
            /*
            const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
            const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
            const digitsPassword =      digitsRegExp.test(passwordInputValue);
            const specialCharPassword = specialCharRegExp.test(passwordInputValue);
            const minLengthPassword =   minLengthRegExp.test(passwordInputValue);
            */
            let errMsg ="";
            if( passwordLength === 0 ){
                    errMsg="Password is empty";
            }
            /**----- If Needed Password Strong We Can Include these lines -- */
            /* 
            else if(!uppercasePassword){
                    errMsg="At least one Uppercase";
            }else if(!lowercasePassword){
                    errMsg="At least one Lowercase";
            }else if(!digitsPassword){
                    errMsg="At least one digit";
            }else if(!specialCharPassword){
                    errMsg="At least one Special Characters";
            }else if(!minLengthPassword){
                    errMsg="At least minumum 8 characters";
            }
            */

            else{
                errMsg="";
            }
            setPasswordError(errMsg);
        }

        /** -- For Confirm Password --  */
        if(passwordInputFieldName=== "confirmPassword" || (passwordInputFieldName==="password" && passwordInput.confirmPassword.length>0) ){
            if(passwordInput.confirmPassword!==passwordInput.password) {
                setConfirmPasswordError("Confirm password is not matched");
            }else{
                setConfirmPasswordError("");
            }   
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoader( true )
        const payload = { 
            password: passwordInput.password, 
            oldPassword: props.user.password, 
            email: props.user.email
        }
        dispatch(createNewPassword(payload))
    }

    useEffect(() => {
        if(updateMessage){
            toast.success(updateMessage)
            setLoader(false)
            props.setUpdatePassword(false);
        }
        if(apiError){
            setLoader(false)
        }
    }, [updateMessage, apiError])

    return (
        <section className="wrapperMain h-100">
            <Container className="h-100">
                <Row className="justify-content-center align-items-center h-100">
                    <Col sm={12} lg={5}>
                        <div className="formTop p-4 shadow-lg rounded">
                            <div className="text-center">
                                <img src={logo} className="App-logo mb-3" alt="logo" />
                                <h2>Update Password</h2>
                                {/* <p>Enter your email and we′ll send you instructions to reset your password</p> */}
                            </div>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control 
                                        type="password" 
                                        placeholder="New Password"
                                        name="password"
                                        value={passwordInput.password}
                                        onChange={handlePasswordChange}
                                        onKeyUp={handleValidation} 
                                    />
                                    {passwordError && <span className="text-danger">{passwordError}</span>}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <Form.Control 
                                        type="password"
                                        name="confirmPassword" 
                                        placeholder="Confirm New Password"
                                        value={passwordInput.confirmPassword}
                                        onChange={handlePasswordChange}
                                        onKeyUp={handleValidation} 
                                    />
                                    {confirmPasswordError && <span className="text-danger">{confirmPasswordError}</span>}
                                </Form.Group>
                                <div className="text-center">
                                    <Button 
                                        variant="primary" 
                                        type="submit"
                                        disabled={ loader }
                                    >
                                        {loader ? 'Updating...' : 'Update'}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default UpdatePassword;
