import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Accordion, Form, Dropdown, Modal, Alert } from "react-bootstrap";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
// import { BsChevronDown } from 'react-icons/bs';
import { approveSeller, getAllSellers, updateSubscription } from "../../redux/actions/SellerUser.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
// import { MdVerified,MdDelete } from 'react-icons/md';
import toast from "react-hot-toast";
import Switch from 'react-js-switch';
import swal from 'sweetalert';
// import { Link } from "react-router-dom";
import { RotatingLines } from 'react-loader-spinner'
// import { BiEdit } from "react-icons/bi";
// import { AiOutlineEye } from "react-icons/ai";
// import { IoMdMore } from "react-icons/io";
import API from '../../helpers/api';
// import * as auth from '../../helpers/auth';
import { momentFormat } from "utils/validations";
import { displayShortAddress } from "utils/validations";
import { checkScrolledToBottom } from "utils/scroll";
import { DateRangePicker } from 'react-date-range';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { CSVLink } from "react-csv";
const limitPerPage = process.env.REACT_APP_LISTING_LIMIT

function SellerList() {
    const waiting = 'Waiting On Other Steps'
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Get the value of the 'q' parameter from the URL
    const is_deleted = queryParams.get('is_deleted');
    const [totalPages, setTotalPages] = useState(0);
    let [page, setPage] = useState(0);

    const [resendLoader, setResendLoader] = useState(false);
    const [remindLoader, setRemindLoader] = useState({ 
        isProfileCompleted: '', 
        isServiceCompleted: '', 
        isScheduleCompleted: '', 
        isProfileCompleted_bulk: '', 
        isServiceCompleted_bulk: '', 
        isScheduleCompleted_bulk: '',
        isSoftAccountReminder_bulk:'' 
    });
    const [emailVerifyLoader, setEmailVerifyLoader] = useState(false);

    // const [ testAccLoader, setTestAccLoader] = useState(false);

    const [loading, setLoading] = useState({ sellers: true, approve: '', subscription: false, loadMore: false });
    const [filter, setFilter] = useState({ is_active: 'true', search: '', is_deleted: JSON.parse(is_deleted) ? true : false, emailVerification: '', subscriptionStatus: '', is_business_approved: null, isTestAccount: false })
    const [userSellerData, setuserSellerData] = useState([])  //////  services all Data
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [dateRange, setDateRange] = useState([
        {
            startDate: from,
            endDate: to,
            key: 'selection',
        },
    ]);

    /** ---- custom email modal ---- */
    const [showCustomEmailModal, setShowCustomEmailModal] = useState(false);
    // const [customEmailModalData, setCustomEmailModalData] = useState({ body:'' });

    const sellerState = useSelector(state => state.sellerUser);

    /************ component life cycle handling  **********************************/
    useEffect(() => {
        if (!JSON.parse(is_deleted)) {
            getSellers()
        }
    }, [])

    useEffect(() => {
        setFilter({ ...filter, is_deleted: JSON.parse(is_deleted) ? true : false })
    }, [is_deleted])

    /** ---Add Event Listener --- */
    useEffect(() => {
        // window.addEventListener("scroll", handleScroll);
        // return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (!sellerState.error) {
            const { getAllSellerUser } = sellerState;

            if (getAllSellerUser && getAllSellerUser.success) {
                if (userSellerData.length < getAllSellerUser.totalCount) {
                    var seller = userSellerData;
                    Array.prototype.push.apply(seller, getAllSellerUser.users);
                    seller = seller.filter((v, i, a) => a.findIndex(t => (t._id === v._id)) === i)
                    setuserSellerData(seller)
                } else {
                    setuserSellerData(getAllSellerUser.users);
                }
                setTotalPages(Math.ceil(getAllSellerUser.totalCount / limitPerPage));
                setLoading({ ...loading, sellers: false, loadMore: false, approve: '' });
                if (page > 0) {
                    window.scrollTo(0, document.documentElement.offsetHeight - 20);
                }
            }
            if (sellerState.approveSeller && sellerState.approveSeller.success) {
                toast.success(sellerState.approveSeller.message);
                setPage(0)
                getSellers();
            }
        }
        if (sellerState.error) {
            toast.error(sellerState.error.message)
            setLoading({ sellers: false, approve: '', subscription: false, loadMore: false });
        }
    }, [sellerState])


    useEffect(() => {
        if (page < totalPages) {
            getSellers();
        } else {
            setLoading(prevState => ({
                ...prevState,
                loadMore: false
            }));
        }
    }, [page])

    useEffect(() => {
        setLoading(prevState => ({
            ...prevState,
            sellers: true
        }));
        setPage(0);
        setTotalPages(0);
        setuserSellerData([]);
        // Define a delay for debouncing (e.g., 500 milliseconds)
        const delay = 700;
        // Use a timer to delay the API call after typing stops
        const timerId = setTimeout(() => {
            getSellers();
            // if (filter.search.trim() !== '') {
            // }
        }, delay);

        // Clear the timer on component unmount or when input changes
        return () => clearTimeout(timerId);
    }, [filter]);

    useEffect(() => {
        setFilter({ ...filter, startDate: '', endDate: '' })
    }, [showDateFilter])

    /** ---- Get More Sellers ---- */
    useEffect(() => {
        if (!loading.loadMore) return;
        if (page < totalPages) {
            setTimeout(() => {
                setPage(page + 1)
            }, 1200);
        } else {
            setLoading(prevState => ({
                ...prevState,
                loadMore: false
            }));


        }
    }, [loading.loadMore]);
    /**************************************************************************** */

    /******************  call Api  **********************/
    const getSellers = () => {
        dispatch(getAllSellers({ page, limit: limitPerPage, ...filter }))
    }
    /************************************************************** */

    /** ---- Handle Scroll ---- */
    const handleScroll = () => {
        const { scrollTop, offsetHeight, scrollHeight } = document.documentElement;
        /** --- Detect Bottom Window --- */
        const bottomOfWindow = checkScrolledToBottom(); //scrollHeight - Math.round(scrollTop)  === offsetHeight;
        /** --- Detect Top Of Window */
        // const topOfWindow = Math.round(scrollTop) - innerHeight === offsetHeight ;

        if (bottomOfWindow) {
            setLoading(prevState => ({
                ...prevState,
                loadMore: true
            }));
        }
    }
    /****************************************/

    /************  input handling function  *****************************/

    const switch_onChange_handle = async (item) => {
        if (item.is_active == true) {
            const payload = {
                is_active: false
            }
            swal({
                title: "Are you sure seller suspend?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        try {
                            let updatedSeller = userSellerData.filter(seller => seller._id !== item._id);
                            setuserSellerData(updatedSeller)
                            setPage(0)
                            // const response = await API.apiPostUrl('buyerUserEdit',`/${item._id}`, payload,config);
                            const response = await API.apiPostUrl('buyerUserEdit', `/${item._id}`, payload);
                            if (response.data.message) {
                                swal("Good Job", "seller suspend successfully");
                                getSellers()
                            }
                        } catch (error) {
                            if (error.response && error.response.data) {
                                toast.error(error.response.data.message)
                            } else {
                                toast.error(error.message)
                            }
                        }
                    }
                });
        } else {
            const payload = {
                is_active: true
            }
            swal({
                title: "Are you sure to active?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willActive) => {
                    if (willActive) {
                        try {
                            let updatedSeller = userSellerData.filter(seller => seller._id !== item._id);
                            setuserSellerData(updatedSeller)
                            setPage(0)
                            const response = await API.apiPostUrl('buyerUserEdit', `/${item._id}`, payload);
                            if (response.data.message) {
                                swal("Good Job", "seller unsuspend successfully");
                                setPage(0)
                                getSellers()
                            }
                        } catch (error) {
                            if (error.response && error.response.data) {
                                toast.error(error.response.data.message)
                            } else {
                                toast.error(error.message)
                            }
                        }
                    }
                });
        }
    }

    const handeIsOutOfCountry = async (item) => {
        if (item.isOutOfCountry == true) {
            const payload = {
                isOutOfCountry: false
            }
            swal({
                title: "Are you sure?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        try {
                            let updatedSeller = userSellerData.filter(seller => seller._id !== item._id);
                            setuserSellerData(updatedSeller)
                            setPage(0)
                            // const response = await API.apiPostUrl('buyerUserEdit',`/${item._id}`, payload,config);
                            const response = await API.apiPostUrl('buyerUserEdit', `/${item._id}`, payload);
                            if (response.data.message) {
                                swal("Good Job", "seller disabled successfully");
                                getSellers()
                            }
                        } catch (error) {
                            if (error.response && error.response.data) {
                                toast.error(error.response.data.message)
                            } else {
                                toast.error(error.message)
                            }
                        }
                    }
                });
        } else {
            const payload = {
                isOutOfCountry: true
            }
            swal({
                title: "Are you sure?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willActive) => {
                    if (willActive) {
                        try {
                            let updatedSeller = userSellerData.filter(seller => seller._id !== item._id);
                            setuserSellerData(updatedSeller)
                            setPage(0)
                            const response = await API.apiPostUrl('buyerUserEdit', `/${item._id}`, payload);
                            if (response.data.message) {
                                swal("Good Job", "seller enabled successfully");
                                setPage(0)
                                getSellers()
                            }
                        } catch (error) {
                            if (error.response && error.response.data) {
                                toast.error(error.response.data.message)
                            } else {
                                toast.error(error.message)
                            }
                        }
                    }
                });
        }
    }
    // const handleDisable = async (item) => {
    //     const isDisabling = item.is_disable; // Determine the current state
    //     const payload = { is_disable: !isDisabling }; // Toggle the state
    //     const actionText = isDisabling ? "disable" : "activate"; // Dynamic action text
    //     const confirmationTitle = `Are you sure to ${isDisabling ? "disable" : "activate"} this seller?`;
    
    //     swal({
    //         title: confirmationTitle,
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     }).then(async (confirmed) => {
    //         if (confirmed) {
    //             try {
    //                 // Update UI optimistically
    //                 const updatedSellers = userSellerData.filter(seller => seller._id !== item._id);
    //                 setuserSellerData(updatedSellers);
    //                 setPage(0);
    
    //                 // Send API request
    //                 const response = await API.apiPostUrl('buyerUserEdit', `/${item._id}`, payload);
    
    //                 // Handle success response
    //                 if (response.data.message) {
    //                     swal("Good Job", `Seller ${actionText}d successfully!`);
    //                     getSellers();
    //                 }
    //             } catch (error) {
    //                 // Handle error response
    //                 const errorMessage = error.response?.data?.message || error.message;
    //                 toast.error(errorMessage);
    //             }
    //         }
    //     });
    // };

    const handleApporve = (userId) => {
        swal({
            title: "Are you sure to approve ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willApporve) => {
                if (willApporve) {
                    setLoading(prevState => ({
                        ...prevState,
                        approve: userId
                    }));
                    dispatch(approveSeller({ userId }))
                }
            });
    }

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setPage(0);
        setTotalPages(0);
        setuserSellerData([])

        setFilter({ ...filter, [name]: name === "isTestAccount" ? !filter.isTestAccount : value });
    }
    /*************  using for sort data by date  *****************/
    const handleDateRangeChange = (ranges) => {
        const { startDate, endDate } = ranges.selection;
        setPage(0);
        setTotalPages(0);
        setuserSellerData([])
        setFilter({ ...filter, startDate: startDate, endDate: endDate })
        setDateRange([ranges.selection]);
    }

    const handleShowDateFilter = () => {
        setShowDateFilter(!showDateFilter)
        setPage(0);
        setTotalPages(0);
        setDateRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
        ]);
    }
    /******************************************************************* */

    const handleViewActiveTrash = () => {
        filter.is_deleted
            ? navigate('/seller/list')
            : navigate('/seller/list?is_deleted=true')

        let q = {
            search: '', service_type: 'all'
        }
        if (filter.is_deleted) {
            q.is_active = true
            q.is_deleted = false
        }

        setFilter({ ...filter, ...q })
    }

    /**************************************************************/


    // const handleSubscription = (item) => {
    //     setShowSubscription(true);
    //     let payload = {
    //         subscriptionAmount: '',
    //         // subscriptionTenure: '',
    //         // subscriptionEndDate:'',
    //     }
    //     if( item.subscription ){
    //         payload.subscriptionAmount = item.subscription.subscriptionAmount
    //         payload.associatedWithSeller = item._id
    //         payload.subscriptionStatus = item.subscription.subscriptionStatus
    //         // payload.subscriptionTenure = item.subscription.subscriptionTenure
    //         // payload.subscriptionEndDate = item.subscription.subscriptionEndDate
    //         setSubscriptionData(payload);
    //     }else{
    //         setSubscriptionData({
    //             subscriptionAmount:0,
    //             associatedWithSeller: item._id,
    //             subscriptionStatus:'Free'
    //             // subscriptionTenure:'month',
    //             // subscriptionEndDate:moment().add(1, 'M').format('YYYY-MM-DD'),
    //         })
    //     }
    // }

    // const handleChangeInput = ({ name, value}) => {
    //     setErrorM('')
    //     if( name === 'subscriptionAmount'){
    //         if( value === ''){
    //             value = 0
    //         }
    //     }
    //     setSubscriptionData({ ...subscriptionData, [name]: value});
    // }

    // const handleCloseModal = () => {
    //     setShowSubscription(false)
    //     setSubscriptionData(null);
    // }

    // const onSuscriptionUpdate = (e) => {
    //     e.preventDefault();
    //     setLoading({ subscription: true})
    //     dispatch(updateSubscription(subscriptionData));
    // }

    const handleResendVerification = async (id) => {
        try {
            setResendLoader(id)
            const response = await API.apiPostUrl('users', `/resent-verification-email/${id}`);
            setResendLoader('')
            if (response.data && response.data.success) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            setResendLoader('')
            console.log('Error: Resend Verification Email', error);
            toast.error(error.message);
        }
    }

    const handleUpdateTestAccount = async (id, isTestAccount) => {
        swal({
            title: isTestAccount ? `Are you sure to remove as test account? ` : `Are you sure to assign as test account?`,
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: false,
        })
            .then(async (update) => {
                if (update) {
                    try {
                        setPage(0)
                        const response = await API.apiPostUrl('users', `/assign-test-account/${id}`);
                        if (response.data && response.data.message) {
                            swal("Good Job", isTestAccount ? "Removed from Test Account" : 'Assigned as Test Account');
                            getSellers()
                        }
                    } catch (error) {
                        if (error.response && error.response.data) {
                            toast.error(error.response.data.message)
                        } else {
                            toast.error(error.message)
                        }
                    }
                }
            });
    }

    /** --- Handling Reminder Email For {type} step --- */
    const handleReminderEmail = async (id, type) => {
        swal({
            title: `Are you sure to send remind email?`,
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: false,
        }).then(async (update) => {
            if (update) {
                try {
                    setRemindLoader({ ...remindLoader, [type]: id })
                    const response = await API.apiPostUrl('users', `/reminder-email`, { userId: id, type: type });
                    setRemindLoader({ ...remindLoader, [type]: '' })
                    if (response.data && response.data.success) {
                        swal("Successfully!", response.data.message);
                    }
                } catch (error) {
                    setRemindLoader({ ...remindLoader, [type]: '' })
                    if (error.response && error.response.data) {
                        toast.error(error.response.data.message)
                    } else {
                        toast.error(error.message)
                    }
                }
            }
        });
    }

    /** ---- Handling Bulk Email ---- */
    /**
     * 
     * @param {String} type email type
     * @param {Object} emailData contains { subject, emailContent }
     */
    const handleBulkEmail = async (type, emailData) => {
        const title = type === 'isCustomEmail' ? 'Are you sure you wants to send email?' : 'Are you sure to send remind email?';
        const payload = emailData ? { type, ...emailData } : { type };

        swal({
            title,
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: false,
        }).then(async (result) => {
            if (result) {
                try {
                    setRemindLoader((prevState) => ({ ...prevState, [`${type}_bulk`]: true }));

                    const response = await API.apiPostUrl('users', '/bulk-reminder-email', payload);

                    if (response.data && response.data.success) {
                        swal("Successfully!", response.data.message);
                    }
                } catch (error) {
                    const errorMessage = error.response?.data?.message || error.message;
                    toast.error(errorMessage);
                } finally {
                    setRemindLoader((prevState) => ({ ...prevState, [`${type}_bulk`]: false }));
                }
            }

            //   if(type === 'isCustomEmail'){
            //     handleCustomEmailModal(); // clse custom modal 
            //   }
        });
    };
    // const handleBulkEmail = async (type, emailContent) => {
    //     swal({
    //         title: type === 'isCustomEmail' ? `Are you sure you wants to send email?` : `Are you sure to send remind email?`,
    //         icon: "warning",
    //         buttons: ['Cancel', 'Yes'],
    //         dangerMode: false,
    //     }).then(async (update) => {
    //         if (update) {
    //             try {
    //                 setRemindLoader({ ...remindLoader, [`${type}_bulk`]: true})

    //                 const response = await API.apiPostUrl('users', `/bulk-reminder-email`, emailContent ? { type: type } : { type:type, emailContent:emailContent});
    //                 setRemindLoader({ ...remindLoader, [`${type}_bulk`]: '' })
    //                 if (response.data && response.data.success) {
    //                     swal("Successfully!", response.data.message);
    //                 }
    //             } catch (error) {

    //                 if (error.response && error.response.data) {
    //                     toast.error(error.response.data.message)
    //                 } else {
    //                     toast.error(error.message)
    //                 }
    //             } finally{
    //                 setRemindLoader({ ...remindLoader, [`${type}_bulk`]: false })
    //             }
    //         }
    //     });
    // }

    const handleVerifyEmail = async (id, type) => {
        swal({
            title: `Are you sure you want to verify the email address?`,
            text: 'This action will grant the user access to certain features.',
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: false,
        }).then(async (result) => {
            if (result) {
                try {
                    setEmailVerifyLoader(id);
                    const response = await API.apiPostUrl('users', `/verify-email/${id}`);
                    setEmailVerifyLoader(false);
                    if (response.data && response.data.success) {
                        swal({
                            icon: 'success',
                            title: response.data.message
                        });
                        getSellers()
                    }
                } catch (error) {
                    setEmailVerifyLoader('');
                    if (error.response && error.response.data) {
                        toast.error(error.response.data.message)
                    } else {
                        toast.error(error.message)
                    }
                }
            }
        });
    }

    const exportToCSV = () => {
        // const csvDataConsole = userSellerData
        //     .filter((it) => !it.is_deleted)
        //     .map((item, ind) => ({
        //         index: ind + 1,
        //         name: item?.name || 'N/A',
        //         email: item.email || 'N/A',
        //         // referralCode: item.referralCode || 'N/A',
        //         // referBy: item.referBy && item.referBy.refer_by ? item.referBy.refer_by.referralCode : 'N/A',
        //         // promoCode: item.promoCode || 'N/A',
        //         // phoneNumber: item.phoneNumber || 'N/A',
        //         // address: item.address ? displayShortAddress(item.address) : 'N/A',
        //         // speciality: (item.speciality && item.speciality.length > 0) ? item.speciality.map(sp => sp.name).join(', ') : 'N/A',
        //         // medicineApproach: (item.medicineApproach && item.medicineApproach.length > 0) ? item.medicineApproach.map(ma => ma).join(', ') : 'N/A',
        //         // subscriptionPlanName: item?.subscription?.associatedWithSubscriptionPlan?.name || 'N/A',
        //         // subscriptionTenure: item?.subscription?.associatedWithSubscriptionPlan?.subscriptionTenure || 'N/A',
        //         // subscriptionStatus: item?.subscription?.subscriptionStatus || 'N/A',
        //         // isSubscribed: item?.subscription?.subscribed ? 'Yes' : 'No',
        //         // nextSubscriptionRenew: item?.subscription?.renewedAt ? momentFormat.dateTimeWithTz(item?.subscription?.renewedAt) : 'N/A',
        //         // promoExpiryDate: item?.subscription?.expiryDate ? momentFormat.dateTimeWithTz(item?.subscription?.expiryDate) : 'N/A',
        //         // emailStatus: item?.emailVerification || 'N/A',
        //         // accountActive: item?.is_active ? 'Yes' : 'No',
        //         // accountApprove: item?.is_business_approved ? 'Yes' : 'No',
        //         // isProfileCompleted: item?.is_profile_completed ? 'Yes' : 'No',
        //         // isServicesCompleted: (item.services && item.services.length > 1) ? 'Yes' : 'No',
        //         // isScheduleCompleted: item.totalExistSchedules > 0 ? 'Yes' : 'No',
        //         // bridgerTestAccount: item?.isTestAccount ? 'Yes' : 'No',
        //         // bridgerRegisteredDate: momentFormat.dateTimeWithTz(item.createdAt)
        //     }));
        // console.log('csvDataConsole', csvDataConsole);

        const csvData = userSellerData
            .filter((it) => !it.is_deleted)
            .map((item, ind) => ({
                index: ind + 1,
                name: item?.name || 'N/A',
                email: item.email || 'N/A',
                referralCode: item.referralCode || 'N/A',
                referBy: item.referBy && item.referBy.refer_by ? item.referBy.refer_by.referralCode : 'N/A',
                promoCode: item.promoCode || 'N/A',
                phoneNumber: item.phoneNumber || 'N/A',
                address: item.address ? displayShortAddress(item.address) : 'N/A',
                speciality: (item.speciality && item.speciality.length > 0) ? item.speciality.map(sp => sp.name).join(', ') : 'N/A',
                medicineApproach: (item.medicineApproach && item.medicineApproach.length > 0) ? item.medicineApproach.map(ma => ma).join(', ') : 'N/A',
                subscriptionPlanName: item?.subscription?.associatedWithSubscriptionPlan?.name || 'N/A',
                subscriptionTenure: item?.subscription?.associatedWithSubscriptionPlan?.subscriptionTenure || 'N/A',
                subscriptionStatus: item?.subscription?.subscriptionStatus || 'N/A',
                isSubscribed: item?.subscription?.subscribed ? 'Yes' : 'No',
                nextSubscriptionRenew: item?.subscription?.renewedAt ? momentFormat.dateTimeWithTz(item?.subscription?.renewedAt) : 'N/A',
                promoExpiryDate: item?.subscription?.expiryDate ? momentFormat.dateTimeWithTz(item?.subscription?.expiryDate) : 'N/A',
                emailStatus: item?.emailVerification || 'N/A',
                accountActive: item?.is_active ? 'Yes' : 'No',
                accountApprove: item?.is_business_approved ? 'Yes' : 'No',
                isProfileCompleted: item?.is_profile_completed ? 'Yes' : 'No',
                isServicesCompleted: (item.services && item.services.length > 1) ? 'Yes' : 'No',
                isScheduleCompleted: item.totalExistSchedules > 0 ? 'Yes' : 'No',
                bridgerTestAccount: item?.isTestAccount ? 'Yes' : 'No',
                bridgerRegisteredDate: momentFormat.dateTimeWithTz(item.createdAt)
            }));
        return csvData;
    };

    // const handleCustomEmailModal = () => {
    //     setShowCustomEmailModal(!showCustomEmailModal);
    // }

    console.log('userSellerData',userSellerData)
    const renderTable = () => {
        return (
            <>
                <div className="sellers-mgt-btn">
                    <CSVLink
                        data={exportToCSV()}
                        filename={"user_data.csv"}
                        className="btn btn-primary"
                        target="_blank"
                    >
                        Export to CSV
                    </CSVLink>
                    <Button
                        className="mr-1"
                        style={{margin:5}}
                        onClick={() => handleBulkEmail('isProfileCompleted')}
                        disabled={remindLoader.isProfileCompleted_bulk}
                    >
                        {remindLoader.isProfileCompleted_bulk ? 'Please Wait...' : 'Bulk Profile Reminder'}
                    </Button>
                    <Button
                        className="mr-1"
                        style={{margin:5}}
                        onClick={() => handleBulkEmail('isServiceCompleted')}
                        disabled={remindLoader.isServiceCompleted_bulk}
                    >
                        {remindLoader.isServiceCompleted_bulk ? 'Please Wait...' : 'Bulk Service Reminder'}
                    </Button>
                    <Button
                        className="mr-1"
                        style={{margin:5}}
                        onClick={() => handleBulkEmail('isScheduleCompleted')}
                        disabled={remindLoader.isScheduleCompleted_bulk}
                    >
                        {remindLoader.isScheduleCompleted_bulk ? 'Please Wait...' : "Bulk Schedule Reminder"}
                    </Button>

                    <Button
                        className="mr-1"
                        style={{margin:5}}
                        onClick={() => handleBulkEmail('isSoftAccountReminder')}
                        disabled={remindLoader.isSoftAccountReminder_bulk}
                    >
                        {remindLoader.isSoftAccountReminder_bulk ? 'Please Wait...' : "Bulk Soft Account Reminder"}
                    </Button>
                    {/* <Button
                        className="mr-1"
                        style={{margin:5}}
                        onClick={() => handleCustomEmailModal()}
                        // disabled={remindLoader.isScheduleCompleted_bulk}
                    >
                        Create Custom e-mail
                    </Button> */}
                </div>
                <TableContainer component={Paper} style={{ maxHeight: 700 }}>
                    <Table stickyHeader aria-label="sticky table" striped responsive="sm">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Submitted Account</TableCell>
                                <TableCell>Own Referral Code</TableCell>
                                <TableCell>Refer By</TableCell>
                                <TableCell>Promo Code</TableCell>
                                <TableCell>Phone Number</TableCell>

                                <TableCell>Address</TableCell>
                                <TableCell>Signup Speciality</TableCell>
                                <TableCell>Medicine Approach</TableCell>
                                <TableCell>How Reached Us?</TableCell>
                                <TableCell>Subscription Plan Name</TableCell>
                                <TableCell>Subscription Tenure</TableCell>
                                <TableCell>Subscription Status</TableCell>
                                <TableCell>Is Subscribed</TableCell>
                                <TableCell>Next Subscription Renew</TableCell>
                                <TableCell>Promo Expiry Date</TableCell>
                                <TableCell>Email Status</TableCell>
                                <TableCell>Account Active</TableCell>
                                <TableCell>Account isOutOfCountry</TableCell>
                                <TableCell>Account Approve</TableCell>

                                <TableCell>Is Profile Completed</TableCell>
                                <TableCell>Is Services Completed</TableCell>
                                <TableCell>Is Schedule Completed</TableCell>
                                <TableCell>Bridger Test Account</TableCell>
                                <TableCell>Bridger Registered Date</TableCell>
                                <TableCell>Bridger Last Login Date</TableCell>
                                <TableCell className="edit-service">View</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userSellerData.filter((it) => !it.is_deleted).map((item, ind) => {
                                return <TableRow key={ind}>
                                    <TableCell>{ind + 1}</TableCell>
                                    <TableCell>{item?.name}</TableCell>
                                    <TableCell>{item?.email}</TableCell>

                                    <TableCell>{item?.emailVerification === 'pending' && !item?.is_submit_clicked ? 'Not submitted' : 'Submitted'}</TableCell>

                                    <TableCell>{item?.referralCode}</TableCell>
                                    <TableCell>{item?.referBy && item.referBy.refer_by ? item.referBy.refer_by.referralCode : 'N/A'}</TableCell>
                                    <TableCell>{item?.promoCode || 'N/A'}</TableCell>
                                    <TableCell>{item?.phoneNumber || 'N/A'}</TableCell>

                                    <TableCell>{item?.address ? displayShortAddress(item?.address) : 'N/A'}</TableCell>



                                    <TableCell>
                                        {(item?.services && item?.services.length > 0)
                                            ? item.services
                                                .filter(sp => sp?.category?.name !== 'Consultation') // Filter out 'Consultation'
                                                .map(sp => sp?.category?.name)
                                                .join(', ')
                                            : 'N/A'}
                                    </TableCell>

                                    <TableCell style={{ textTransform: "capitalize" }}>{(item?.medicineApproach && item?.medicineApproach.length > 0) ? item?.medicineApproach.map(ma => ma).join(', ') : 'N/A'}</TableCell>
                                    <TableCell>{item?.referralSource || 'N/A'}</TableCell>
                                    <TableCell>{item?.subscription?.associatedWithSubscriptionPlan?.name ? `${item?.subscription?.associatedWithSubscriptionPlan?.name}` : 'N/A'}</TableCell>
                                    <TableCell>{item?.subscription?.associatedWithSubscriptionPlan?.subscriptionTenure || 'N/A'}</TableCell>
                                    <TableCell>{item?.subscription?.subscriptionStatus || 'N/A'}</TableCell>
                                    <TableCell className="text-center">
                                        {(item?.subscription && item?.subscription.subscribed)
                                            ? 'Yes'
                                            : 'No'
                                        }

                                        {((!item?.subscription || (item?.subscription && !item?.subscription.subscribed)) && item?.is_active && item?.emailVerification === 'verified' && item?.is_business_approved) &&
                                            <Button
                                                size="sm"
                                                style={{ padding: '3px 8px', fontSize: '12px' }}
                                                disabled={(item?.emailVerification === 'pending' || remindLoader['subscribed'] === item?._id)}
                                                onClick={() => handleReminderEmail(item?._id, 'subscribed')}
                                            >
                                                {remindLoader['subscribed'] === item?._id ? 'Wait...' : 'Remind'}
                                            </Button>
                                        }
                                    </TableCell>
                                    <TableCell>{item?.subscription?.renewedAt ? momentFormat.dateTimeWithTz(item?.subscription?.renewedAt) : 'N/A'}</TableCell>
                                    <TableCell>{item?.subscription?.expiryDate ? momentFormat.dateTimeWithTz(item?.subscription?.expiryDate) : 'N/A'}</TableCell>
                                    <TableCell
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        {item?.emailVerification
                                            ? <span
                                                className={`status--${item?.emailVerification}`}
                                                style={{ padding: '4px 8px', borderRadius: '3px' }}
                                            >
                                                {!item?.is_submit_clicked ? 'waiting on account creation' : item?.emailVerification}
                                            </span>
                                            : "NA"
                                        }

                                        {item?.emailVerification === 'pending' &&
                                            <Button
                                                size="sm"
                                                className="m-1 capitalize"
                                                onClick={() => handleVerifyEmail(item?._id)}
                                                disabled={(emailVerifyLoader.toString() === item?._id.toString()) || !item?.is_active || !item?.is_submit_clicked}
                                                style={{ padding: '3px 8px', fontSize: '12px' }}
                                            >
                                                {(emailVerifyLoader.toString() === item?._id.toString()) ? 'Sending...' : !item?.is_submit_clicked ? 'waiting on account creation' : 'Verify'}
                                            </Button>

                                        }
                                    </TableCell>

                                    <TableCell>
                                        <Switch
                                            value={item?.is_active}
                                            onChange={() => switch_onChange_handle(item)}
                                            backgroundColor={{
                                                on: 'blue', off: "grey"
                                            }}
                                            borderColor={{
                                                on: 'blue', off: "grey"
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Switch
                                            value={item?.isOutOfCountry}
                                            onChange={() => handeIsOutOfCountry(item)}
                                            backgroundColor={{
                                                on: 'blue', off: "grey"
                                            }}
                                            borderColor={{
                                                on: 'blue', off: "grey"
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ minWidth: '200px' }} className="text-center">
                                        {item?.emailVerification === 'pending' ? (
                                            <Button
                                                size="sm"
                                                onClick={() => handleResendVerification(item?._id)}
                                                disabled={(resendLoader.toString() === item?._id.toString()) || !item?.is_active}
                                                style={{ padding: '3px 8px', fontSize: '12px' }}
                                            >
                                                {(resendLoader.toString() === item?._id.toString()) ? 'Sending...' : 'Resend Verification Email'}
                                            </Button>
                                        ) : (
                                            <Button
                                                size="sm"
                                                disabled={(loading.approve === item?._id) || item?.is_business_approved || (item?.emailVerification === 'pending') || !item?.is_active}

                                                onClick={() => handleApporve(item?._id)}
                                                style={{ padding: '3px 8px', fontSize: '12px' }}
                                            >
                                                {loading.approve === item?._id ? 'Please Wait...' : item?.is_business_approved ? 'Approved' : 'Approve'}
                                            </Button>
                                        )}
                                    </TableCell>
                                    <TableCell aria-description="isProfileCompleted" className="text-center">
                                        {item.is_profile_completed ? 'Yes' : (!item?.is_business_approved || !item?.subscription || (item?.subscription && !item?.subscription.subscribed)) ? waiting : 'No'}{' '}

                                        {(!item?.is_profile_completed && item?.is_business_approved && item?.is_active && item?.subscription && item?.subscription.subscribed) && (
                                            <Button
                                                size="sm"
                                                style={{ padding: '3px 8px', fontSize: '12px' }}
                                                onClick={() => handleReminderEmail(item?._id, 'isProfileCompleted')}
                                            >
                                                {remindLoader['isProfileCompleted'] === item?._id ? 'Wait...' : 'Remind'}
                                            </Button>
                                        )}
                                    </TableCell>
                                    <TableCell aria-description="isServiceCompleted">
                                        {(item?.services && item?.services.length > 1) ? 'Yes' : !item?.is_profile_completed ? waiting : 'No'}{' '}
                                        {(item?.services && item?.services.length < 2 && item?.is_profile_completed && item?.is_active) && (
                                            <Button size="sm" style={{ padding: '3px 8px', fontSize: '12px' }} onClick={() => handleReminderEmail(item?._id, 'isServiceCompleted')}>
                                                {remindLoader['isServiceCompleted'] === item?._id ? 'Wait...' : 'Remind'}
                                            </Button>
                                        )}
                                    </TableCell>
                                    <TableCell aria-description="isScheduleCompleted">
                                        {item?.totalExistSchedules > 0 ? 'Yes' : (!item?.services || (item?.services && item?.services.length < 2)) ? waiting : 'No'}{' '}
                                        {(!item?.totalExistSchedules && item?.services && item?.services.length > 1 && item?.is_active) && (
                                            <Button size="sm" style={{ padding: '3px 8px', fontSize: '12px' }} onClick={() => handleReminderEmail(item?._id, 'isScheduleCompleted')}>
                                                {remindLoader['isServiceCompleted'] === item?._id ? 'Wait...' : 'Remind'}
                                            </Button>
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        <Switch
                                            value={item?.isTestAccount}
                                            onChange={() => handleUpdateTestAccount(item?._id, item?.isTestAccount)}
                                            backgroundColor={
                                                { on: 'blue', off: "grey" }
                                            }
                                            borderColor={
                                                { on: 'blue', off: "grey" }
                                            }
                                        />
                                    </TableCell>
                                    <TableCell style={{ minWidth: 230 }}>{momentFormat.dateTimeWithTz(item?.createdAt)}</TableCell>
                                    <TableCell style={{ minWidth: 230 }}>{item?.last_login ? momentFormat.dateTimeWithTz(item?.last_login) : 'N/A'}</TableCell>

                                    <TableCell>
                                        <Dropdown>
                                            <Dropdown.Toggle />
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => navigate(`/seller/service/${item?._id}`, { state: { item } })}>
                                                    View Service
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => navigate(`/seller/staff/${item?._id}`, { state: { item } })}>
                                                    View Staff
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => navigate(`/seller/transactions/${item?._id}`)}>
                                                    View Transactions
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </TableCell>
                                </TableRow>
                            }

                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {loading.loadMore ? <h4> Loading... </h4> : null}
            </>
        );
    }

    return (
        <section className="popular--Wrapper edit--page services-page-all">
            <Container fluid>
                <Row>
                    <Col sm={12} className="px-0">
                        <div className="sidebar--wrapper">
                            <div className="admin--content">
                                <Container>
                                    <Row>
                                        <div className="all-servicess-Wrap d-flex  justify-content-between align-items-center">

                                            <h2>{filter.is_deleted ? 'Trash' : null} Seller</h2>
                                            <div className="filter--type d-flex align-items-center">
                                                <Form.Check
                                                    checked={!filter.isTestAccount}
                                                    label="Exclude Test Account"
                                                    name="isTestAccount"
                                                    onChange={(e) => handleChange(e)}
                                                    style={{ marginRight: '10px' }}
                                                />
                                                <Button
                                                    className="ms-auto"
                                                    variant="primary"
                                                    onClick={() => handleViewActiveTrash()}
                                                >
                                                    View {filter.is_deleted ? 'Active' : 'Trash'}
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="service--filter">
                                            <div className="filter--type">
                                                <Form.Label>Email Status</Form.Label>
                                                <Form.Select
                                                    aria-label="service type"
                                                    name="emailVerification"
                                                    className="filter--service--type"
                                                    onChange={(e) => handleChange(e)}
                                                    value={filter.emailVerification}

                                                >
                                                    <option value="">All</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="verified">Verified</option>
                                                </Form.Select>
                                            </div>
                                            <div className="filter--type ms-3 me-3">
                                                <Form.Label>Subscription Status</Form.Label>
                                                <Form.Select
                                                    aria-label="service type"
                                                    name="subscriptionStatus"
                                                    className="filter--service--type"
                                                    onChange={(e) => handleChange(e)}
                                                    value={filter.subscriptionStatus}

                                                >
                                                    <option value="">All</option>
                                                    <option value="Paid">Paid</option>
                                                    <option value="Unpaid">Unpaid</option>
                                                </Form.Select>
                                            </div>
                                            <div className="filter--type">
                                                <Form.Label>Account Status</Form.Label>
                                                <Form.Select
                                                    aria-label="service type"
                                                    name="is_business_approved"
                                                    className="filter--service--type"
                                                    onChange={(e) => handleChange(e)}
                                                    value={filter.is_business_approved}

                                                >
                                                    <option value=''>All</option>
                                                    <option value="true">Approve</option>
                                                    <option value="false">Pending</option>
                                                </Form.Select>
                                            </div>
                                            <div className="filter--search">
                                                <Form.Label>Search</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search By Name, Email, Phone Number"
                                                    name="search"
                                                    value={filter.search || ''}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                            {!is_deleted &&
                                                <div className="filter--service--by">
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select
                                                        aria-label="service by"
                                                        name="is_active"
                                                        className="filter--service--isActive"
                                                        value={filter.is_active}
                                                        onChange={(e) => handleChange(e)}
                                                    >
                                                        <option value="">All</option>
                                                        <option value="true">Active</option>
                                                        <option value="false">In-active</option>
                                                    </Form.Select>
                                                </div>}
                                            <Button
                                                variant="primary"
                                                onClick={() => handleShowDateFilter()}
                                            >
                                                {showDateFilter ? 'Hide Date Filter' : 'Show Date Filter'}
                                            </Button>
                                            {showDateFilter &&
                                                <div className="range__picker">
                                                    <DateRangePicker
                                                        ranges={dateRange}
                                                        onChange={handleDateRangeChange}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        {loading['sellers']
                                            ? <div className="loader-main">
                                                <RotatingLines
                                                    strokeColor="grey"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="80"
                                                    visible={true}
                                                />
                                            </div>
                                            : <Accordion defaultActiveKey="0" className="services-main-wraper">
                                                {userSellerData && userSellerData.length > 0
                                                    ? renderTable()
                                                    : <h4>No Sellers Registered</h4>
                                                }
                                            </Accordion>
                                        }
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* {showSubscriptionModal &&
            <Modal
                show={showSubscriptionModal}
                onHide={() => handleCloseModal()}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <h4>Update Subscription</h4>
                </Modal.Header>
                <Modal.Body>
                <Row>
                            <Form.Group>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Amount"
                                    name="subscriptionAmount"
                                    value={ subscriptionData ? subscriptionData['subscriptionAmount'] : ''}
                                    onChange={({ target }) => handleChangeInput(target)}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Select 
                                    name="subscriptionStatus"
                                    value={ subscriptionData ? subscriptionData['subscriptionStatus'] : ''}
                                    onChange={({ target }) => handleChangeInput(target)}
                                    aria-label="Select Status"
                                >
                                    <option>Select Status</option>
                                    <option value="Free">Free</option>
                                    <option value="Paid">Paid</option>
                                    <option value="Unpaid">Unpaid</option>
                                </Form.Select>
                            </Form.Group>
                            {errorM && <Alert variant={errorM.variant}>{errorM.message}</Alert>}
                            <div className="text-center add-service-btn">
                                <Button 
                                    variant="primary" 
                                    type="submit" 
                                    disabled={loading['subscription'] || sbsBtn}
                                    onClick={(e) => onSuscriptionUpdate(e)}
                                >
                                    {loading['subscription'] ? 'Please Wait...' : 'Submit'}
                                </Button>
                            </div>
                        </Row>
                </Modal.Body>
            </Modal>} */}
        </section>
    );
}

export default SellerList;