import {
    POST_CATEGORY_RESTRICTION_SUCCESS,
    POST_CATEGORY_RESTRICTION_FAILED,
    PUT_CATEGORY_RESTRICTION_SUCCESS,
    PUT_CATEGORY_RESTRICTION_FAILED,
    DELETE_CATEGORY_RESTRICTION_SUCCESS,
    DELETE_CATEGORY_RESTRICTION_FAILED,
    GET_ALL_CATEGORY_RESTRICTION_SUCCESS,
    GET_ALL_CATEGORY_RESTRICTION_FAILED
} from "redux/actions/types";
    
    const initialState = {
        all: null,
        post: null,
        put: null,
        delete: null,
        error: null,
    };
    
    export default (state = initialState, action) => {
        switch (action.type) {
            case GET_ALL_CATEGORY_RESTRICTION_SUCCESS:
                return {
                    all: action.payload,
                    error: null
                }
            case GET_ALL_CATEGORY_RESTRICTION_FAILED:
                return {
                    error: action.payload
                }
            case POST_CATEGORY_RESTRICTION_SUCCESS:
                return {
                    post: action.payload,
                    error: null
                }
            case POST_CATEGORY_RESTRICTION_FAILED:
                return {
                    error: action.payload
                }
            case PUT_CATEGORY_RESTRICTION_SUCCESS:
                return {
                    put: action.payload,
                    error: null
                }
            case PUT_CATEGORY_RESTRICTION_FAILED:
                return {
                    error: action.payload
                }
            case DELETE_CATEGORY_RESTRICTION_SUCCESS:
                return {
                    delete: action.payload,
                    error: null
                }
            case DELETE_CATEGORY_RESTRICTION_FAILED:
                return {
                    error: action.payload
                }
            default: return state;
        }
    };
    
    
    
    