import { get } from 'lodash';
import API from '../../helpers/api';

/**   import Seller types */
import {
    GET_SERVICES_BB_SUCCESS,
    GET_SERVICES_BB_FAILED,
    DELETE_SERVICES_BB_SUCCESS,
    DELETE_SERVICES_BB_FAILED,
    ADD_SERVICES_BB_SUCCESS,
    ADD_SERVICES_BB_FAILED,
    UPDATE_SERVICES_BB_SUCCESS,
    UPDATE_SERVICES_BB_FAILED,
    GET_ONE_BBSERVICE_SUCCESS,
    GET_ONE_BBSERVICE_FAILED,
} from './types' ;


function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: {message: data.error.message},
        });
    } else {
        dispatch({
            type: type,
            payload: {message: data.message},
        });
    }
}

/**
 * 
 * @param {*} using for services 
 * @returns 
*/

  

export const getAllBBServices = (query) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('bbServices', query);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_SERVICES_BB_SUCCESS, payload: response.data });
        } else {
            await dispatch({ type: GET_SERVICES_BB_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_SERVICES_BB_FAILED);
      }
    }
}

export const getOneBBService = (id) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('bbServices', `/${id}`);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_ONE_BBSERVICE_SUCCESS, payload: response.data });
        } else {
            await dispatch({ type: GET_ONE_BBSERVICE_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_ONE_BBSERVICE_FAILED);
      }
    }
}

export const deleteServiceBB = (id) => {
    return async (dispatch) => {
      try {
        const response = await API.apiDelete('bbServices', `/delete/${id}`);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: DELETE_SERVICES_BB_SUCCESS, payload: response.data });
        } else {
            await dispatch({ type: DELETE_SERVICES_BB_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, DELETE_SERVICES_BB_FAILED);
      }
    }
}

export const addBBService = (payload) => {
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('bbServices', `/add`, payload);
            if (response.data && response.data.success) {
                await dispatch({ type: ADD_SERVICES_BB_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: ADD_SERVICES_BB_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, ADD_SERVICES_BB_FAILED);
        }
    }
}

export const updateBBService = (id,payload) => {
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('bbServices',`/edit/${id}`, payload);
            /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: UPDATE_SERVICES_BB_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: UPDATE_SERVICES_BB_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, UPDATE_SERVICES_BB_FAILED);
        }
    }
}