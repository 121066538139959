import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Accordion, Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import toast from 'react-hot-toast';
import { FaRegDotCircle, FaRegCircle  } from "react-icons/fa";
import { MdDelete, MdOutlineRestore  } from 'react-icons/md';
import { BiEdit } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import swal from "sweetalert";
import LoadingView from "components/LoadingView";
import { serviceTypeName, momentFormat } from "utils/validations";
import { checkScrolledToBottom } from "utils/scroll";
import { getAllSubcriptions , deleteOneSubcription , updateSubcriptionStatus} from "redux/actions/subcription.action";





const limitPerPage = process.env.REACT_APP_LISTING_LIMIT

function SubcriptionList() {
    /********************************     state Mangement  ***************************************/
    const [loading, setLoading] = useState({ delete: '', initial: true, loadMore:false, update:false  });
    const [subcriptions, setSubcriptions] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    let [page, setPage] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState('all');
    /**********************************************************************************************/
    /*************************************   Reducer State Mangement       *************************/
    const subcriptionState = useSelector(state => state.subcription);
    /*********************************************************************************************/
    /****************************************   Redux function Mangement ******************************/
    const dispatch = useDispatch();
    const navigate = useNavigate() ;
    /********************************************************************************************/

    /*******************************  component life_cycle handling functions  ****************************/

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    },[]);

    useEffect(() => {
        getSubcriptions();
    }, [])
    /********  update page  *********/
    useEffect(() => {
        if(page < totalPages){
            getSubcriptions();
        }else{
            setLoading(prevState => ({
                ...prevState,
                loadMore: false
            }));
        }
    }, [page , selectedFilter])

    /********* Get More Subcriptions  **********************/
    useEffect(() => {
        if(!loading.loadMore) return;

        if(page < totalPages){
            setTimeout(() => {
                setPage(page + 1)
            }, 1200);
        }else{
            setLoading(prevState => ({
                ...prevState,
                loadMore: false
            }));
        } 
    }, [loading.loadMore]);

    useEffect(()=>{
        if(!subcriptionState.error){
            const {getAllSubcriptions , deleteSubcription , updateStatus,error} = subcriptionState ; 
            if(getAllSubcriptions && getAllSubcriptions.success){
                if((subcriptions.length < getAllSubcriptions.totalCount)){
                    var subcription = subcriptions ;
                    Array.prototype.push.apply(subcription, getAllSubcriptions.subscriptionPlan);
                    subcription = subcription.filter((v, i, a) => a.findIndex(t => (t._id === v._id)) === i)
                    setSubcriptions(subcription)
                }else{
                    setSubcriptions(getAllSubcriptions.subscriptionPlan)
                }

                setTotalPages(Math.ceil(getAllSubcriptions.totalCount / limitPerPage));
                setLoading({ ...loading, initial: false, loadMore: false });

                if (page > 0) {
                    window.scrollTo(0, document.documentElement.offsetHeight - 20);
                }
            }
            if(deleteSubcription && deleteSubcription.success){
                toast.success(deleteSubcription.message);
                setLoading({...loading , delete:''})
                setPage(0)
                getSubcriptions()
            }
            if(updateStatus && updateStatus.success){
                toast.success(updateStatus.message);
                setLoading({...loading , delete:''})
                setPage(0)
                getSubcriptions()
            }
        }
        if( subcriptionState.error ){
            toast.error(subcriptionState.error.message) 
            setLoading({ delete: '', initial: false, loadMore:false, update:false  })
        }
    },[subcriptionState])
    /********************************************************************************************************/
    /***********************************  infinite scroll handling functions *********************************/
    function handleScroll() {
        const bottomOfWindow =  checkScrolledToBottom() ; 
        if (bottomOfWindow) {
            setLoading(prevState => ({
                ...prevState,
                loadMore: true
            }));
        }
    }
    /********************************************  Api call function  ***************************************/
    const getSubcriptions = () =>{
        dispatch(getAllSubcriptions({page, limit:limitPerPage}))
    }
    /*************************************************   subscriptions handling functions *******************************************************/
    /** ---    using for delete subscription ----- */
    const handleDeleteSubcription = (id) => {
        swal({
            title: "Are you sure ?",
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: true,
        })
        .then( async(willDelete) => {
            if (willDelete) {
                setLoading({ 
                    ...loading, 
                    delete: id
                });
                dispatch(deleteOneSubcription(id));
            } 
        });
    }
    /**** --   using for change the subscription status  -- */
    const handleUpdateStatus = (item) => {
        const body = {status : item.status === 'inactive' ? true : false }
        dispatch(updateSubcriptionStatus(item._id , body))
    }

    /**----------  using for filter the subcription (month / year ) -------------*/

    const handleSelectBox = (value) => {
        setSelectedFilter(value);
        setPage(0); // Reset page when filter changes
    }
    /************  using for show the subcription data into table  *********************************************/
    const renderTable = () => {
        let filteredSubscriptions = subcriptions;

        if (selectedFilter === 'month') {
            filteredSubscriptions = subcriptions.filter(item => item.subscriptionTenure === 'month');
        } else if (selectedFilter === 'year') {
            filteredSubscriptions = subcriptions.filter(item => item.subscriptionTenure === 'year');
        }
        if(filteredSubscriptions && filteredSubscriptions.length > 0){
            return (
                <>
                    <Table striped responsive="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Subcription Name</th>
                                <th>Subcription Tenure</th>
                                <th>Subcription Amount</th>
                                <th>Subcription Plan Id</th>
                                <th>Discount</th>
                                <th>Status</th>
                                <th>Created At</th>
                                <th className="edit-service">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredSubscriptions.map((item , ind)=>{
                                return(
                                    <tr key={ind}>
                                        <td>{ind+1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.subscriptionTenure}</td>
                                        <td>{item.amount}</td>
                                        <td>{item.stripeSubscriptionPlanId || 'N/A'}</td>
                                        <td>{item.discount}</td>
                                        <th>{item.status}</th>
                                        <td>{momentFormat.fullDate(item.createdAt)}</td>
                                        <td className="edit-service">
                                            <Dropdown>
                                                <Dropdown.Toggle 
                                                    variant="success" 
                                                    id="dropdown-basic">
                                                    <AiOutlineEye /> 
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item 
                                                        onClick={()=> handleDeleteSubcription(item._id)}
                                                        disabled={loading.delete === item._id}
                                                    > 
                                                        <MdDelete /> {' '}{loading.delete === item._id ? 'Deleting...' : 'Delete'}
                                                        
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => navigate(`/subcription/update/${item._id}`)}> 
                                                        <BiEdit /> Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={()=> handleUpdateStatus(item)}
                                                    >
                                                        {item.status === 'active' 
                                                            ?   <> <FaRegCircle size={12}/> {' '} Deactivate </> 
                                                            :   <> <FaRegDotCircle size={12}/> {' '} Activate </>
                                                        }
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    {loading.loadMore ? <h4> Loading... </h4> : null}
                </>
            )
        }else{
            <h4>Subcription Not Available</h4>
        }

    }
    return (
        <section className="popular--Wrapper edit--page services-page-all">
            <Container fluid>
                <Row>
                    <Col sm={12} className="px-0">
                        <div className="sidebar--wrapper">
                            <div className="admin--content">
                                <Container>
                                    <Row>
                                        <div className="all-servicess-Wrap">
                                            <h2>Subcriptions</h2>
                                            <Link to="/subcription/create" className="ms-auto">
                                                <Button variant="primary">Add Subcriptions </Button>
                                            </Link>
                                        </div>
                                        <div className="service--filter mb-4">
                                            <div className="filter--type">
                                                <Form.Label>Select Subcription Tenure </Form.Label>
                                                <Form.Select 
                                                    aria-label="service type"
                                                    name="orderStatus"
                                                    className="filter--service--type"
                                                    onChange={(e) => handleSelectBox(e.target.value)}
                                                    value={selectedFilter}

                                                >
                                                    <option value="all">All</option>
                                                    <option value="month">Month</option>
                                                    <option value="year">Year</option>
                                                </Form.Select>
                                            </div>
                                                
                                        </div>
                                        {loading.initial 
                                            ?   <LoadingView />
                                            :   <Accordion 
                                                    defaultActiveKey="0" 
                                                    className="services-main-wraper"
                                                >
                                                    {renderTable()}
                                                </Accordion>
                                        }
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Col>
                </Row>
            
            </Container>
        </section>
    )
}

export default SubcriptionList