import {
    GET_BUYER_USER_SUCCESS,
    GET_BUYER_USER_FAILED,
} from "redux/actions/types";

const initialState = {
    error: null,
    getAllBuyerUser: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_BUYER_USER_SUCCESS:
            return {
                getAllBuyerUser: action.payload,
                error: null
            }
        case GET_BUYER_USER_FAILED:
            return {
                error: action.payload,
            }
        default: return state;
    }
};
