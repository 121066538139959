// State Mapping Object
const stateMapping = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'FL': 'Florida',
    'GA': 'Georgia',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PA': 'Pennsylvania',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming',
};

const stateAbbreviations = Object.keys(stateMapping);
const stateNames = Object.values(stateMapping);

const normalizeState = (state) => {
    state = state.trim().toUpperCase();

    // Check if the state is already an abbreviation
    if (stateAbbreviations.includes(state)) {
        return state;
    }

    // Check if the state is a full name and convert to abbreviation
    const index = stateNames.findIndex(name => name.toUpperCase() === state);
    if (index !== -1) {
        return stateAbbreviations[index];
    }

    // If neither, try to match common variations (like "Tx", "texas", etc.)
    const abbreviation = stateAbbreviations.find(abbr => state.startsWith(abbr));
    return abbreviation || state;
};

module.exports = { normalizeState };