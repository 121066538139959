import React, { useEffect, useRef, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { notValid } from "utils/validations";
import { addBBService, getOneBBService, updateBBService } from "../../redux/actions/serviceBB.action";
import toast from 'react-hot-toast';
import { uploadFilesToCloud } from "redux/actions/cloudinary.action";
const defaultImg = require('../../assets/images/placeholder.png')
const defaultServiceFields = { name: '', description: '', service_type:'class', profile:null }

function CreateOrUpdateService() {
    /** ----- Used For Update ----- */
    const { id } = useParams();
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [service, setService] = useState({...defaultServiceFields});

    const [ uploadProgress, setUploadProgress] = useState(0);
    const [ fileLoading , setFileLoading ] = useState(false);

    const [error, setError] = useState({ name: '', description: '', service_type: '' });

    // const [loading, setLoading] = useState(false);

    // const [ isDisable, setIsDisable ] = useState(true);
    /** ---- Used to Updates if component is in edit mode or add mode --- */
    const [ isEditable, setIsEditable ] = useState(id ?  true : false);
    const [ loading, setLoading ] = useState({ init: id ? true: false, createUpdate: false});

    /** --- Reducer state -- */
    const serviceState = useSelector(state => state.serviceBB);


    useEffect(() => {
        if(id){ 
            setIsEditable(true);
            dispatch(getOneBBService(id));
        }else{
            setIsEditable(false);
        }
    }, [id])


    const handleChangeInput = (target) => {
        const { value, name } = target
        setError({ ...error, [name]: ''});
        // checkForErrors(name, value);
        setService({ ...service, [name]: value });
    }


    const isValid = () => {
        let isValid = true
        let err = { ...error }

        if (notValid(service.name)) {
            err.name = "Please provide service name"
            isValid = false
        }

        if(notValid(service.service_type)){
            err.service_type = "Please select Specialty type"
            isValid = false 
        }

        if (notValid(service.description)) {
            err.description = "Please provide description"
            isValid = false
        }

        // if(notValid(service.profile)){
        //     err.profile = "Please upload cover picture"
        //     isValid = false
        // }

        setError({ ...err });
        return isValid
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (isValid()) {
            setLoading({ createUpdate: true});
            if(isEditable){
                dispatch( updateBBService(id, { name: service.name, description:service.description, service_type:service.service_type, profile: service.profile }) );
            }else{
                dispatch(addBBService(service))
            }
        }
    }

    useEffect(() => {
        if( serviceState.error ){
            toast.error(serviceState.error.message) 
            setLoading({ createUpdate: false, init: false })
        }
        
        if(!serviceState.error){
            const { addServiceBB, getOne, update } = serviceState;

            if(addServiceBB && addServiceBB.success){
                toast.success(addServiceBB.message);
                setLoading({ createUpdate: false })
                setService({...defaultServiceFields});
                // setTimeout(()=>{
                //     navigate('/product/list')
                // },1000)
                //  navigate('/product/list')
            }

            if(getOne && getOne.success ){
                setService(getOne.bbService);
                setLoading({ init: false });
            }

            if(update && update.success){
                // toast.success(update.message);
                setLoading({ createUpdate: false });
                navigate('/product/list')
            }

        }
    }, [serviceState])

    // const handleUploadFile = (e) => {
    //     setError({ ...error, ['profile']: ''});
    //     const _files = e.target.files
    //     const filePromises = [];

    //     for (let i = 0; i < _files.length; i++) {
    //         const file = _files[i];
    //         const reader = new FileReader();
    //         const promise = new Promise((resolve, reject) => {
    //             if( file.size > (1024 * 1024) * 10){
    //                 reject(file.name + ' size should be less than 10mb' );
    //             }
    //             reader.onload = (e) => {
    //                 const newFile = {
    //                     name: file.name,
    //                     fileType: file.type,
    //                     url: e.target.result,
    //                 }
              
    //                 resolve(newFile);
    //             };
        
    //             reader.onerror = (error) => {
    //                 reject(error);
    //             };

    //         });

    //         filePromises.push(promise);
    //         reader.readAsDataURL(file);
    //     }
  
    //     Promise.all(filePromises)
    //     .then((file) => {
    //         setService({ ...service, profile: file[0] });
    //     })
    //     .catch((err) => {
    //         setError({ ...error, ['profile']:  'Please try uploading another Cover Photo'});
    //         console.error(err);
    //     });
    // }

    const handleUploadFile = async (event, field) => {
        setError({ ...error, [field]: '' });
    
        const files = event.target.files;
        const formData = new FormData();
        let hasError = false;
    
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if( !['image/jpeg' , 'image/png' , 'image/jpg'].includes(file.type)){
              setError({ ...error, [field]: file.name + ' file type is not supported' });
              hasError = true; // Set hasError to true if there's an error
              break; // Exit the loop early if there's an error
            }
  
  
            if (file.size > (1024 * 1024) * 10) {
                setError({ ...error, [field]: file.name + ' size should be less than 10mb' });
                hasError = true; // Set hasError to true if there's an error
                break; // Exit the loop early if there's an error
            }
            
            formData.append('files', file);
    
        }
    
        if (!hasError && formData.has('files') ) { // Proceed with upload only if there are no errors
            const response = await uploadFilesToCloud(formData, setUploadProgress, setFileLoading);
            if (response && response.data && response.data.success) {
                // setCategory({ ...category, });
                setService({ ...service, profile: response.data.files[0]});
            }
        }
    };

    const handleClickImage = () => {
        fileInputRef.current.click();
    };

    return (
        <div className='main-add-service'>
            <h2>{isEditable ? 'Update': 'Add'} Product</h2>
           
            <Button onClick={() => navigate('/product/list')}>View Products</Button>
            <div className="form-add-service">
                <Form onSubmit={onSubmit}>
                    <Form.Label>Cover Photo</Form.Label>
                    <Form.Group className="mb-3">
                        <img 
                            src={service.profile ? service.profile.url : defaultImg} 
                            height={125} 
                            width={125} 
                            style={{ borderRadius: '50%', cursor:'pointer'}}
                            onClick={() => handleClickImage()}
                        />
                        <Form.Control
                            type="file"
                            name="profile"
                            ref={fileInputRef}
                            style={{ display: 'none'}}
                            hidden
                            onChange={(e) => handleUploadFile(e)}
                            disabled={fileLoading}
                        />
                         {uploadProgress > 0 && <p >{uploadProgress === 100 ? 'Uploaded' : 'Uploading...'} {uploadProgress}%</p>}
                        {error.profile && <span className="error">{error.profile}</span>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter product name"
                            name="name"
                            value={service.name || ''}
                            onChange={({ target }) => handleChangeInput(target)}
                        />
                        {error.name && <span className="error">{error.name}</span>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Product Type</Form.Label>
                        <Form.Select 
                            aria-label="Default select example"
                            name="service_type"
                            value={service.service_type || ''}
                            onChange={({target}) => handleChangeInput(target)}
                        >
                            <option>Select Product Type</option>
                            <option value="class">Class</option>
                            <option value="service">Service</option>
                        </Form.Select>
                        {error.service_type && <span className="error">{error.service_type}</span>}
                    </Form.Group>
                    <Form.Group className="form-group col-sm-12">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea"
                            rows={10} 
                            placeholder="Enter Description"
                            name="description"
                            value={service.description || ''}
                            onChange={({ target }) => handleChangeInput(target)}
                        />
                         {error.description && <span className="error">{error.description}</span>}
                    </Form.Group>

                    <div className="text-center add-service-btn">
                        <Button 
                            variant="primary" 
                            type="submit" 
                            disabled={loading.createUpdate}
                        >
                            {loading.createUpdate 
                                ? 'Please Wait...' 
                                : isEditable 
                                    ? 'Update' 
                                    : 'Add'
                            }
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default CreateOrUpdateService