export const checkScrolledToBottom = () => {
    const { scrollTop, offsetHeight, scrollHeight } = document.documentElement;

    const heightDifference = Math.abs(scrollHeight - Math.round(scrollTop) - offsetHeight)

    if (heightDifference <= 10) {
       return true;
    }

    return false;
}