import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Accordion, Card,Dropdown } from "react-bootstrap";
// import { BsChevronDown } from 'react-icons/bs';
import { getSellerService } from "../../redux/actions/SellerUser.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
// import { MdVerified,MdDelete } from 'react-icons/md';
// import toast from "react-hot-toast";
import API from '../../helpers/api';
// import { Link } from "react-router-dom";
import { RotatingLines } from 'react-loader-spinner'
// import { BiEdit } from "react-icons/bi";
// import { AiOutlineEye, AiOutlineSend } from "react-icons/ai";
import 'bootstrap/dist/css/bootstrap.min.css';
import { formatPrice } from "utils/validations";
// import { serviceTypeName } from "utils/validations";
import { momentFormat } from "utils/validations";
import toast from "react-hot-toast";

function SellerTransaction() {
    const { id } = useParams();
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(id){
            getUserTransactions();
        }else{
            navigate('/seller/list');
        }
    }, [])

    const getUserTransactions = async () => {
        try {
            setLoading(true)
            const response = await API.apiGet('transaction', `/${id}`);
            setLoading(false)
            // console.log(response )
            if (response.data && response.data.success) {
                setUser(response.data.user);
                setTransactions(response.data.transactions)
            }
        } catch (error) {
            setLoading(false)
            if (error.response && error.response.data) {
                toast.error(error.response.data.message)
            } else {
                toast.error(error.message)
            }
        }
    }

    const renderCoupon = (discount, coupon_code) => {
        if(discount && typeof discount === 'object' && discount.coupon){
            return discount.coupon.name
        }

        if(coupon_code){
            return coupon_code
        }
        return 'N/A'
    }

    const renderDiscount = (discount) => {
        if(discount && typeof discount === 'object' && discount.coupon){
            return discount.coupon.percent_off ? `${discount.coupon.percent_off}%` : `$${discount.coupon.amount_off}`
        }

        if(discount && typeof discount === 'object' && discount.type){
            return discount.type === 'percentage' ? `${discount.value}%` : `$${discount.value}`
        }

        return 'N/A'
    }

    return (
        <section className="popular--Wrapper edit--page services-page-all">
            <Container fluid>
                <Row>
                    <Col sm={12} className="px-0">
                        <div className="sidebar--wrapper">
                            <div className="admin--content">
                                <Container>
                                    <Row>
                                        <div className="all-servicess-Wrap d-flex justify-content-between">
                                            <h2>{user ? `"${user.name}"` : ''} Transactions</h2>
                                            <Button onClick={() => navigate('/seller/list')}>Back</Button>
                                        </div>
                                        {loading 
                                        ?   <div className="loader-main">
                                                <RotatingLines
                                                    strokeColor="grey"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="80"
                                                    visible={true}
                                                />
                                            </div>
                                           
                                        :
                                            <Accordion defaultActiveKey="0" className="services-main-wraper">
                                            {(transactions && transactions.length > 0) 
                                                ?
                                                <Table striped responsive="sm">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>BirthBridge Transaction ID</th>
                                                            <th>Stripe Transaction ID</th>
                                                            <th scope="col">Transaction Date</th>
                                                            <th scope="col">Discount</th>
                                                            <th scope="col">Coupon Code</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="Col">Remarks</th>
                                                            <th scope="col">Payment Status </th>
                                                        </tr>
                                                    </thead>
                                                    { transactions.map((tx, ind) => {
                                                        return (
                                                            <tbody>
                                                                <tr>
                                                                    <td>{ind+1}</td>
                                                                    <td>{tx.payment_tnx_id || 'N/A'}</td>
                                                                    <td>{tx.tnx_id || 'N/A'}</td>
                                                                    <td>{momentFormat.dateTimeFormat(tx.createdAt)}</td>
                                                                    <td>{renderDiscount(tx.discount)}</td>
                                                                    <td>{renderCoupon(tx.discount, tx.coupon_code)}</td>
                                                                    <td>${formatPrice(tx.total)}</td>
                                                                    <td>{tx.remarks || 'N/A'}</td>
                                                                    <td>
                                                                        <span className={`status-services status--${tx.status}`}>{tx.status}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    })}
                                                </Table>
                                                :
                                                <div>
                                                    <h2>Transactions Not Available</h2>
                                                </div>
                                            }
                                            </Accordion>
                                           
                                        }
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SellerTransaction;