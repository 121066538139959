import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Accordion, Dropdown, Form } from "react-bootstrap";
import { getAllBBServices,deleteServiceBB, updateBBService } from "../../redux/actions/serviceBB.action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { FaRegDotCircle, FaRegCircle  } from "react-icons/fa";
import { MdDelete, MdOutlineRestore  } from 'react-icons/md';
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import swal from "sweetalert";
import LoadingView from "components/LoadingView";
import { serviceTypeName, momentFormat, generateLetterSVG } from "utils/validations";
import { DateRangePicker } from 'react-date-range';
import { checkScrolledToBottom } from "utils/scroll";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'bootstrap/dist/css/bootstrap.min.css';

const limitPerPage = process.env.REACT_APP_LISTING_LIMIT

function ProductList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
        
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    // Get the value of the 'q' parameter from the URL
    const is_deleted = queryParams.get('is_deleted');
    
    const [loading, setLoading] = useState({ delete: false, initial: true, loadMore:false, update:false  });
    const [ filter, setFilter] = useState({ service_type: 'all', is_active: true, search: '', is_deleted: JSON.parse(is_deleted) ? true : false , startDate:'' , endDate:''})
    const [services, setServices] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    let [page, setPage] = useState(0);
    const [showDateFilter , setShowDateFilter] = useState(false);
    const [from , setFrom] = useState(new Date());
    const [to , setTo] = useState(new Date());
    const [dateRange, setDateRange] = useState([
        {
            startDate: from,
            endDate: to,
            key: 'selection',
        },
    ]);


    /** --- Reducer State --- */
    const serviceState = useSelector(state => state.serviceBB);

    /** ---Add Event Listener --- */
    useEffect(() => {
        // window.addEventListener("scroll", handleScroll);
        // return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const listBBServices = () => {
        dispatch(getAllBBServices({page, limit:limitPerPage, ...filter}))
    }

    /** ---- List BB Services ---- */
    useEffect(() => {
        if(!JSON.parse(is_deleted)){
           listBBServices();
        }
    }, [])

    useEffect(() => {
        setFilter({ ...filter, is_deleted: JSON.parse(is_deleted) ? true : false })
    }, [is_deleted])

    useEffect(() => {
        setFilter({ ...filter, startDate: '' , endDate : '' })
    }, [showDateFilter])

    /** ----- Update Pages ----- */
    useEffect(() => {
        if(page < totalPages){
            listBBServices();
        }else{
            setLoading(prevState => ({
                ...prevState,
                loadMore: false
            }));
        }
    }, [page])

    /** ---- Get More Services ---- */
    useEffect(() => {
        if(!loading.loadMore) return;

        if(page < totalPages){
            setTimeout(() => {
                setPage(page + 1)
            }, 1200);
        }else{
            setLoading(prevState => ({
                ...prevState,
                loadMore: false
            }));
        } 
    }, [loading.loadMore]);


    // useEffect(() => {
    //     // setPage(0);
    //     // setTotalPages(0)
    //     // setServices([])
    //     // setLoading({ initial: true })
    //     if(filter.search) return ;
       
    //     // listBBServices()

    // }, [filter])

    useEffect(() => {
        setPage(0);
        setTotalPages(0)
        setServices([])
        setLoading(prevState => ({
            ...prevState,
            initial: true
        }));
        // Define a delay for debouncing (e.g., 500 milliseconds)
        const delay = 700;
    
        // Use a timer to delay the API call after typing stops
        const timerId = setTimeout(() => {
            listBBServices();
        }, delay);
    
        // Clear the timer on component unmount or when input changes
        return () => clearTimeout(timerId);
    }, [filter]);

    useEffect(() => {
        if(serviceState.error){
            toast.error(serviceState.error.message || 'Network Error');
            setLoading({ 
                delete: false,
                update: false, 
                initial:false, 
                loadMore: false  
            });
        }

        if (!serviceState.error) {
            const { getAllServiceBB, deleteServiceBB, update } = serviceState;
            
            if (getAllServiceBB && getAllServiceBB.success) {
                if((services.length < getAllServiceBB.totalCount)){
                    // setServices(prevServices => [...prevServices, ...getAllServiceBB.bbService]);
                    var service = services ;
                    Array.prototype.push.apply(service, getAllServiceBB.bbService);
                    service = service.filter((v, i, a) => a.findIndex(t => (t._id === v._id)) === i)
                    setServices(service)
                }else{
                    setServices(getAllServiceBB.bbService);
                }

                setTotalPages(Math.ceil(getAllServiceBB.totalCount / limitPerPage));
                setLoading({ ...loading, initial: false, loadMore: false });
    
                if (page > 0) {
                    window.scrollTo(0, document.documentElement.offsetHeight - 20);
                }
            }

            if( update && update.success){
                toast.success(update.message);
                let updatedServices = services.filter(service => service._id !== loading.update);
                setServices(updatedServices)
                setLoading({ ...loading, update: false})
                setPage(0)
                listBBServices()
            }
    
            if (deleteServiceBB && deleteServiceBB.success) {
                toast.success(deleteServiceBB.message);
                setLoading({ ...loading, delete: false });
            }
        }
    }, [serviceState]);

    const handleDeleteService = (id) => {
        swal({
            title: "Are you sure ?",
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: true,
        })
        .then( async(willDelete) => {
            if (willDelete) {
                setLoading({ 
                    ...loading, 
                    delete: true 
                });

                let updatedServices = services.filter(service => service._id !== id);
                setServices(updatedServices)
                
                dispatch(deleteServiceBB(id));
            } 
        });
    }

    /** ---- Handle Scroll ---- */
    function handleScroll() {
        
        const bottomOfWindow =  checkScrolledToBottom() ; 
        
        if (bottomOfWindow) {
            setLoading(prevState => ({
                ...prevState,
                loadMore: true
            }));
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPage(0);
        setTotalPages(0);
        setServices([]);
        setFilter({ ...filter, [name]: value });
    }

    const handleViewActiveTrash = () => {
        filter.is_deleted 
            ? navigate('/product/list')
            : navigate('/product/list?is_deleted=true')
        
        let q = {
            search:'', service_type: 'all'
        }
        if(filter.is_deleted ){
            q.is_active = true
            q.is_deleted = false
        }

        setFilter({ ...filter, ...q })
    }

    const handleRestoreService = (item) => {
        let body = {...item , restore:true}
        setLoading(preState=>({...preState , update:item._id}));
        dispatch(updateBBService(item._id, body))
    }

    const handleUpdateStatus = (item) => {
        let body = {...item , is_active: !item.is_active}
        setLoading(preState=>({...preState , update:item._id}));
        dispatch(updateBBService(item._id, body))
    }

    /** using sort data by start and end date */

    const handleDateRangeChange = (ranges) =>{
        const { startDate, endDate } = ranges.selection;
        setPage(0);
        setTotalPages(0);
        setServices([]);
        setDateRange([ranges.selection]);
        setFilter({...filter , startDate : startDate , endDate : endDate})
    }

    const handleShowDateFilter = () =>{
        setShowDateFilter(!showDateFilter)
        setPage(0);
        setTotalPages(0);
        setDateRange( [
            {
              startDate: new Date(),
              endDate: new Date(),
              key: 'selection',
            },
        ]);
    }

    const renderTable = () => {
        
        return (
            <>
                <Table striped responsive="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Cover</th>
                            <th>Product Name</th>
                            <th>Product Description</th>
                            <th>Product Type</th>
                            <th>Status</th>
                            <th>Registered Date</th>
                            <th className="edit-service">Action</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {services.map((item, ind) => {
                            return (
                                <tr key={ind}>
                                    <td>{ind+1}</td>
                                    <td>
                                        <img 
                                            height={40} 
                                            width={40} 
                                            style={{ borderRadius:'50%'}}
                                            src={item.profile
                                                ? item.profile.url
                                                : generateLetterSVG(item?.name, 36)
                                            }
                                            alt='profile picture' 
                                        />
                                    
                                        {/* {item.profile ? <>
                                        <img 
                                            src={item.profile.url} height={40} width={40} style={{ borderRadius:'50%'}}/></> : generateLetterSVG(item?.name, 2)
                                        } */}
                                    </td>
                                    <td>{item?.name}</td>
                                    <td>{item.description}</td>
                                    <td>{serviceTypeName(item.service_type)}</td>
                                    <td>{item.is_active ? 'Active' : "Inactive"}</td>
                                    <td>{momentFormat.fullDate(item.createdAt)}</td>
                                    <td className="edit-service"> 
                                        <Dropdown>
                                            <Dropdown.Toggle 
                                                variant="success" 
                                                id="dropdown-basic">
                                                <AiOutlineEye />
                                            </Dropdown.Toggle>
                                            {<Dropdown.Menu>
                                               { filter.is_deleted
                                                ? 
                                                    <Dropdown.Item 
                                                        onClick={() => handleRestoreService(item)}
                                                    >
                                                        <MdOutlineRestore/> Restore
                                                    </Dropdown.Item>
                                                : <>
                                                    {/* <Dropdown.Item 
                                                        onClick={()=> handleDeleteService(item._id)}
                                                        disabled={loading.delete}
                                                    >
                                                        <MdDelete /> {' '}{loading.delete ? 'Deleting...' : 'Delete'}
                                                    </Dropdown.Item> */}
                                                    <Dropdown.Item 
                                                        onClick={() => navigate(`/product/update/${item._id}`)}
                                                    >
                                                        <BiEdit /> {' '} Edit
                                                    </Dropdown.Item>
                                                    {/* <Dropdown.Item
                                                     onClick={() => handleUpdateStatus(item)}
                                                    >
                                                        {item.is_active 
                                                            ?   <> <FaRegCircle size={12}/> {' '} Deactivate </> 
                                                            :   <> <FaRegDotCircle size={12}/> {' '} Activate </>
                                                        }
                                                    </Dropdown.Item> */}
                                                </>
                                                }
                                            </Dropdown.Menu>}
                                        </Dropdown>
                                    </td>
                                </tr>     
                            )
                        })}
                    </tbody>
                </Table>
                {loading.loadMore ? <h2> Loading... </h2> : null}
            </>
        )
    }
    
    return (
        <section className="popular--Wrapper edit--page services-page-all">
            <Container fluid>
                <Row>
                    <Col sm={12} className="px-0">
                        <div className="sidebar--wrapper">
                            <div className="admin--content">
                                <Container>
                                    <Row className="mb-3">
                                        <div className="all-servicess-Wrap">
                                            <h2>{filter.is_deleted ? 'Trash' : null} Products</h2>
                                            <Link to="/product/create" className="ms-auto me-2">
                                                <Button variant="primary">Add Products</Button>
                                            </Link>
                                            <Button 
                                                variant="primary" 
                                                onClick={() => handleViewActiveTrash() }
                                            >
                                                View {filter.is_deleted ? 'Active' : 'Trash'}
                                            </Button>
                                        </div>
                                        <div className="service--filter">
                                            <div className="filter--type">
                                                <Form.Label>Product Type</Form.Label>
                                                <Form.Select 
                                                    aria-label="service type"
                                                    name="service_type"
                                                    className="filter--service--type"
                                                    onChange={(e) => handleChange(e)}
                                                    value={filter.service_type}

                                                >
                                                    <option value="all">All</option>
                                                    <option value="service">Service</option>
                                                    <option value="class">Class</option>
                                                </Form.Select>
                                            </div>
                                            <div className="filter--search">
                                                <Form.Label>Search</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search By Name or Description"
                                                    name="search"
                                                    value={filter.search || ''}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                            {!is_deleted && 
                                                <div className="filter--service--by">
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select 
                                                        aria-label="service by"
                                                        name="is_active"
                                                        className="filter--service--isActive"
                                                        value={filter.is_active}
                                                        onChange={(e) => handleChange(e)}
                                                    >
                                                        <option value="true">Active</option>
                                                        <option value="false">In Active</option>
                                                    </Form.Select>
                                                </div>
                                            }
                                            <Button
                                                variant="secondary" 
                                                onClick={() =>  handleShowDateFilter()}
                                            >
                                                {showDateFilter ? 'Hide Date Filter' : 'Show Date Filter'}
                                            </Button>
                                            {showDateFilter &&
                                                <div className="range__picker">
                                                    <DateRangePicker 
                                                        ranges={dateRange}
                                                        onChange={handleDateRangeChange}
                                                    />
                                                </div> 
                                            }
                                        </div>
                                        {loading.initial 
                                        ?   <LoadingView />
                                        :   <Accordion 
                                                defaultActiveKey="0" 
                                                className="services-main-wraper"
                                            >
                                                {(services && services.length) 
                                                    ?   renderTable()
                                                    :   <h4>No Record Available</h4>
                                                }
                                            </Accordion>
                                        }
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ProductList;