import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, Button, Modal, Dropdown,Accordion,Table } from "react-bootstrap";
import { getSellerStaff } from "../../redux/actions/SellerUser.action";
import { useDispatch, useSelector } from "react-redux";
import { MdVerified, MdDelete } from 'react-icons/md';
import { BiBoltCircle } from "react-icons/bi";
import { AiOutlineEye, AiOutlineSend } from "react-icons/ai";
import { Rating } from 'react-simple-star-rating';
import { useNavigate, useParams } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './staff.css';

function SellerStaff() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [staffDetails, setstaffDetails] = useState([]);
    const [singleStaffDetails, setsingleStaffDetails] = useState({});
    const [showStaffModel, setshowStaffModel] = useState(false); ////  Model open staff details
    const staffState = useSelector(state => state.sellerUser.getSellerStaff)

    const handleCloseStaffModel = () => setshowStaffModel(false);
    const handleShowStaffModel = (staff) => {
        setsingleStaffDetails(staff)
        setshowStaffModel(true)
    };

    useEffect(() => {
        staffDetailsApi()
    }, [])

    const staffDetailsApi = () => {
        dispatch(getSellerStaff(id))
    }

    useEffect(() => {
        if (staffState) {
            setstaffDetails(staffState)
            setLoading(false)
        }

    }, [staffState])

    return (
        <>
              <section className="popular--Wrapper edit--page services-page-all">
            <Container fluid>
                <Row>
                    <Col sm={12} className="px-0">
                        <div className="sidebar--wrapper">
                            <div className="admin--content">
                                <Container>
                                    <Row>
                                        <div className="all-servicess-Wrap">
                                            <h2>Our Staff</h2>
                                            <Button onClick={() => navigate('/seller/list')}>Back</Button>
                                        </div>
                                        {!loading ?
                                            <Accordion defaultActiveKey="0" className="services-main-wraper">
                                                {staffDetails && staffDetails?.length ?
                                                    <Table striped responsive="sm">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Phone number</th>
                                                                <th>Description</th>
                                                                <th>Payout (in %)</th>
                                                                <th>Email Verified</th>
                                                                <th className="edit-service">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {staffDetails && staffDetails?.map((item, ind) => {
                                                            return (
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{ind+1}</td>
                                                                        <td>{item?.name ? item?.name : "NA"}</td>
                                                                        <td>{item.email}</td>
                                                                        <td>{item?.phoneNumber ? item?.phoneNumber : "NA"}</td>
                                                                        <td>{item.about_staff}</td>
                                                                        <td>{item.commissionPayout}</td>
                                                                        <td>{item.emailVerification}</td>
                                                                        <td className="edit-service"> 
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                                    <AiOutlineEye />
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item 
                                                                                        onClick={() => navigate(`/seller/service/${item._id}`,
                                                                                            { state: { item }}
                                                                                        )}
                                                                                    >
                                                                                        View Service
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        })}
                                                    </Table>
                                                    :
                                                    <div>
                                                        <h2>Staff Not Available</h2>
                                                    </div>
                                                }
                                            </Accordion>
                                            :
                                            <div className="loader-main">
                                                <RotatingLines
                                                    strokeColor="grey"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="80"
                                                    visible={true}
                                                />
                                            </div>
                                        }
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        
        </>


    );
}

export default SellerStaff;