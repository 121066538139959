import React, { useState } from 'react';
import { Button, Form, Col, Row, Container } from "react-bootstrap";
import ReactQuill from "react-quill";
import toast from 'react-hot-toast';
import swal from 'sweetalert';
import API from '../../helpers/api';
import 'react-quill/dist/quill.snow.css';
import './style.css'
const CustomEmail = () => {
    const [editorContent, setEditorContent] = useState("");
    const [subject, setSubject] = useState('');
    const [error, setError] = useState({ subject: '', editorContent: '' });
    const [loader, setLoader ] = useState(false);

    const handleChange = (e, type) => {
        if (type === 'subject') {
            setSubject(e.target.value);
            if (error.subject) {
                setError(prevError => ({ ...prevError, subject: '' }));
            }
        }
        if (type === 'editorContent') {
            setEditorContent(e);
            if (error.editorContent) {
                setError(prevError => ({ ...prevError, editorContent: '' }));
            }
        }
    };

    const handleSubmit = () => {
        const errors = {};
        if (!subject.trim()) errors.subject = 'Subject is Required';
        if (!editorContent.trim()) errors.editorContent = 'Body is Required';

        setError(errors);

        if (Object.keys(errors).length === 0) {
            // handleSendCustomEmail({ subject, emailContent: editorContent });
            handleBulkEmail('isCustomEmail', { subject, emailContent: editorContent })
        }
    };


    const handleBulkEmail = async (type, emailData) => {
        const title = 'Who do you want to send this email to?';
        const payload =  { type, ...emailData }
      
        // swal({
        //   title,
        //   icon: "warning",
        //   buttons: ['Cancel', 'Yes'],
        //   dangerMode: false,
        // }).then(async (result) => {
        //     if (result) {

        //         try {
        //             setLoader(true);
        //             const response = await API.apiPostUrl('users', '/bulk-reminder-email', payload);
        //             setEditorContent("");
        //             setSubject('');
        //             if (response.data && response.data.success) {
        //                 swal({
        //                     text:'Emails are now in the queue and will be sent out according to schedule.',
        //                     icon: "success",
        //                     dangerMode: false,
        //                 })
        //             }else{
        //                 swal({
        //                     title:'Error Sending Emails',
        //                     icon: "error",
        //                     dangerMode: true,
        //                 })
        //             } 
        //         } catch (error) {
        //             const errorMessage = error.response?.data?.message || error.message;
        //             toast.error(errorMessage);
        //         } finally {
        //             setLoader(false)
        //         }
        //     }
        // });

        swal({
            title: "Who do you want to send this email to?",
            icon: "warning",
            buttons: ['Cancel', 'Send'],
            dangerMode: false,
            content: {
                element: "div",
                attributes: {
                    innerHTML: `
                        <div style="text-align: left;">
                            <input type="checkbox" id="profile" name="profile">
                            <label for="profile">People who haven't finished their profile</label><br>
                            <input type="checkbox" id="services" name="services">
                            <label for="services">People who haven't finished their services</label><br>
                            <input type="checkbox" id="schedule" name="schedule">
                            <label for="schedule">People who haven't finished their schedule</label>
                            <div style="margin-top:20px">
                                <small><strong>Note:</strong>If No above option selected by default email will be delivered to all Birth Professionals ( No Staff Included )</small>
                            </div>
                        </div>
                    `
                }
            },
            className: "swal-auto-width"
        }).then(async (result) => {
            if (result) {
                const profileChecked = document.getElementById('profile').checked;
                const servicesChecked = document.getElementById('services').checked;
                const scheduleChecked = document.getElementById('schedule').checked;
                if(profileChecked || servicesChecked || scheduleChecked){
                    payload.options = {}
                    payload.options.isProfileCompleted = profileChecked;
                    payload.options.isServiceCompleted = servicesChecked;
                    payload.options.isScheduleCompleted = scheduleChecked;
                }
        
                try {
                    setLoader(true);
                    const response = await API.apiPostUrl('users', '/bulk-reminder-email', payload);
                    setEditorContent("");
                    setSubject('');
                    if (response.data && response.data.success) {
                        swal({
                            text: 'Emails are now in the queue and will be sent out according to schedule.',
                            icon: "success",
                            dangerMode: false,
                        });
                    } else {
                        swal({
                            title: 'Error Sending Emails',
                            icon: "error",
                            dangerMode: true,
                        });
                    }
                } catch (error) {
                    const errorMessage = error.response?.data?.message || error.message;
                    toast.error(errorMessage);
                } finally {
                    setLoader(false);
                }
            }
        });      
    };

    return (
        <section className="popular--Wrapper edit--page services-page-all">
            <Col sm={12} className="px-0">
                <div className="sidebar--wrapper">
                    <div className="admin--content">
                        <Container>
                                <div className="all-servicess-Wrap">
                                    <h2>Customize Email To Birth Professionals</h2>
                                </div>
                                <Form className='mb-3'>
                                    <Form.Group className='mb-2'>
                                        <Form.Label>Subject</Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={subject}
                                            placeholder='Enter Subject'
                                            onChange={(e) => handleChange(e, 'subject')}
                                        />
                                        {error.subject && <span className="error">{error.subject}</span>}
                                    </Form.Group>
                                    <Form.Group controlId="formEditor" className='mb-2'>
                                        <Form.Label>Body</Form.Label>
                                        <ReactQuill
                                            value={editorContent}
                                            onChange={(e) => handleChange(e, 'editorContent')}
                                            placeholder='Enter Email body'
                                            // style={{ height: '250px' }}  // You can adjust the height as needed
                                        />
                                        {error.editorContent && <span className="error">{error.editorContent}</span>}
                                    </Form.Group>
                                </Form>
                                <Button
                                    className='mb-2'
                                    variant="primary"
                                    onClick={() => !loader && handleSubmit()}
                                    disabled={loader}
                                >
                                    {loader ? 'Sending...' : 'Submit'}
                                </Button>
                        </Container>
                    </div>
                </div>
            </Col>
        </section>
    );
};

export default CustomEmail;