import {
GET_ORDERS_SUCCESS,
GET_ORDERS_FAILED
} from "redux/actions/types";

const initialState = {
    error: null,
    all: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDERS_SUCCESS:
            return {
                all: action.payload,
                error: null
            }
        case GET_ORDERS_FAILED:
            return {
                error: action.payload
            }
        default: return state;
    }
};



