import { FiHome } from "react-icons/fi";
import { GrUserAdd } from "react-icons/gr";
import { RiNewspaperLine } from "react-icons/ri";
import { AiOutlineSolution,AiOutlineUserAdd } from "react-icons/ai";
import { GoListUnordered, GoMail, GoLocation } from 'react-icons/go';

import SignIn from 'components/authentication/sign-in';

import Dashboard from "components/dashboard";
import { Navigate } from "react-router-dom";
import ResetPassword from "components/authentication/reset-password";
import ProductList from "../components/ProductManagement/ProductList";
// import UpdateService from "../components/ServiceManagement/UpdateService";

import CreateOrUpdateService from "../components/ProductManagement/CreateOrUpdateService";

import SettingList from "../components/settings/List";
import UpdateSetting from "../components/settings/UpdateSetting";
import BuyerList from "../components/buyerManagement/BuyerList"; 
import SellerList from "../components/SellerManagement/SellerList";
import SellerService from "../components/SellerManagement/SellerService";
import SellerStaff from "../components/SellerManagement/SellerStaff";
import OrderList from "components/ordersManagement/List";
import CategoriesAddOrUpdate from "components/categoriesManagement/categoriesAddOrUpdate";
import CategoriesList from "components/categoriesManagement/categoriesList";
import SubcriptionList from "components/SubcriptionMangement/SubcriptionList";
import CreateOrUpdateSubcription from "components/SubcriptionMangement/CreateOrUpdateSubcription";
import SellerTransaction from "components/SellerManagement/SellerTransactions";
import MapView from "components/MapView";
import CustomEmail from "components/CustomEmail";
import BlockingLocationManagement from 'components/blockingLocationManagement';
import { TbLocationCancel } from "react-icons/tb";

const all = 'all'
const owner = 'managers'

/**
 *  type 'page' is visible in const companyMetaSusidenav
 *  type 'sub-page' will not visible in sidenav
 *  type 'title' will be a Heading without any route
 *  type 'divider' will be a line below menu
 *  collapse sidenav routes
 */

const privateRoutes = [
    {
        type: "other",
        name: 'ROOT',
        key: 'ROOT',
        component: <Navigate to="/dashboard" />,
        route: "/",
        access: all
    },
    {
        type: 'page',
        name: 'Dashboard',
        key: 'DASHBOARD',
        component: <Dashboard />,
        icon: <FiHome />,
        route: '/dashboard',
        access: all,
    },
    {
        type: 'page',
        name: 'Map View',
        key: 'MAP_VIEW',
        component: <MapView />,
        icon: <GoLocation />,
        route: '/map-view',
        access: all,
    },
    {
        type: "title",
        title: "Service Management",
        key: "Service",
        icon: <RiNewspaperLine />,
        // displayTo: owner,
        collapse: [
            {
                type: 'page',
                name: 'Product',
                key: 'PRODUCT_LIST',
                component: <ProductList />,
                icon: <AiOutlineSolution />,
                route: '/product/list',
            },
            {
                type: 'sub-page',
                name: 'Create Product',
                key: 'PRODUCT_CREATE',
                component: <CreateOrUpdateService />,
                icon: <GrUserAdd />,
                route: '/product/create',
            },
            {
                type: 'sub-page',
                name: 'Update Product',
                key: 'PRODUCT_UPDATE',
                component: <CreateOrUpdateService />,
                icon: <GrUserAdd />,
                route: '/product/update/:id',
            },
            {
                type: 'page',
                name: 'Category',
                key: 'CATEGORY_LIST',
                component: <CategoriesList />,
                icon: <GoListUnordered />,
                route: '/categories',
            },
            {
                type: 'sub-page',
                name: 'Add Category',
                key: 'ADD_CATEGORY',
                component: <CategoriesAddOrUpdate />,
                icon: <GoListUnordered />,
                route: '/category/add',
            },
            {
                type: 'sub-page',
                name: 'Update Category',
                key: 'UPDATE_CATEGORY',
                component: <CategoriesAddOrUpdate />,
                icon: <GoListUnordered />,
                route: '/category/:id',
            },
           
        ] 
    },
    {
        type: "title",
        title: "Family Management",
        key: "BUYER",
        icon: <AiOutlineUserAdd />,
        // displayTo: owner,
        collapse: [
            {
                type: 'page',
                name: 'List',
                key: 'BUYER_LIST',
                component: <BuyerList />,
                icon: <AiOutlineUserAdd />,
                route: '/buyer/list',
                // access: owner,
            },
        ]
    },
    {
        type: "title",
        title: "Birth Professional Management",
        key: "SELLER",
        icon: <AiOutlineUserAdd />,
        // displayTo: owner,
        collapse: [
            {
                type: 'page',
                name: 'List',
                key: 'SELLER_LIST',
                component: <SellerList />,
                icon: <AiOutlineUserAdd />,
                route: '/seller/list',
                // access: owner,
            },
            {
                type: 'sub-page',
                name: 'get seller service',
                key: 'GET_SELLER_SERVICE',
                component: <SellerService />,
                icon: <GrUserAdd />,
                route: '/seller/service/:id',
                // access: owner,
            },
            {
                type: 'sub-page',
                name: 'get seller staff',
                key: 'GET_SELLER_STAFF',
                component: <SellerStaff />,
                icon: <GrUserAdd />,
                route: '/seller/staff/:id',
                // access: owner,
            },
            {
                type: 'sub-page',
                name: 'Transactions',
                key: 'GET_SELLER_TRANSACTIONS',
                component: <SellerTransaction />,
                icon: <GrUserAdd />,
                route: '/seller/transactions/:id',
                // access: owner,
            },
        ]
    },
    {
        type: "title",
        title: "Orders Management",
        key: "Orders",
        icon: <GoListUnordered />,
        collapse: [
            {
                type: 'page',
                name: 'List',
                key: 'ORDER_LIST',
                component: <OrderList />,
                icon: <GoListUnordered />,
                route: '/orders',
            },
            {
                type: 'sub-page',
                name: 'User Orders',
                key: 'USER_ORDERS',
                component: <OrderList />,
                icon: <GoListUnordered />,
                route: '/orders/:id',
            },
        ]
    },
    {
        type: "title",
        title: "Subcription Management",
        key: "Subcriptions",
        icon: <GoListUnordered />,
        collapse: [
            {
                type: 'page',
                name: 'List',
                key: 'Subcription_LIST',
                component: <SubcriptionList />,
                icon: <GoListUnordered />,
                route: '/subcriptions/list',
                
            },
            {
                type: 'sub-page',
                name: 'Create Service',
                key: 'SERVICE_CREATE',
                component: <CreateOrUpdateSubcription/>,
                icon: <GrUserAdd/>,
                route: '/subcription/create',
            },
            {
                type: 'sub-page',
                name: 'Update Service',
                key: 'SERVICE_UPDATE',
                component: <CreateOrUpdateSubcription />,
                icon: <GrUserAdd/>,
                route: '/subcription/update/:id',
            },
        ]
    },
    {
        type: 'page',
        name: 'Families Allowed States',
        key: 'BLOCKKING_STATES',
        component: <BlockingLocationManagement />,
        icon: <TbLocationCancel/>,
        route: '/blocking-locations',
        access: all,
    },
    {
        type: 'page',
        name: 'Custom Email',
        key: 'CUSTOM_EMAIL',
        component: <CustomEmail />,
        icon: <GoMail/>,
        route: '/custom-email',
        access: all,
    },
    {
        type: "title",
        title: "Settings",
        key: "SETTINGS",
        icon: <AiOutlineSolution />,
        // displayTo: owner,
        collapse: [
            {
                type: 'page',
                name: 'Setting List',
                key: 'SETTINGS_LIST',
                component: <SettingList />,
                icon: <AiOutlineUserAdd />,
                route: '/setting/list',
                // access: owner,
            },
            {
                type: 'sub-page',
                name: 'update setting',
                key: 'UPDATE_SETTING',
                component: <UpdateSetting />,
                icon: <GrUserAdd />,
                route: '/update/setting/:id',
                // access: owner,
            },
        ]
    },
   
    {
        type: 'other',
        name: 'Un Match',
        key: "UN_MATCH",
        component: <Navigate to="/dashboard" />,
        route: "*",
        access: all
    }
]


const paySubscription = [
    // {
    //     type: 'other',
    //     name: 'ROOT',
    //     key: 'ROOT',
    //     component: <Navigate to="/pay/subscription" />,
    //     route: "/",
    // },
    // {
    //     type: 'sub-page',
    //     name: 'Pay Subscription',
    //     key: 'PAY_SUBSCRIPTION',
    //     component: <PaySubscription />,
    //     route: '/pay/subscription',
    // },
    // {
    //     type: 'other',
    //     name: 'Un Match',
    //     key: 'UN_MATCH',
    //     component: <Navigate to="/pay/subscription" />,
    //     route: "*",
    // }
]

const publicRoutes = [
    // {
    //     type: 'other',
    //     name: 'ROOT',
    //     key: 'ROOT',
    //     component: <Navigate to="/sign-in" />,
    //     route: "/",
    // },
    {
        type: 'page',
        name: 'Sign In',
        key: 'SIGN_IN',
        component: <SignIn />,
        route: '/sign-in',
    },
    // {
    //     type: 'page',
    //     name: 'Sign Up',
    //     key: 'SIGN_UP',
    //     component: <SignUp />,
    //     route: '/sign-up',
    // },
    {
        type: 'page',
        name: 'Reset Password',
        key: 'RESET_PASSWORD',
        component: <ResetPassword />,
        route: '/reset-password',
    },
    {
        type: 'other',
        name: 'Un Match',
        key: 'UN_MATCH',
        component: <Navigate to="/sign-in" />,
        route: "*",
    }
]

export { privateRoutes, paySubscription, publicRoutes };