import React, { useRef, useState } from "react";
import { Circle, CircleMarker, MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import { generateLetterSVG } from "utils/validations";
import { normalizeState } from "utils/states";
import { getRandomColor } from "utils/colors";
import L from "leaflet";
// import CircleView from './circleView';
import "leaflet/dist/leaflet.css";
import './mapStyle.css'


// Custom icon for the marker
const customIcon = new L.Icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"), // Default Leaflet marker icon
  iconSize: [25, 41], // Size of the icon
  iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  shadowSize: [41, 41], // Size of the shadow
});


const CustomMap = ({ data }) => {
  const mapRef = useRef(null);  // Reference to the map container
  let usedColors = []

  const changeColor = () => {
    let newColor;
    do {
      newColor = getRandomColor();
    } while (usedColors.includes(newColor));
    new Set(usedColors).add(newColor)
    return newColor;
  };

  const calculateCenter = () => {
    const totalCoords = data.reduce(
      (acc, user) => {
        const [lat, lng] =[user.address.location[1], user.address.location[0]]
        acc.lat += lat;
        acc.lng += lng;
        return acc;
      },
      { lat: 0, lng: 0 }
    );
  
    return [totalCoords.lat / data.length, totalCoords.lng / data.length];
  };

  const getSubscription = (user) => {
    let subscription = null;
  
    if (user.role === 'staff') {
      if (user.associatedWithSeller) {
        if (Array.isArray(user.associatedWithSeller)) {
          // If it's an array, take the first element
          user.associatedWithSeller = user.associatedWithSeller[0];
        }
        subscription =  user.associatedWithSeller.subscription || null;
      }
    } else if (user.role === 'seller') {
      subscription = user.subscription || null;
    }
  
    // Check if subscription is an object with any keys
    if (subscription && typeof subscription === 'object') {
      return Object.keys(subscription).length > 0 ? subscription.subscribed : false;
    }
  
    return subscription || false ;
  };
  

  const getBusinessName = (user) => {
    if(user.role === 'staff'){
      return  <div>
                <h6>{user.name}</h6>
                <small>{user.associatedWithSeller.name}</small>
              </div>
    }else{
      return  <div>
                <h6>{user.name}</h6>
                <small>{user.associatedWithSeller.name}</small>
              </div>
    }
  }
  const dataToShow = (user, type) => {
    if(type === 'popup'){
      return (
        <div className="popup-container">
          <div className="popup-header d-flex align-items-center">
            <img
              src={(user.profilePic && user.profilePic.url) ? user.profilePic.url : generateLetterSVG(user?.name, 36)}
              className="profile-pic"
            />
            {getBusinessName(user)}
          </div>
          <div className="popup-user-data">
            <div className="popup-row">
              <strong className="popup-label">Services</strong>
              <span className="popup-value">{(user.category.length > 0) ? user.category.join(', ') : 'Not Available'}</span>
            </div>
            <div className="popup-row">
              <strong className="popup-label">Location:</strong>
              <span className="popup-value">{user.address.city}, {normalizeState(user.address.state)}</span>
            </div>
            <div className="popup-row">
              <strong className="popup-label">Email Status:</strong>
              <span className="popup-value capitalize">{user.emailVerification}</span>
            </div>
            <div className="popup-row">
              <strong className="popup-label">Subscribed:</strong>
              <span className="popup-value">{getSubscription(user) ? "Yes" : "No"}</span>
            </div>
            <div className="popup-row">
              <strong className="popup-label">Business Approved:</strong>
              <span className="popup-value">{user.is_business_approved ? "Yes" : "No"}</span>
            </div>
            <div className="popup-row">
              <strong className="popup-label">Profile Completed:</strong>
              <span className="popup-value">{user.is_profile_completed ? "Yes" : "No"}</span>
            </div>
            <div className="popup-row">
              <strong className="popup-label">Services Completed:</strong>
              <span className="popup-value">{(user.services && user.services.length > 1) ? "Yes" : "No"}</span>
            </div>
            <div className="popup-row">
              <strong className="popup-label">Schedules Completed:</strong>
              <span className="popup-value">{(user.totalExistSchedules > 0) ? "Yes" : "No"}</span>
            </div>
          </div>
        </div>
      )
    }

    if(type === 'circle'){
      return (
        `<div style="font-size: 12px; text-align: center;font-weight:700;color:#000">
            <h6 style="margin-bottom:1px;text-transform:capitalize">${user.name}</h6>
            <p style="margin-bottom:1px;">${user.category.join(', ')}</p>
            <p style="margin-bottom:1px;">${user.address.city}, ${normalizeState(user.address.state)}</p>

        </div>`
      )
    }
  }

  const getRadiusInMeter = (totalDistanceInMiles) => {
    const radiusInMiles = totalDistanceInMiles / 2; // Get radius by dividing diameter by 2
    const radiusInMeters = radiusInMiles * 1609.34; // Convert radius from miles to meters
    return Math.floor(radiusInMeters); // Return the radius in meters
  };
  

  return (
    <MapContainer
        className="map-container"
        center={calculateCenter()}
        zoom={6}
        ref={mapRef}
    >
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
       {data.map((user, index) => 
            <Circle
                key={index}
                center={[user.address.location[1], user.address.location[0]]}
                radius={getRadiusInMeter(user.seller_serve_distance)} // Circle radius in meters (random between 10 to 200 miles)
                color="black"
                fillColor={changeColor()}
                fillOpacity={0.4}
            >
                
                {/* <CircleMarker
                  center={[user.address.location[1], user.address.location[0]]}
                  radius={5} // Small radius for the dot
                  fillColor="black"
                  color="black"
                  weight={1}
                  opacity={1}
                  fillOpacity={1}
                /> */}
                 {/* Map Marker at the center of the circle */}
                <Marker
                  position={[user.address.location[1], user.address.location[0]]}
                  icon={customIcon}
                >
                  <Popup>
                    {dataToShow(user, 'popup')}
                  </Popup>
                </Marker>
            </Circle>

        ) }
    </MapContainer>
  );
};

export default CustomMap;
