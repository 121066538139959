import { get } from 'lodash';
import API from '../../helpers/api';


/**   import Seller types */
import {
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILED,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAILED,
} from './types' ;


function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: {message: data.error.message},
        });
    } else {
        dispatch({
            type: type,
            payload: {message: data.message},
        });
    }
}

/**
 * 
 * @param {*} using for services 
 * @returns 
*/

export const getAllsettings = () => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('settings');
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_SETTINGS_SUCCESS, payload: response.data });
        } else {
            await dispatch({ type: GET_SETTINGS_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_SETTINGS_FAILED);
      }
    }
}


export const updateSettings = (payload) => {
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('settings',`/edit`, payload);
            /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: UPDATE_SETTINGS_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: UPDATE_SETTINGS_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, UPDATE_SETTINGS_FAILED);
        }
    }
}