import { get } from 'lodash';
import API from '../../helpers/api';

/**   import Seller types */
import {
    GET_BUYER_USER_SUCCESS,
    GET_BUYER_USER_FAILED,
} from './types' ;




function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: {message: data.error.message},
        });
    } else {
        dispatch({
            type: type,
            payload: {message: data.message},
        });
    }
}

/**
 * 
 * @param {*} using for services 
 * @returns 
*/

  

export const getAllBuyerUser = (query) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('buyerUser', query);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_BUYER_USER_SUCCESS, payload: response.data});
        } else {
            await dispatch({ type: GET_BUYER_USER_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_BUYER_USER_FAILED);
      }
    }
}