import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import jwtDecode from 'jwt-decode';

/**   import Seller types */
import {
    GET_SELLER_USER_SUCCESS,
    GET_SELLER_USER_FAILED,
    GET_SELLER_SERVICE_SUCCESS,
    GET_SELLER_SERVICE_FAILED,
    APPROVE_SELLER_SUCCESS,
    APPROVE_SELLER_FAILED,
    GET_SELLER_STAFF_SUCCESS,
    GET_SELLER_STAFF_FAILED,
    POST_SUBSCRIPTION_SUCCESS,
    POST_SUBSCRIPTION_FAILED,
} from './types' ;

function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: {message: data.error.message},
        });
    } else {
        dispatch({
            type: type,
            payload: {message: data.message},
        });
    }
}

/**
 * 
 * @param {*} using for services 
 * @returns 
*/

  

export const getAllSellers = (query) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('sellerUser', query);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_SELLER_USER_SUCCESS, payload: response.data });
        } else {
            await dispatch({ type: GET_SELLER_USER_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_SELLER_USER_FAILED);
      }
    }
}

export const getSellerService = (id) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('userServices', `/${id}`);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_SELLER_SERVICE_SUCCESS, payload: response.data});
        } else {
            await dispatch({ type: GET_SELLER_SERVICE_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_SELLER_SERVICE_FAILED);
      }
    }
}

export const approveSeller = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('users', `/approve`, payload);
      if (response.data && response.data.success) {
          await dispatch({ type:APPROVE_SELLER_SUCCESS, payload: response.data });
      } else {
          await dispatch({ type: APPROVE_SELLER_FAILED, payload: response.data });
      }
    } catch (error) {
      errorRequest(error, dispatch, APPROVE_SELLER_FAILED)
    }
  }
}

export const getSellerStaff = (id) => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet('userStaff', `/${id}`);
      /** -- If Response Success -- */
      if (response.data && response.data.success) {
          await dispatch({ type: GET_SELLER_STAFF_SUCCESS, payload: response.data.staff
          });
      } else {
          await dispatch({ type: GET_SELLER_STAFF_FAILED, payload: response.data });
      }
    } catch (error) {
      errorRequest(error, dispatch, GET_SELLER_STAFF_FAILED);
    }
  }
}

export const updateSubscription = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('users', `/subscription`, payload);
      /** -- If Response Success -- */
      if (response.data && response.data.success) {
          await dispatch({ type: POST_SUBSCRIPTION_SUCCESS, payload: response.data });
      } else {
          await dispatch({ type: POST_SUBSCRIPTION_FAILED, payload: response.data });
      }
    } catch (error) {
      errorRequest(error, dispatch, POST_SUBSCRIPTION_FAILED);
    }
  }
}