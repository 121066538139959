
import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from 'react-hot-toast';
import { privateRoutes, publicRoutes } from 'routes';
import Sidebar from "components/sidebar";
import TopBar from "components/sidebar/topbar";
// import { getCompanyMeta, getEmplyeeMeta } from "redux/actions/meta.actions";
import * as auth from 'helpers/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-pro-sidebar/dist/css/styles.css";
import './App.css';

function App() {
  const dispatch =  useDispatch();
  const [loader, setLoader] = useState(true)
  // const [company, setCompanyMeta] = useState(null)
  // const [employee, setEmployeeMeta] = useState(null)
  // const companyMetaSuccess = useSelector((state) => state.meta.companyMeta);
  // const employeeMetaSuccess = useSelector((state) => state.meta.employeeMeta);
  // const apiError = useSelector((state) => state.meta.error);
  const isLoggedIn = useSelector((state) => state.auth.loggedIn || auth.isAuth());
  // const openPaySubscription = useSelector((state) => state.auth.openPaySubscription || auth.getSubscriptionSession());

  useEffect(() => {
    (async () => {
      /** -- If Logged In and Pay Subscription is not needed */
      if(isLoggedIn){
        setLoader(false)
        // await dispatch(getCompanyMeta())
        // await dispatch(getEmplyeeMeta())
      }else{
        setLoader(false)
      }
    })()
  }, [])

  // useEffect(() => {
  //   // if(companyMetaSuccess){
  //   //     setCompanyMeta(companyMetaSuccess);
  //   // }
  //   // if(employeeMetaSuccess){
  //   //     setEmployeeMeta(employeeMetaSuccess)
  //   // }
  //   if(apiError){
  //     setLoader(false)
  //   }
  // }, [
  //   // companyMetaSuccess, 
  //   // employeeMetaSuccess, 
  //   apiError
  // ])

  // useEffect(() => {
  //   if(company && employee){
  //     setLoader(false)
  //   }
  // }, [company, employee])

  const handleLogout = () => {
    console.log("Logged out");
    // Add logout logic here
  };

  const getRoutes = (routes, _privateRoute) => 
    routes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse, _privateRoute);
      }

      // if( _privateRoute 
      //   && route.access 
      //   && auth.notAllowAccess(route.access, employeeMetaSuccess?.role.title)
      //   ){
      //   return null;
      // }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    return (
      <div className="App">
        <Toaster/>
        { (isLoggedIn && !loader)
          ?   <Sidebar
                routes={privateRoutes}
              />
          :   null 
        }
        { !loader 
            ? isLoggedIn
              ?  <section className="dashContent">
                    <TopBar 
                      // company={company} 
                      // employee={employee}
                    />
                    <Routes>
                      {getRoutes(privateRoutes, true)}
                    </Routes>
                  </section>   
              :   <Routes>
                    {getRoutes(publicRoutes)}
                  </Routes>
            : <div className="app-loader"> 
                <img 
                  src={require("assets/images/icons/loading.gif")} 
                  alt="Loading..."
                />
              </div>
        }
      </div>
  );
}

export default App;




/**
 * 
 *  <div className="App">
      <Toaster/>
      {(isLoggedIn && !openPaySubscription)
        ?   <Header/>
        :   null 
      }
      {isLoggedIn
        ? <section className="dashContent">
            <TopBar/>
            <Routes>
              {AppRoutes()}
            </Routes>
          </section> 
        : openPaySubscription 
          ?   <Routes>
                {PaySubscriptionRoutes()}
              </Routes>
          :   <Routes>
                {AuthRoute()}
              </Routes>
      }
    </div>
 */