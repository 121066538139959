import { 
    LOGIN_FAILED, 
    LOGIN_SUCCESS, 
    LOGIN_FAILED_UPDATE_PASSWORD,
    POST_JOIN_BROKER_FAILED,
    POST_JOIN_BROKER_SUCCESS,

    PATCH_FIRST_NEW_PASSWORD_SUCCESS,
    PATCH_FIRST_NEW_PASSWORD_FAILED,
    LOGIN_LIST_BROKER_COMPANIES,
    LOGIN_SUCCESS_UNPAID_SUBSCRIPTION,
    POST_VERIFY_INVITE_SUCCESS,
    POST_VERIFY_INVITE_FAILED,
    LOGOUT,

    REQUEST_OTP_SUCCESS,
    REQUEST_OTP_FAILED,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,

    AUTH_COMMON_ERROR 
} from "redux/actions/types";
import * as auth from 'helpers/auth';
const token = auth.getToken();

const initialState = {
  token: null,
  loggedIn: token ? true : false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: 
        return { 
            token: action.payload,
            loggedIn: token ? true : false 
        };
        case LOGIN_FAILED: 
        return { 
            error: action.payload, 
            loggedIn: token ? true : false 
        };
        
    case POST_JOIN_BROKER_SUCCESS:
        return {
            joinMessage:action.payload.message,
            loggedIn:token ? true : false
        }
    case POST_JOIN_BROKER_FAILED:
        return {
            joinFail: action.payload, 
            loggedIn: token ? true : false 
        }
    case POST_VERIFY_INVITE_SUCCESS:
        return {
            user:action.payload.user,
            loggedIn: token ? true : false,
        }
    case POST_VERIFY_INVITE_FAILED:
        return {
            error: action.payload, 
            loggedIn: token ? true : false 
        }
    case LOGIN_SUCCESS_UNPAID_SUBSCRIPTION:
        return {
            token: action.payload.accessToken,
            loggedIn: token ? true : false,
            openPaySubscription: true,
        }
    case LOGIN_LIST_BROKER_COMPANIES:
        return {
            loggedIn: token ? true : false,
            associatedWithCompanies: action.payload.associatedWithCompany
        }
    case LOGIN_FAILED_UPDATE_PASSWORD: 
        return {
            error: action.payload,
            updatePassword: true,
            loggedIn:false 
        }
    case PATCH_FIRST_NEW_PASSWORD_SUCCESS: 
        return {
            updateMessage: action.payload.message,
            updatePassword: false,
            loggedIn:false 
        }
    case PATCH_FIRST_NEW_PASSWORD_FAILED:
        return {
            error: action.payload,
            updatePassword: true,
            loggedIn: false,
        }
    case REQUEST_OTP_SUCCESS: 
        return {
            requestMessage: action.payload.message,
            loggedIn: false
        }
    case REQUEST_OTP_FAILED: 
        return {
            error: action.payload,
            loggedIn: false,
        }   
    case VERIFY_OTP_SUCCESS:
        return {
            verifyMessage: action.payload.message,
            loggedIn: false,
        }
    case VERIFY_OTP_FAILED: 
        return {
            error: action.payload,
            loggedIn: false,
        }
    case RESET_PASSWORD_SUCCESS: 
        return {
            updateMessage: action.payload.message,
            loggedIn: false,
        }
    case RESET_PASSWORD_FAILED:
        return {
            error: action.payload,
            loggedIn: false,
        }
    case AUTH_COMMON_ERROR: 
        return {
            error: action.payload, 
            loggedIn: token ? true : false 
        }
    case LOGOUT: return null;
    default: return state;
  }
};
