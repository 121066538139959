import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Accordion , Button , Dropdown, Form} from "react-bootstrap";
import { getAllBuyerUser } from "../../redux/actions/buyerUser.action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation , useNavigate } from 'react-router-dom';
import Switch from 'react-js-switch';
import swal from 'sweetalert';
import toast from "react-hot-toast";
import API from '../../helpers/api';
// import * as auth from '../../helpers/auth';
import LoadingView from "components/LoadingView";
import { displayShortAddress } from "utils/validations";
// import { checkScrolledToBottom } from "utils/scroll";
import { serviceTypeName, momentFormat } from "utils/validations";
import { DateRangePicker } from 'react-date-range';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './buyerStyle.css'

const limitPerPage = process.env.REACT_APP_LISTING_LIMIT

function BuyerList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const is_deleted = queryParams.get('is_deleted');
    const [ filter, setFilter] = useState({is_active: true, search: '', is_deleted: JSON.parse(is_deleted) ? true : false , emailVerification: '' , loadData:false})
    // Get the value of the 'q' parameter from the URL
    const [isSwitchOn, setIsSwitchOn] = useState(true);
    // const [loading, setLoading] = useState(true);
    const [loading, setLoading] = useState({ delete: false, initial: true, loadMore:false });
    const [userBuyerData, setuserBuyerData] = useState([])  //////  services all Data
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(0);
    const [from , setFrom] = useState(new Date());
    const [to , setTo] = useState(new Date());
    const [showDateFilter , setShowDateFilter] = useState(false);

    const [ emailVerifyLoader, setEmailVerifyLoader ] = useState('')

    const [dateRange, setDateRange] = useState([
        {
            startDate: from,
            endDate: to,
            key: 'selection',
        },
    ]);
    const buyerUserState = useSelector(state => state.buyerUser)

    useEffect(()=>{
        if(!JSON.parse(is_deleted)){
            getUsers()
        }
    },[])

    useEffect(() => {
        setFilter({ ...filter, is_deleted: JSON.parse(is_deleted) ? true : false })
    }, [is_deleted])

    /** ---Add Event Listener --- */
   

    useEffect(() => {
        if( buyerUserState.getAllBuyerUser && buyerUserState.getAllBuyerUser.success){
            if((userBuyerData.length < buyerUserState.getAllBuyerUser.totalCount)){
                var buyer = userBuyerData ;
                Array.prototype.push.apply(buyer, buyerUserState.getAllBuyerUser.users);
                buyer = buyer.filter((v, i, a) => a.findIndex(t => (t._id === v._id)) === i)
                setuserBuyerData(buyer);
            }else{
                setuserBuyerData(buyerUserState.getAllBuyerUser.users);
            }
            setTotalPages(Math.ceil(buyerUserState.getAllBuyerUser.totalCount/limitPerPage));
            setLoading({ ...loading , initial: false, loadMore: false });
            if(page > 0 ){
                window.scrollTo(0, document.documentElement.offsetHeight - 20);
            }
        }
        if( buyerUserState.error ){
            toast.error(buyerUserState.error.message)
            setLoading({ initial: false, delete: false, loadMore: false });
        }
    },[buyerUserState])


    useEffect(() => {
        setFilter({ ...filter, startDate: '' , endDate : '' })
    }, [showDateFilter])


    useEffect(() => {
        if(page < totalPages){
            getUsers();
        }else{
            setLoading(prevState => ({
                ...prevState,
                loadMore: false
            }));
        }
    }, [page])

    useEffect(() => {
        setLoading(prevState => ({
            ...prevState,
            initial: true
        }));
        setPage(0);
        setTotalPages(0)
        setuserBuyerData([])
        // Define a delay for debouncing (e.g., 500 milliseconds)
        const delay = 700;
        // Use a timer to delay the API call after typing stops
        const timerId = setTimeout(() => {
            getUsers();
            // if (filter.search.trim() !== '') {
            // }
        }, delay);
    
        // Clear the timer on component unmount or when input changes
        return () => clearTimeout(timerId);
    },[filter]);

    useEffect(() => {

        if(!loading.loadMore) return;

        if(page < totalPages){
            setTimeout(() => {
                setPage(page + 1)
            }, 1200);
        }else{
            setLoading(prevState => ({
                ...prevState,
                loadMore: false
            }));
        }   
    }, [ loading.loadMore ]);

    const getUsers = () => {
        dispatch(getAllBuyerUser({page, limit:limitPerPage, ...filter}))
    }

    const handleChange = (e) =>{
        const { name, value } = e.target;
        setPage(0);
        setTotalPages(0);
        setuserBuyerData([])
        setFilter({ ...filter, [name]: value });
    }

    const handleDateRangeChange = (ranges) =>{
        const { startDate, endDate } = ranges.selection;
        setPage(0);
        setTotalPages(0);
        setuserBuyerData([])
        setDateRange([ranges.selection]);
        setFilter({...filter , startDate : startDate , endDate : endDate})
    }

    const handleShowDateFilter = () =>{
        setShowDateFilter(!showDateFilter)
        setPage(0);
        setTotalPages(0);
        setDateRange( [
            {
              startDate: new Date(),
              endDate: new Date(),
              key: 'selection',
            },
        ]);
    }
    /**********************************************************/

    const handleViewActiveTrash = () => {
        setPage(0);
        filter.is_deleted 
            ? navigate('/buyer/list')
            : navigate(`/buyer/list?is_deleted=true`)
        
        let q = {
            search:'', service_type: 'all'
        }
        if(filter.is_deleted ){
            q.is_active = true
            q.is_deleted = false
        }
        setFilter((prevFilter) => ({ ...prevFilter, ...q, loadData:true}));
    }

    const switch_onChange_handle = async (item) => {
        if(item.is_active == true){
            const payload = {
                is_active:false
            }

            swal({
                title: "Are you sure user suspend?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then( async(willDelete) => {
                if (willDelete) {
                    try {
                        let updatedBuyer = userBuyerData.filter(buyer => buyer._id !== item._id);
                        setuserBuyerData(updatedBuyer)
                        const response = await API.apiPostUrl('buyerUserEdit',`/${item._id}`, payload);
                        if(response.data && response.data.success){
                            swal("Good Job", response.data.message );
                            setPage(0)
                            getUsers()
                        }
                    } catch (error) {
                        if(error.response && error.response.data){
                            toast.error(error.response.data.message)
                        }else{
                            toast.error(error.message)
                        }
                    }
                } 
            });
        }else{
            const payload ={
                is_active:true
            } 
            swal({
                title: "Are you sure user unsuspend?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then( async(willDelete) => {
                if (willDelete) {
                    try {
                        const response = await API.apiPostUrl('buyerUserEdit',`/${item._id}`, payload);
                        if(response.data && response.data.success){
                            swal("Good Job", response.data.message );
                            getUsers()
                        }
                    } catch (error) {
                        if(error.response && error.response.data){
                            toast.error(error.response.data.message)
                        }else{
                            toast.error(error.message)
                        }
                    }
                } 
            });
        }
    }

    const handleIsOutOfCountry = async (item) => {
        if(item.isOutOfCountry){
            const payload = {
                isOutOfCountry:false
            }

            swal({
                title: "Are you sure?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then( async(willDelete) => {
                if (willDelete) {
                    try {
                        // let updatedBuyer = userBuyerData.filter(buyer => buyer._id !== item._id);
                        // setuserBuyerData(updatedBuyer)
                        const response = await API.apiPostUrl('buyerUserEdit',`/${item._id}`, payload);
                        if(response.data && response.data.success){
                            swal("Good Job", response.data.message );
                            setPage(0)
                            getUsers()
                        }
                    } catch (error) {
                        if(error.response && error.response.data){
                            toast.error(error.response.data.message)
                        }else{
                            toast.error(error.message)
                        }
                    }
                } 
            });
        }else{
            const payload ={
                isOutOfCountry:true
            } 
            swal({
                title: "Are you sure?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then( async(willDelete) => {
                if (willDelete) {
                    try {
                        const response = await API.apiPostUrl('buyerUserEdit',`/${item._id}`, payload);
                        if(response.data && response.data.success){
                            swal("Good Job", response.data.message );
                            getUsers()
                        }
                    } catch (error) {
                        if(error.response && error.response.data){
                            toast.error(error.response.data.message)
                        }else{
                            toast.error(error.message)
                        }
                    }
                } 
            });
        }
    }
   
    /** ---------- Manual Email Verification ---------- */
    const handleVerifyEmail = async (id) => {
        swal({
            title: `Are you sure you want to verify the email address?`,
            text: 'This action will grant the user access to certain features.',
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: false,
        }).then(async (result) => {
            if (result) {
                try {
                    setEmailVerifyLoader(id);
                    const response = await API.apiPostUrl('users', `/verify-email/${id}`);
                    if (response.data && response.data.success) {
                        swal({
                            icon: 'success',
                            title: response.data.message
                        });
                        getUsers()
                    }
                } catch (error) {
                    if (error.response && error.response.data) {
                        toast.error(error.response.data.message)
                    } else {
                        toast.error(error.message)
                    }
                } finally {
                    setEmailVerifyLoader(false);
                }
            }
        });
    }
   


    /************************************************************************** */

    const renderTable = () => {
        return (
            <>    
                <Table striped responsive="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Email Status</th>
                            <th>Phone Number</th>
                            <th>Address</th>
                            <th>Account Active</th>
                            {/* <th>Account isOutOfCountry</th> */}
                            <th>Family Registered Date</th>
                            {/* <th className="edit-service">Action</th> */}
                        </tr>
                    </thead>
                    {userBuyerData.filter((it)=>it.is_deleted !== true).map((item, ind) => {
                        return (
                            <tbody>
                                <tr>
                                    <td>{ind+1}</td>
                                    <td className="street">{item?.name}</td>
                                    <td>{item.email}</td>
                                    <td>
                                        {/* {item?.emailVerification ? item?.emailVerification : "NA"} */}
                                        {item?.emailVerification
                                            ? <span
                                                className={`status--${item?.emailVerification} text-capitalise`}
                                                style={{ padding: '4px 8px', borderRadius: '3px' }}
                                            >
                                                {item?.emailVerification}
                                            </span>
                                            : "NA"
                                        }

                                        {item?.emailVerification === 'pending' &&
                                            <Button
                                                size="sm"
                                                className="m-1 text-capitalise"
                                                onClick={() => handleVerifyEmail(item._id)}
                                                disabled={(emailVerifyLoader.toString() === item._id.toString()) || !item.is_active}
                                                style={{ padding: '3px 8px', fontSize: '12px' }}
                                            >
                                                {(emailVerifyLoader.toString() === item._id.toString()) ? 'Sending...' : 'Verify'}
                                            </Button>
                                        }
                                    </td>
                                    <td>{item.phoneNumber || 'N/A'}</td>
                                    <td>{item.address ? displayShortAddress(item.address) : 'N/A'}</td>
                                    {/* <td>{item?.address?.city ? item?.address?.city : "NA"}</td>
                                    <td>{item?.address?.country ? item?.address?.country : "NA"}</td>
                                    <td>{item?.address?.postalCode ? item?.address?.postalCode : "NA"}</td>
                                    <td>{item?.address?.state ? item?.address?.state : "NA"}</td>
                                    <td className="street">{item?.address?.street ? item?.address?.street : "NA"}</td> */}
                                    
                                    <td>
                                        <Switch 
                                            value={item?.is_active} 
                                            onChange={()=>switch_onChange_handle(item)}
                                            backgroundColor={
                                                { on: 'blue', off: "grey" }
                                            }
                                            borderColor={
                                                { on: 'blue', off: "grey" }   
                                            }
                                        />
                                    </td>

                                    {/* <td>
                                        <Switch 
                                            value={item?.isOutOfCountry} 
                                            onChange={()=>handleIsOutOfCountry(item)}
                                            backgroundColor={
                                                { on: 'blue', off: "grey" }
                                            }
                                            borderColor={
                                                { on: 'blue', off: "grey" }   
                                            }
                                        />
                                    </td> */}

                                    <td>{momentFormat.fullDate(item.createdAt)}</td>
                                    {/* <td className="edit-service"> 
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    <AiOutlineEye />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item 
                                                        onClick={() => navigate(`/update/setting/:${item._id}`,
                                                        { state: { item }}
                                                        )}
                                                    >
                                                        <BiEdit /> Edit
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td> */}
                                </tr>
                            </tbody>
                        )
                    })}
                </Table>
                {loading.loadMore ? <h5> Loading... </h5> : null}
            </>
        );
    }
  
    return (
        <section className="popular--Wrapper edit--page services-page-all">
            <Container fluid>
                <Row>
                    <Col sm={12} className="px-0">
                        <div className="sidebar--wrapper">
                            <div className="admin--content">
                                <Container>
                                    <Row>
                                        {/* <div className="all-servicess-Wrap">
                                            <h2>Buyers</h2>
                                        </div> */}
                                        <div className="all-servicess-Wrap">
                                            <h2>{filter.is_deleted ? 'Trash' : null} Buyers</h2>
                                            <Button 
                                                className="ms-auto"
                                                variant="primary" 
                                                onClick={() => handleViewActiveTrash() }
                                            >
                                                View {filter.is_deleted ? 'Active' : 'Trash'}
                                            </Button>
                                        </div>
                                        <div className="service--filter">
                                            <div className="filter--type">
                                                <Form.Label>Email Status</Form.Label>
                                                <Form.Select 
                                                    aria-label="service type"
                                                    name="emailVerification"
                                                    className="filter--service--type"
                                                    onChange={(e)=>handleChange(e)}
                                                    value={filter.emailVerification}

                                                >
                                                    <option value="">All</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="verified">Verified</option>
                                                </Form.Select>
                                            </div>
                                            <div className="filter--search">
                                                <Form.Label>Search</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search By Name , Email , Phone Number"
                                                    name="search"
                                                    value={filter.search || ''}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                            {!is_deleted && 
                                            <div className="filter--service--by">
                                                <Form.Label>Status</Form.Label>
                                                <Form.Select 
                                                    aria-label="service by"
                                                    name="is_active"
                                                    className="filter--service--isActive"
                                                    value={filter.is_active}
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                    <option value="true">Active</option>
                                                    <option value="false">In Active</option>
                                                </Form.Select>
                                            </div>}
                                            <Button
                                                variant="primary" 
                                                onClick={() =>  handleShowDateFilter()}
                                            >
                                                {showDateFilter ? 'Hide Date Filter' : 'Show Date Filter'}
                                            </Button>
                                            {showDateFilter &&
                                                <div>
                                                    <DateRangePicker 
                                                        ranges={dateRange}
                                                        onChange={handleDateRangeChange}
                                                    />
                                                </div> 
                                            }
                                        </div>
                                        {loading.initial 
                                            ?   <LoadingView />
                                            :   <Accordion
                                                    defaultActiveKey="0" 
                                                    className="services-main-wraper"
                                                >
                                                    {(userBuyerData && userBuyerData.length > 0)
                                                    
                                                        ? 
                                                            renderTable()
                                                           
                                                        : <h3>Data Not Available</h3>
                                                    }
                                                </Accordion>

                                        }
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default BuyerList;