import {
    GET_SERVICES_BB_SUCCESS,
    GET_SERVICES_BB_FAILED,
    DELETE_SERVICES_BB_SUCCESS,
    DELETE_SERVICES_BB_FAILED,
    ADD_SERVICES_BB_SUCCESS,
    ADD_SERVICES_BB_FAILED,
    UPDATE_SERVICES_BB_SUCCESS,
    UPDATE_SERVICES_BB_FAILED,
    GET_ONE_BBSERVICE_SUCCESS,
    GET_ONE_BBSERVICE_FAILED
} from "redux/actions/types";

const initialState = {
    error: null,
    getAllServiceBB: null,
    deleteServiceBB: null,
    // errorDServiceBB: null,
    addServiceBB: null,
    // errorAddServiceBB: null,
    updateServiceBB: null,
    // errorupdateServiceBB: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICES_BB_SUCCESS:
            return {
                getAllServiceBB: action.payload,
                error: null, 
            }
        case GET_SERVICES_BB_FAILED:
            return {
                error: action.payload,
            }
        case DELETE_SERVICES_BB_SUCCESS:
            return {
                deleteServiceBB: action.payload,
                error: null
            }
        case DELETE_SERVICES_BB_FAILED:
            return {
                error: action.payload,
            }
        case ADD_SERVICES_BB_SUCCESS:
            return {
                addServiceBB: action.payload,
                error: null
            }
        case ADD_SERVICES_BB_FAILED:
            return {
                error: action.payload
            }
        case UPDATE_SERVICES_BB_SUCCESS:
            return {
                update: action.payload,
                error: null
            }
        case UPDATE_SERVICES_BB_FAILED:
            return {
                error: action.payload,
            }
        case GET_ONE_BBSERVICE_SUCCESS:
            return {
                getOne: action.payload,
                error : null
            }
        case GET_ONE_BBSERVICE_FAILED:
            return {
                error:  action.payload
            }
        default: return state;
    }
};
