import React, { useEffect, useState } from 'react';
import { Form, Button } from "react-bootstrap";
import toast from 'react-hot-toast';
import { emailValidation } from 'utils/validations';
import { requestOtp } from 'redux/actions/auth.actions';
import { useDispatch, useSelector } from 'react-redux';

const RequestOtp = ({ logo, setEmail, email, setShowOtpView }) => {
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);
    const [loader, setLoader]= useState(false);
    const [error, setError] = useState('');
    const state = useSelector(state => state.auth)
    const check = [null, undefined, 'null', 'undefined', ''];
    const requestMessage = useSelector(state => state.auth.requestMessage)
    const apiError = useSelector(state => state.auth.error)

    useEffect(() => {
        setError('')
    }, [email]);


    const handleRequestOTP = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoader(true);
        if(check.includes(email) || !emailValidation(email)){
            setError('Please provide valid email')
            setLoader(false);
        }else{
            await dispatch(requestOtp({ email: email }))
        }
    }

    useEffect(() => {
        toast.dismiss()
        if(error || apiError){
            toast.error(error || apiError);
            setLoader(false)
        }

        if(requestMessage){
            setLoader(false);
            setShowOtpView(true)
        }
    }, [state])

    return (
        <div className="formTop p-4 shadow-lg rounded">
            <div className="text-center">
                <img src={logo} className="App-logo mb-3" alt="logo" />
                <h2>Forgot Password?</h2>
                <p>Enter your email to verify it's you</p>
            </div>
            <Form onSubmit={handleRequestOTP}>
                <Form.Group className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control 
                        type="email" 
                        placeholder="Enter email"
                        value={email}
                        onChange={({ target:{ value } }) => setEmail(value)} 
                    />
                    {error && <span className='error'>{error}</span>}
                </Form.Group>
                <div className="text-center">
                    <Button 
                        variant="primary" 
                        type="submit"
                        onClick={handleRequestOTP}
                        disabled={disable || loader}
                    >
                        {loader ? 'Please wait...' : 'Submit'}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default RequestOtp;