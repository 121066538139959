import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import serviceBBReducer from "./serviceBB.reducer";
import settingsReducer from "./settings.reducer";
import buyerUserReducer from "./buyerUser.reducer";
import sellerUserReducer from "./sellerUser.reducer";
import ordersReducer from "./orders.reducer";
import categoryReducer from "./category.reducer";
import subcriptionReducer from "./subcription.reducer";
import categoryRestrictionReducer from "./categoryRestriction.reducer";

export default combineReducers({
  auth: authReducer,
  serviceBB:serviceBBReducer,
  settings:settingsReducer,
  buyerUser:buyerUserReducer,
  sellerUser:sellerUserReducer,
  order: ordersReducer,
  category: categoryReducer ,
  subcription: subcriptionReducer,
  categoryRestriction: categoryRestrictionReducer,
});
