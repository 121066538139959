import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { BsTrash2Fill } from 'react-icons/bs';
import API from '../../helpers/api';
import toast from "react-hot-toast";

const BlockingLocationManagement = () => {
    const [ loading, setLoading ] = useState({ list: false, save: false, delete: false});
    const [ locations, setLocations ] = useState([])
    const [ showModal, setShowModal ] = useState(false);
    const [showPlaces, setShowPlaces] = useState(false);
    const [searchLocation, setSearchLocation] = useState('');

    useEffect(() => {
        getLocations()
    }, [])

    const getLocations = async () => {
        try {
            setLoading({ ...loading, list: true});

            const response = await API.apiGetByKey('settings', `/blocked-locations`);
            if (response.data && response.data.success) {
                setLocations( response.data.locations);
                // toast.error(errorMessage);
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message;
            console.log('Error Message', errorMessage)
            toast.error(errorMessage);
        } finally {
            setLoading({ ...loading, list: false });
        }
    }

    // const getPlaces = async () => {
    //     try {
    //         setLoading({ ...loading, search: true});

    //         const response = await API.apiGetByKey('places', `/search/states?location=${searchLocation}`);
    //            console.log( response.data);
    //         if (response.data && response.data.success) {
    //             // setLocations( response.data.suggestions);
    //         }
    //     } catch (error) {
    //         const errorMessage = error.response?.data?.message || error.message;
    //         console.log('Error Message', errorMessage)
    //         toast.error(errorMessage);
    //     } finally {
    //         setLoading({ ...loading, search: false });
    //     }
    // }

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //       if (searchLocation) {
    //         console.log('kkkk')
    //         setShowPlaces(true)
    //         getPlaces()
    //         // dispatch(getPlaces(searchLocation));
    //       } else {
    //         setShowPlaces(false)
    //       }
    //     }, 500);
    
    //     return () => clearTimeout(timer);
    
    //   }, [searchLocation]);

    // const handleSelectPlace = (place) => {
    //     console.log( place )
    //     setShowPlaces(false);
    //     setSearchLocation('');
    //     // dispatch(getPlaceDetails(place.place_id))
    // }

    const renderTable = () => {
        return (
            <>    
                <Table striped responsive="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Actions</th>
                            {/* <th className="edit-service">Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {locations.map((item, ind) => {
                            return (
                                <tr>
                                    <td>{ind + 1}</td>
                                    <td>{item.location}</td>
                                    <td>
                                        {loading.delete === item._id ? '...' : <BsTrash2Fill onClick={() => deleteLocation(item._id)} style={{ cursor:'pointer'}}/>}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>

                </Table>
            </>
        );
    }

    // const renderModal = () => {
    //     return (
    //         <Modal
    //             show={showModal}
    //             onHide={() => setShowModal(false)}
    //         >
    //             <Modal.Header closeButton>Add States to Allow Families for Signup</Modal.Header>
    //             <Modal.Body>
                    
    //             </Modal.Body>
    //             <Modal.Footer>
    //                 <Button onClick={() => setShowModal(false)}>close</Button>
    //                 <Button>Add Location</Button>
    //             </Modal.Footer>               
    //         </Modal>
    //     )
    // }

    const saveLocation = async () => {
            try {
                setLoading({ ...loading, save: true});
    
                const response = await API.apiPostUrl('settings', `/blocked-locations`, {location:searchLocation});
                if (response.data && response.data.success) {
                    // setLocations( response.data.suggestions);
                    getLocations();
                    setSearchLocation('');

                }
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message;
                console.log('Error Message', errorMessage)
                toast.error(errorMessage);
            } finally {
                
                setLoading({ ...loading, save: false });
            }
    }

    const deleteLocation = async (id) => {
        try {
            setLoading({ ...loading, delete: id});

            const response = await API.apiDeleteUrl('settings', `/blocked-locations/${id}`, );
            if (response.data && response.data.success) {
                getLocations();
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message;
            console.log('Error Message', errorMessage)
            toast.error(errorMessage);
        } finally {
            
            setLoading({ ...loading, delete: false });
        }
    }

    return (
        <div className='blocked-locations-main'>
            <div className='d-flex justify-content-between align-items-center p-3'>
                <h4>Families Allowed States</h4>
                {/* <Button variant="primary" onClick={() => setShowModal(true)}>Add Location</Button> */}
            </div>
            <div className='p-3'>
                <div className='d-flex justify-content-between'>
                    <Form.Group className="col-sm-10">
                        <Form.Control 
                            type='text'
                            placeholder='Enter State Name'
                            value={searchLocation}
                            onChange={(e) => setSearchLocation(e.target.value)}
                        />     
                            {/* {(showPlaces && locations.length > 0) &&
                                <div className="places--list">
                                    {locations.map((suggestion, pIdx) => (
                                    <span
                                        key={pIdx}
                                        onClick={() => handleSelectPlace(suggestion)}
                                    >
                                        {suggestion.description}
                                    </span>
                                    ))}
                                </div>
                            }    */}
                    </Form.Group>
                    <Button disabled={loading.save || !searchLocation.toString().trim()} onClick={() => searchLocation.toString().trim() !== '' ? saveLocation() : null }>{loading.save ? 'Saving...': 'Save'}</Button>
                </div>
            </div>
            <div className='p-3'>
                {(locations && locations.length > 0 ) ? renderTable() : <h5 className='text-center'>No Locations Found</h5>}
            </div>

            {/* {showModal && renderModal()} */}
        </div>
    )
}

export default BlockingLocationManagement;