import CryptoJS from 'crypto-js';
import jwt_decode from "jwt-decode";
import { setAuthorization } from './api/index';

export function getSubscriptionSession() {
  return localStorage.getItem('_paySubs');
}

export function setSubscriptionSession(open) {
  return localStorage.setItem('_paySubs', open);
}

export function removeSubscriptionSession(){
    return localStorage.removeItem('_paySubs');
}

export function getAppId() {
  return process.env.REACT_APP_SECRET_KEY || 'INVALID';
}

export function getToken() {
  return localStorage.getItem('accessToken');
}

export function notAllowAccess(access, empRole){
  /** Route access alow for manager, if current user is broker/vendor then not allow */
  if(access === 'managers'){
    return ['broker', 'vendor'].includes(empRole);
  }

  return false
}


export function encodeJWT(payload, KEY = getAppId()) {
  try {
    const encodedPayload = CryptoJS.AES.encrypt(JSON.stringify(payload), KEY).toString();
    const encodedPayloadString = encodedPayload.replace(/\+/g, '~').replace(/\//g, '!').replace(/=/g, '_');
    return encodedPayloadString;
  } catch (error) {
    error.message = 'Data encryption failed';
    return false;
  }
}

export function decodeJWT(initialToken, KEY = getAppId()) {
  try {
    const token = initialToken.replace(/~/g, '+').replace(/!/g, '/').replace(/_/g, '=');
    const bytes = CryptoJS.AES.decrypt(token, KEY);
    const playload = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return playload;
  } catch (error) {
    error.message = 'Invalid secret key';
    return false;
  }
}

export function makeWebId(length) {
  const result = [];
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
}

export function currentUser() {
  return JSON.parse(localStorage.getItem('accessToken'));
}

export function isAuth() {
  try {
    const token = localStorage.getItem('accessToken');
    if (token) {
      return jwt_decode(token);
    }
    return false;
  } catch (err) {
    return false;
  }
}

export function login(token, appId = '') {
  localStorage.setItem('accessToken', token);
  localStorage.setItem('appId', appId);
  setAuthorization();
  window.location.replace('/')
  return true;
}

export function setRemember(user = {}) {
  localStorage.setItem('userRemember', JSON.stringify(user || isAuth()));
  return true;
}

export function removeRemember() {
  localStorage.removeItem('userRemember');
  return true;
}

export function logout() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('appId');
  removeSubscriptionSession();
  setAuthorization();
  setTimeout(() => {
    window.location.href = `${window.location.origin}/`;
  }, 500);
  return true;
}
