module.exports = {
    "states": [
      {
        "key_name": "alabama",
        "display_name": "Alabama",
        "area_sq_miles": 52420,
        "population": 5039877
      },
      {
        "key_name": "alaska",
        "display_name": "Alaska",
        "area_sq_miles": 665384,
        "population": 732984
      },
      {
        "key_name": "arizona",
        "display_name": "Arizona",
        "area_sq_miles": 113990,
        "population": 7276316
      },
      {
        "key_name": "arkansas",
        "display_name": "Arkansas",
        "area_sq_miles": 53179,
        "population": 3025891
      },
      {
        "key_name": "california",
        "display_name": "California",
        "area_sq_miles": 163696,
        "population": 39613493
      },
      {
        "key_name": "colorado",
        "display_name": "Colorado",
        "area_sq_miles": 104094,
        "population": 5893634
      },
      {
        "key_name": "connecticut",
        "display_name": "Connecticut",
        "area_sq_miles": 5543,
        "population": 3605944
      },
      {
        "key_name": "delaware",
        "display_name": "Delaware",
        "area_sq_miles": 2489,
        "population": 1008359
      },
      {
        "key_name": "florida",
        "display_name": "Florida",
        "area_sq_miles": 65757,
        "population": 21992985
      },
      {
        "key_name": "georgia",
        "display_name": "Georgia",
        "area_sq_miles": 59425,
        "population": 10799566
      },
      {
        "key_name": "hawaii",
        "display_name": "Hawaii",
        "area_sq_miles": 10932,
        "population": 1441553
      },
      {
        "key_name": "idaho",
        "display_name": "Idaho",
        "area_sq_miles": 83569,
        "population": 1900923
      },
      {
        "key_name": "illinois",
        "display_name": "Illinois",
        "area_sq_miles": 57914,
        "population": 12569321
      },
      {
        "key_name": "indiana",
        "display_name": "Indiana",
        "area_sq_miles": 36420,
        "population": 6805985
      },
      {
        "key_name": "iowa",
        "display_name": "Iowa",
        "area_sq_miles": 56273,
        "population": 3193079
      },
      {
        "key_name": "kansas",
        "display_name": "Kansas",
        "area_sq_miles": 82278,
        "population": 2934582
      },
      {
        "key_name": "kentucky",
        "display_name": "Kentucky",
        "area_sq_miles": 40408,
        "population": 4509394
      },
      {
        "key_name": "louisiana",
        "display_name": "Louisiana",
        "area_sq_miles": 52378,
        "population": 4624047
      },
      {
        "key_name": "maine",
        "display_name": "Maine",
        "area_sq_miles": 35380,
        "population": 1372247
      },
      {
        "key_name": "maryland",
        "display_name": "Maryland",
        "area_sq_miles": 12406,
        "population": 6177224
      },
      {
        "key_name": "massachusetts",
        "display_name": "Massachusetts",
        "area_sq_miles": 10554,
        "population": 7033469
      },
      {
        "key_name": "michigan",
        "display_name": "Michigan",
        "area_sq_miles": 96714,
        "population": 10045029
      },
      {
        "key_name": "minnesota",
        "display_name": "Minnesota",
        "area_sq_miles": 86936,
        "population": 5700671
      },
      {
        "key_name": "mississippi",
        "display_name": "Mississippi",
        "area_sq_miles": 48432,
        "population": 2961279
      },
      {
        "key_name": "missouri",
        "display_name": "Missouri",
        "area_sq_miles": 69707,
        "population": 6160281
      },
      {
        "key_name": "montana",
        "display_name": "Montana",
        "area_sq_miles": 147040,
        "population": 1103187
      },
      {
        "key_name": "nebraska",
        "display_name": "Nebraska",
        "area_sq_miles": 77348,
        "population": 1961504
      },
      {
        "key_name": "nevada",
        "display_name": "Nevada",
        "area_sq_miles": 110572,
        "population": 3233960
      },
      {
        "key_name": "new_hampshire",
        "display_name": "New Hampshire",
        "area_sq_miles": 9349,
        "population": 1389741
      },
      {
        "key_name": "new_jersey",
        "display_name": "New Jersey",
        "area_sq_miles": 8723,
        "population": 9288994
      },
      {
        "key_name": "new_mexico",
        "display_name": "New Mexico",
        "area_sq_miles": 121590,
        "population": 2120220
      },
      {
        "key_name": "new_york",
        "display_name": "New York",
        "area_sq_miles": 54555,
        "population": 20201249
      },
      {
        "key_name": "north_carolina",
        "display_name": "North Carolina",
        "area_sq_miles": 53819,
        "population": 10600823
      },
      {
        "key_name": "north_dakota",
        "display_name": "North Dakota",
        "area_sq_miles": 70698,
        "population": 774948
      },
      {
        "key_name": "ohio",
        "display_name": "Ohio",
        "area_sq_miles": 44826,
        "population": 11714618
      },
      {
        "key_name": "oklahoma",
        "display_name": "Oklahoma",
        "area_sq_miles": 69899,
        "population": 3980783
      },
      {
        "key_name": "oregon",
        "display_name": "Oregon",
        "area_sq_miles": 98379,
        "population": 4318492
      },
      {
        "key_name": "pennsylvania",
        "display_name": "Pennsylvania",
        "area_sq_miles": 46054,
        "population": 12964056
      },
      {
        "key_name": "rhode_island",
        "display_name": "Rhode Island",
        "area_sq_miles": 1545,
        "population": 1106350
      },
      {
        "key_name": "south_carolina",
        "display_name": "South Carolina",
        "area_sq_miles": 32020,
        "population": 5190705
      },
      {
        "key_name": "south_dakota",
        "display_name": "South Dakota",
        "area_sq_miles": 77116,
        "population": 909824
      },
      {
        "key_name": "tennessee",
        "display_name": "Tennessee",
        "area_sq_miles": 42144,
        "population": 6975218
      },
      {
        "key_name": "texas",
        "display_name": "Texas",
        "area_sq_miles": 268596,
        "population": 29730311
      },
      {
        "key_name": "utah",
        "display_name": "Utah",
        "area_sq_miles": 84897,
        "population": 3360237
      },
      {
        "key_name": "vermont",
        "display_name": "Vermont",
        "area_sq_miles": 9616,
        "population": 643503
      },
      {
        "key_name": "virginia",
        "display_name": "Virginia",
        "area_sq_miles": 42774,
        "population": 8654542
      },
      {
        "key_name": "washington",
        "display_name": "Washington",
        "area_sq_miles": 71298,
        "population": 7887965
      },
      {
        "key_name": "west_virginia",
        "display_name": "West Virginia",
        "area_sq_miles": 24230,
        "population": 1793716
      },
      {
        "key_name": "wisconsin",
        "display_name": "Wisconsin",
        "area_sq_miles": 65496,
        "population": 5851754
      },
      {
        "key_name": "wyoming",
        "display_name": "Wyoming",
        "area_sq_miles": 97813,
        "population": 578803
      }
    ]
  }