import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Card, CardContent, Typography, Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { getAllSellers } from "../../redux/actions/SellerUser.action";
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, Box } from '@mui/material';

// State Mapping Object
const stateMapping = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'FL': 'Florida',
    'GA': 'Georgia',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PA': 'Pennsylvania',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming',
};

const stateAbbreviations = Object.keys(stateMapping);
const stateNames = Object.values(stateMapping);

const normalizeState = (state) => {
    state = state.trim().toUpperCase();

    // Check if the state is already an abbreviation
    if (stateAbbreviations.includes(state)) {
        return state;
    }

    // Check if the state is a full name and convert to abbreviation
    const index = stateNames.findIndex(name => name.toUpperCase() === state);
    if (index !== -1) {
        return stateAbbreviations[index];
    }

    // If neither, try to match common variations (like "Tx", "texas", etc.)
    const abbreviation = stateAbbreviations.find(abbr => state.startsWith(abbr));
    return abbreviation || state;
};

const Dashboard = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sellerState = useSelector(state => state.sellerUser);
    const [stateCounts, setStateCounts] = useState({});
    const [servicesByState, setServicesByState] = useState(null);
    const [overallServiceCount, setOverallServiceCount] = useState(null);
    const [pendingEmailCount, setPendingEmailCount] = useState(0);


    const [incompleteProfileCount, setIncompleteProfileCount] = useState(0);
    const [completedProfileCount, setCompletedProfileCount] = useState(0);


    const [doulasByState, setDoulasByState] = useState(0);
    const [totalDoulasCount, setTotalDoulasCount] = useState(0);
    const [incompleteServicesCount, setIncompleteServicesCount] = useState(0);

    const [completedServicesCount, setCompletedServicesCount] = useState(0);

    const [incompleteSchedulesCount, setIncompleteSchedulesCount] = useState(0);
    const [completedSchedulesCount, setCompletedSchedulesCount] = useState(0);


    const [selectedState, setSelectedState] = useState(null);
    const [open, setOpen] = useState(false);

    // Fetch sellers only once when the component mounts
    useEffect(() => {
        dispatch(getAllSellers({}));
    }, [dispatch]);


    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        if (sellerState.getAllSellerUser && sellerState.getAllSellerUser.users) {
            let pendingCount = 0;
            let incompleteProfileCountTemp = 0;
            let completeProfileCountTemp = 0;

            let incompleteServicesCountTemp = 0;
            let completeServicesCountTemp = 0;

            let incompleteSchedulesCountTemp = 0;
            let completeSchedulesCountTemp = 0;

            // Variables for counting services by state and overall services count
            const servicesByState = {};
            const overallServiceCount = {}; // New variable to count services globally
            let inCompleteProfileContact = [];
            let totalTestArr = []
                
            const counts = sellerState.getAllSellerUser.users.reduce((acc, seller) => {
                // Skip inactive sellers and test accounts
                if (!seller.is_active || seller.isTestAccount) {
                    return acc;
                }

                let state = seller.address?.state;
                const specialities = seller.speciality?.map(spec => spec.name).join(', ');

                if (seller.emailVerification === "pending") {
                    pendingCount += 1;
                }

                if (!seller?.is_profile_completed) {
                    const pushData = {
                        email: seller.email,
                        name: seller.name,
                    }
                    inCompleteProfileContact.push(pushData);
                    incompleteProfileCountTemp += 1;
                }
                if (seller?.is_profile_completed) {
                    completeProfileCountTemp += 1;
                }

                // Check if services are incomplete or only contain Consultation
                const services = seller.services;
                const hasOnlyConsultation = services.length === 1 && services[0]?.category?.name === "Consultation";
                
                if (seller.emailVerification !== 'verified' || seller.isTestAccount || !seller.is_active || services.length === 0 || hasOnlyConsultation || !seller.subscription.subscribed) {
                    
                    if (seller?.is_profile_completed) {
                        const pushData = {
                            email: seller.email,
                            name: seller.name,
                        }
                        inCompleteProfileContact.push(pushData);
                    }
                    console.log('incompleteServicesCountTemp - seller', seller)
                    incompleteServicesCountTemp += 1;
                } else {
                    totalTestArr.push(seller)
                    console.log('completeServicesCountTemp - seller', seller)
                    completeServicesCountTemp += 1;
                }
                console.log('totalTestArr',totalTestArr);


                if (seller?.totalExistSchedules === 0) {
                    incompleteSchedulesCountTemp += 1;
                }
                if (seller?.totalExistSchedules > 0) {
                    completeSchedulesCountTemp += 1;
                }

                if (state) {
                    const normalizedState = normalizeState(state);

                    // Initialize state in accumulator if not present
                    if (!acc[normalizedState]) {
                        acc[normalizedState] = {
                            count: 0,
                            professionals: []
                        };
                    }

                    // Initialize services in accumulator if not present
                    if (!servicesByState[normalizedState]) {
                        servicesByState[normalizedState] = {};
                    }

                    // Count each service category for the state and overall
                    services.forEach(service => {
                        const categoryName = service.category?.name;

                        // Update state-level service count
                        if (!servicesByState[normalizedState][categoryName]) {
                            servicesByState[normalizedState][categoryName] = 0;
                        }
                        servicesByState[normalizedState][categoryName] += 1;

                        // Update overall service count
                        if (!overallServiceCount[categoryName]) {
                            overallServiceCount[categoryName] = 0;
                        }
                        overallServiceCount[categoryName] += 1;
                    });

                    // Add the seller to the normalized state entry
                    acc[normalizedState].professionals.push({
                        name: seller.name,
                        speciality: specialities,
                        emailVerification: seller.emailVerification,
                        isProfileCompleted: seller.is_profile_completed
                    });

                    acc[normalizedState].count += 1;
                }
                return acc;
            }, {});

            // Sort the states by the number of professionals
            const sortedCounts = Object.keys(counts)
                .sort((a, b) => counts[b].count - counts[a].count)
                .reduce((acc, key) => {
                    acc[key] = counts[key];
                    return acc;
                }, {});

            setPendingEmailCount(pendingCount);
            setIncompleteProfileCount(incompleteProfileCountTemp);
            setCompletedProfileCount(completeProfileCountTemp);
            setIncompleteServicesCount(incompleteServicesCountTemp);
            setCompletedServicesCount(completeServicesCountTemp);
            setIncompleteSchedulesCount(incompleteSchedulesCountTemp);
            setCompletedSchedulesCount(completeSchedulesCountTemp);

            // Update the state with the service counts by state and overall
            console.log('servicesByState', servicesByState);
            console.log('overallServiceCount', overallServiceCount); // For debugging
            setStateCounts(sortedCounts);
            setServicesByState(servicesByState);
            setOverallServiceCount(overallServiceCount);  // New state for overall service count
        }
    }, [sellerState.getAllSellerUser]);


    const handleOpen = (state) => {
        setSelectedState(state);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedState(null);
    };

    const handlePendingEmailClick = () => {
        navigate('/seller/list');
    };

    if (!sellerState.getAllSellerUser) {
        return <div>Loading...</div>;
    }

    const renderServicesByState = () => {
        if (servicesByState) {

            return Object.keys(servicesByState).map((state, index) => (
                <Col sm={12} md={6} lg={4} key={index}>
                    <Card sx={{ maxWidth: 345, margin: '1rem' }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {stateMapping[state] || state} - Services
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {Object.keys(servicesByState[state]).length > 0 ? (
                                    Object.entries(servicesByState[state]).map(([service, count]) => (
                                        <div key={service}>
                                            {service}: {count} {count > 1 ? 'providers' : 'provider'}
                                        </div>
                                    ))
                                ) : (
                                    <div>No services available</div>
                                )}
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
            ));
        }
    };


    const totalStates = Object.keys(stateCounts).length;

    console.log('    totalStates', totalStates);


    return (
        <div className="dashboardMainContent">

            <Box sx={{ width: '100%', overflowX: 'auto' }}> {/* Added overflowX for scrollable effect */}
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    variant="scrollable"  // Allows tabs to scroll
                    scrollButtons="auto"  // Shows scroll buttons automatically
                    centered={false} // Adjust to `false` to ensure proper scrolling 
                >
                    <Tab label="Account Statuses" />
                    <Tab label="Total States" />
                    <Tab label="Overall Service Count" />
                    <Tab label="Services By State" />
                    <Tab label="Reminders" />
                </Tabs>


                <Box sx={{ padding: '1rem' }}>
                    {selectedTab === 0 && ( //Account statsuses
                        <Row>

                            <Col sm={12} md={6} lg={4}>
                                <Card sx={{ maxWidth: 345, margin: '1rem' }}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Total Birth Professionals
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {incompleteProfileCount + completedProfileCount} Doulas
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Col>



                            <Col sm={12} md={6} lg={4}>
                                <Card sx={{ maxWidth: 345, margin: '1rem' }}>
                                    <CardContent>
                                        <Typography style={{ textAlign: 'center' }} gutterBottom variant="h5" component="div">
                                            Profile Counts
                                        </Typography>
                                        <div style={{ flexWrap: 'wrap', justifyContent: 'space-between', flexDirection: 'row', textAlign: 'center', display: 'flex' }}>
                                            <div>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Incomplete Profiles
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {incompleteProfileCount} Users
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Completed Profiles
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {completedProfileCount} Users
                                                </Typography>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Col>

                            <Col sm={12} md={6} lg={4}>
                                <Card sx={{ maxWidth: 345, margin: '1rem' }}>
                                    <CardContent>
                                        <Typography style={{ textAlign: 'center' }} gutterBottom variant="h5" component="div">
                                            Services Counts
                                        </Typography>
                                        <div style={{ flexWrap: 'wrap', justifyContent: 'space-between', flexDirection: 'row', textAlign: 'center', display: 'flex' }}>
                                            <div>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Incomplete Services
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {incompleteServicesCount} Users
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Completed Services
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {completedServicesCount} Users
                                                </Typography>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Col>



                            <Col sm={12} md={6} lg={4}>
                                <Card sx={{ maxWidth: 345, margin: '1rem' }}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Pending Email Verifications
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {pendingEmailCount} Users
                                        </Typography>
                                        <Button variant="contained" color="primary" onClick={handlePendingEmailClick}>
                                            Send email reminder
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Col>


                            {/* Add Doulas by State Dashboard Item */}
                            {/* {Object.keys(doulasByState).map((state, index) => (
                                <Col sm={12} md={6} lg={4} key={index}>
                                    <Card sx={{ maxWidth: 345, margin: '1rem' }}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {state} - Doulas
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {doulasByState[state]} Doulas in {stateMapping[state]}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Col>
                            ))} */}








                            <Col sm={12} md={6} lg={4}>
                                <Card sx={{ maxWidth: 345, margin: '1rem' }}>
                                    <CardContent>
                                        <Typography style={{ textAlign: 'center' }} gutterBottom variant="h5" component="div">
                                            Schedules Completed Counts
                                        </Typography>
                                        <div style={{ justifyContent: 'space-between', flexDirection: 'row', textAlign: 'center', display: 'flex' }}>
                                            <div>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Incomplete Schedules
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {incompleteSchedulesCount} Users
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Completed Schedules
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {completedSchedulesCount} Users
                                                </Typography>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    {selectedTab === 1 && (
                        <Row>
                            <Col>

                                <Card sx={{ maxWidth: 345, margin: '1rem' }}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Total State's we're in
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {totalStates}
                                        </Typography>
                                    </CardContent>
                                </Card>



                            </Col>
                            {Object.keys(stateCounts).map((state, index) => (
                                <Col sm={12} md={6} lg={4} key={index}>
                                    <Card sx={{ maxWidth: 345, margin: '1rem' }}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {state}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {stateCounts[state].count} Birth Professionals
                                            </Typography>
                                            <Button variant="contained" color="primary" onClick={() => handleOpen(state)}>
                                                View Specialities
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Col>
                            ))}

                        </Row>
                    )
                    }
                    {selectedTab === 2 && (
                        <Row>
                            {overallServiceCount &&
                                Object.entries(overallServiceCount)
                                    .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
                                    .map(([service, count], index) => (
                                        <Col sm={12} md={6} lg={4} key={index}>
                                            <Card sx={{ maxWidth: 345, margin: '1rem' }}>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        {service}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {count} {count > 1 ? 'providers' : 'provider'}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Col>
                                    ))}
                        </Row>
                    )
                    }
                    {selectedTab === 3 && (
                        <Row>{renderServicesByState()}</Row>
                    )
                    }

                    {selectedTab === 4 && (
                        <Row>
                            <Col>profile reminder</Col>
                            <Col>service reminder</Col>
                            <Col>schedule reminder</Col>
                        </Row>
                    )
                    }
                </Box>
            </Box>


            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>{selectedState} - Specialities</DialogTitle>
                <DialogContent>
                    {selectedState && (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Speciality</TableCell>
                                        <TableCell>Email Verification</TableCell>
                                        <TableCell>Profile Completed</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stateCounts[selectedState].professionals.map((professional, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{professional.name}</TableCell>
                                            <TableCell>{professional.speciality}</TableCell>
                                            <TableCell>{professional.emailVerification}</TableCell>
                                            <TableCell>{professional.isProfileCompleted ? 'Yes' : 'No'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DialogContent>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </Dialog>
        </div>
    );
}

export default Dashboard;