import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Accordion, Card,Dropdown } from "react-bootstrap";
// import { BsChevronDown } from 'react-icons/bs';
import { getSellerService } from "../../redux/actions/SellerUser.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
// import { MdVerified,MdDelete } from 'react-icons/md';
// import toast from "react-hot-toast";

// import { Link } from "react-router-dom";
import { RotatingLines } from 'react-loader-spinner'
// import { BiEdit } from "react-icons/bi";
// import { AiOutlineEye, AiOutlineSend } from "react-icons/ai";
import 'bootstrap/dist/css/bootstrap.min.css';
import { formatPrice } from "utils/validations";
import { serviceTypeName } from "utils/validations";
import { momentFormat } from "utils/validations";

function SellerService() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [ServicesData, setServicesData] = useState([])  //////  services all Data
    const serviceState = useSelector(state => state.sellerUser)

    const settinsDetailsApi = () => {
        dispatch(getSellerService(id))
    }

    useEffect(() => {
        settinsDetailsApi()
    }, [])

    useEffect(() => {
        if (!serviceState.error && serviceState.getServiceSeller && serviceState.getServiceSeller.success) {
            setServicesData(serviceState.getServiceSeller.services);
            setUser(serviceState.getServiceSeller.user)
            setLoading(false)
        }
    }, [serviceState])
    

    return (
        <section className="popular--Wrapper edit--page services-page-all">
            <Container fluid>
                <Row>
                    <Col sm={12} className="px-0">
                        <div className="sidebar--wrapper">
                            <div className="admin--content">
                                <Container>
                                    <Row>
                                        <div className="all-servicess-Wrap d-flex justify-content-between">
                                            <h2>{user ? `"${user.name}"` : ''} Services</h2>
                                            <Button onClick={() => navigate('/seller/list')}>Back</Button>
                                        </div>
                                        {loading 
                                        ?   <div className="loader-main">
                                                <RotatingLines
                                                    strokeColor="grey"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="80"
                                                    visible={true}
                                                />
                                            </div>
                                           
                                        :
                                            <Accordion defaultActiveKey="0" className="services-main-wraper">
                                            {ServicesData && ServicesData?.length ?
                                                <Table striped responsive="sm">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Birth Categories</th>
                                                            <th>Service Name</th>
                                                            <th>Service Description</th>
                                                            <th>Amount</th>
                                                            <th>Service Type</th>
                                                            <th>Is Virtual</th>
                                                            {/* <th>Waiver Disclaimer Policy</th> */}
                                                            <th>Max Capacity</th>
                                                            <th>Registered</th>
                                                            {/* <th className="edit-service">Action</th> */}
                                                        </tr>
                                                    </thead>
                                                    {ServicesData && ServicesData?.map((item, ind) => {
                                                        return (
                                                            <tbody>
                                                                <tr>
                                                                    <td>{ind+1}</td>
                                                                    <td style={{ minWidth: '300px' }}>{typeof item.category === 'object' ? item.category.name : item.category}</td>
                                                                    <td style={{ minWidth: '300px' }}>{item?.bbservice?.name}</td>
                                                                    <td style={{ minWidth: '400px' }}>{item.desc ? item.desc : item?.bbservice?.description}</td>
                                                                    <td>${formatPrice(item.amount)}</td>
                                                                    <td>{serviceTypeName(item?.bbservice?.service_type)}</td>
                                                                    <td>{item.is_virtual ? 'Yes' : 'No'}</td>
                                                                    {/* <td>{item.disclaimer_policy ? <Button onClick={() => handleShowFileView(item.disclaimer_policy_document)}>View</Button> : 'No'}</td> */}
                                                                    <td>{item.max_capacity || 'N/A'}</td>
                                                                    <td style={{ minWidth: '200px' }}>{momentFormat.formatWeekDate(item?.createdAt)}</td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    })}
                                                </Table>
                                                :
                                                <div>
                                                    <h2>Service Not Available</h2>
                                                </div>
                                            }
                                            </Accordion>
                                           
                                        }
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SellerService;