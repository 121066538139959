import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Accordion, Modal, Button , Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import { getUserOrders } from "redux/actions/order.action";
import LoadingView from "components/LoadingView";
import { momentFormat, formatPrice } from "utils/validations";
import { orderStatus , transactionStatus, orderSearchBy} from "utils/status";

import 'bootstrap/dist/css/bootstrap.min.css';
import { checkScrolledToBottom } from "utils/scroll";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const limitPerPage = process.env.REACT_APP_LISTING_LIMIT

function OrderList() {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState({ initial: true, loadMore: false});
    const [ filter, setFilter] = useState({search: '' , value:'' , startDate:'' , endDate: ''})
    const [orderValue , setOrderValue] = useState('') ;
    const [txnValue , setTxnValue] = useState('') ; 
    const [searchBy , setSearchBy] = useState(false);
    const [ orders, setOrders ] = useState([]);
    const [ modal, setModal ] = useState({ buyer: false, seller: false, tnx: false, order: false });
    const [ order, setOrder ] = useState('');
    const [searchBoxValue , setSearchBoxValue] = useState('');
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date (),
            endDate: new Date (),
            key: 'selection',
        },
    ]);

    const [totalPages, setTotalPages] = useState(0);    
    let [page, setPage] = useState(0);

    //** --- Reducer State --- */
    const orderState = useSelector(state => state.order);

    const getOrders = () => {
        dispatch( getUserOrders({limitPerPage, page , ...filter}) );      
    }

    /** ---Add Event Listener --- */
    useEffect(() => {
        // window.addEventListener("scroll", handleScroll);
        // return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    /****** ---------------------------- */

    
    useEffect(() => {
        getOrders();
    }, []);


    /** ----- Reducer State Update ----- */
    useEffect(() => {
        if(!orderState.error){
            const { all } = orderState;

            if(all && all.success){
                if((orders.length < all.totalCount)){
                    setOrders([...orders, ...all.orders]);
                    var ordersList = orders ;
                    Array.prototype.push.apply(ordersList, all.orders);
                    ordersList = ordersList.filter((v, i, a) => a.findIndex(t => (t._id === v._id)) === i)
                    setOrders(ordersList);
                }else{
                    setOrders(all.orders);
                }
                setTotalPages(Math.ceil(all.totalCount/limitPerPage));
                setLoading({ ...loading , initial: false, loadMore: false });
                if(page > 0 ){
                    window.scrollTo(0, document.documentElement.offsetHeight - 20);
                }
            }
        }
        if(orderState.error){
            setLoading({ ['initial']: false, ['loadMore']: false });
            toast.error(orderState.error.message || 'Oops, something goes wrong');
        }
    }, [orderState]);


    useEffect(() => {
        if(page < totalPages){
            getOrders();
        }else{
            setLoading(prevState => ({
                ...prevState,
                loadMore: false
            }));
        }
    }, [page])

    /** ---- Get More Services ---- */
    useEffect(() => {
        if(!loading.loadMore) return;
        // setTimeout(() => {
        //     setPage(page+1)
        // }, 2000);

        if(page < totalPages){
            setTimeout(() => {
                setPage(page + 1)
            }, 1200);
        }else{
            setLoading(prevState => ({
                ...prevState,
                loadMore: false
            }));
        }   
    }, [loading.loadMore]);

    /** ---- Handle Scroll ---- */
    function handleScroll() {
        /** --- Detect Bottom Window --- */
        // const bottomOfWindow = scrollHeight - Math.round(scrollTop)  === offsetHeight;
        const bottomOfWindow =  checkScrolledToBottom() ; 
        /** --- Detect Top Of Window */
        // const topOfWindow = Math.round(scrollTop) - innerHeight === offsetHeight ;
        if (bottomOfWindow) {
            setLoading(prevState => ({
                ...prevState,
                loadMore: true
            }));
        }
    }

    const handleShowModal = (order) => {
        setModal({ order: true });
        setOrder(order);
    }

    const handleCloseModal = () => {
        setModal({ order: false });
        setOrder(null);
    }


    useEffect(() => {
        setLoading(prevState => ({
            ...prevState,
            initial: true
        }));
        setPage(0);
        setTotalPages(0);
        setOrders([]);
        // Define a delay for debouncing (e.g., 500 milliseconds)
        const delay = 700;
    
        // Use a timer to delay the API call after typing stops
        const timerId = setTimeout(() => {
            getOrders();
            // if (filter.search.trim() !== '') {
            // }
        }, delay);
    
        // Clear the timer on component unmount or when input changes
        return () => clearTimeout(timerId);
    }, [filter]);



    const handleChange = (e) =>{
        const {name , value} = e.target ; 
        setPage(0);
        setTotalPages(0);
        setOrders([]);
        setSearchBy('')
        if(name === 'orderStatus'){
            setTxnValue('')
            if(value === 'null'){
                setLoading({...loading, initial:true })
                setFilter({search:'' , value:''})
            }else{
                setLoading({...loading, initial:true })
                setFilter({search:'order_status' , value:value ,})
            }
           
            setOrderValue(value)
        }
        if(name === 'transactionStatus'){
            setOrderValue('')
            if(value === 'null'){
                setLoading({...loading, initial:true })
                setFilter({search:'' , value:''})
            }else{
                setLoading({...loading, initial:true })
                setFilter({search:'transaction_status' , value:value})
            }
           
            setTxnValue(value)
        }
    }

    /**************   using for select the search by type  *********************/

    const handleSearchBy = (e) =>{
        const {value} = e.target
        setSearchBy(value)
        setOrderValue('')
        setTxnValue('')
        setPage(0);
        setTotalPages(0);
        setOrders([]);
        setLoading({...loading, initial:true })
        setFilter({search:'', value:'' , startDate: '' , endDate : ''});
        setDateRange( [
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
        ]);
        
    }

/***********  using for handle the date filter  ******************/
    const handleDateRangeChange = (ranges) =>{
        const { startDate, endDate } = ranges.selection;
        setPage(0);
        setTotalPages(0);
        setOrders([]);
        setDateRange([ranges.selection]);
        setFilter({ search:searchBy, startDate, endDate });
    }

    /********   using for search the orders by orderId or txnId  **************/
    const handleSearchBox = (e) =>{
        const {name , value} = e.target ; 
        setPage(0);
        setTotalPages(0);
        setOrders([]);
        if(value !== ''){
            setFilter({search:searchBy , value:value});
            setSearchBoxValue(value)
        }else{
            setFilter({search:'' , value:''});
            setSearchBoxValue('')
        }
        
    }

    /*************  using for show the search box or date filter according to seacrchBy value */
    const renderSearchBox = () =>{
        if(searchBy){
            if(searchBy === 'order_id' || searchBy === 'tnxId'){
                return(
                    <div className="filter--type mt-2">
                        <Form.Control
                            type="text"
                            placeholder="Search By Order Id or Transaction Id"
                            name="search"
                            value={searchBoxValue}
                            onChange={(e) => handleSearchBox(e)}
                        />
                    </div>
                )
            }else{
                return(
                    <div className="range__picker">
                        <DateRangePicker 
                            ranges={dateRange}
                            onChange={handleDateRangeChange}
                        />
                    </div>
                )
            }
        }

    }



    const renderTable = () => {
        return (
            <>
                <Table striped responsive="sm">
                    <thead>
                        <tr>
                            <th># Order Id</th>
                            <th>Order Date</th>
                            <th>Appointment Date/Time</th>
                            <th>Service Category</th>
                            <th>Service Specialty</th>
                            <th>Customer</th>
                            <th>Seller</th>
                            <th>Seller Type</th>
                            <th>Service Price</th>
                            <th>BB Fees</th>
                            <th>Stripe Fees</th>
                            <th>Donation</th>
                            <th>Total</th>
                            <th>Payout</th>
                            {/* <th>Payment Mode</th> */}
                            <th>Transaction Id</th>
                            <th>Transaction Status</th>
                            <th>Order Status</th>
                            <th className="edit-service">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        { orders.map((_order, orderIndex) => {
                            console.log('_order.associatedWithService',_order.associatedWithService);
                            return (
                                <tr key={orderIndex}>
                                    <td aria-description="order_id">{_order.order_id}</td>
                                    <td aria-description="order_date">{momentFormat.dateTimeFormat(_order.createdAt)}</td>
                                    <td aria-description="appointment_date">
                                        {momentFormat.fullDate(_order.timeSlot.date)}
                                        {(_order.timeSlot.start_time || _order.timeSlot.end_time) && 
                                        <p>
                                            {`${momentFormat.timeInHHmm(_order.timeSlot.start_time)} - ${momentFormat.timeInHHmm(_order.timeSlot.end_time)}`}
                                        </p>
                                        }
                                    </td>
                                    <td aria-description="category">{_order.associatedWithService.bbservice.name}</td>
                                    <td aria-description="specialty">{_order.associatedWithService.category.name}</td>
                                    <td aria-description="customer">{_order.associatedWithUser.name}</td>
                                    <td aria-description="seller">{_order.associatedWithService.associatedWithUser.name}</td>
                                    <td aria-description="seller_type">{_order.associatedWithService.associatedWithUser.role}</td>
                                    <td aria-description="price">${formatPrice(_order.price)}</td>
                                    
                                    <td aria-description="bbfees">
                                        {_order.bbd_fees_amount 
                                        ? typeof _order.bbd_fees_amount === 'object'
                                            ?   <>
                                                    <td>Family Trans: ${_order.bbd_fees_amount.buyer_fees}</td>
                                                    <td>Business Trans : ${_order.bbd_fees_amount.buisness_fees}</td>
                                                    <td>Total: ${_order.bbd_fees_amount.total_fees}</td>
                                                </>
                                            :   `$${_order.bbd_fees_amount}`

                                        : 'N/A'
                                        }
                                    </td>
                                    <td aria-description="stripe-fees">
                                        {(_order.stripe_fees && ['online'].includes(_order.mode)) ? '$'+_order.stripe_fees.fees+`(${_order.stripe_fees.is_stripe_fees_charge_by_customer ? 'Customer' : 'Seller/Staff'})` : 'N/A'}
                                    </td>

                                    <td aria-description="donation-amount">${formatPrice(_order.donation_amount)}</td>
                                    {/* Total Price Taken From Buyer ( Including Service Fees ) */}
                                    <td aria-description="total">${formatPrice(_order.total)}</td>
                                    <td aria-description="payout">
                                        {(_order.associatedWithService.associatedWithUser.role === 'staff' && _order.payout) &&
                                            <td>Staff: ${_order.payout.staff}</td>
                                        }
                                        <td>Seller: ${_order.payout?.seller || 'N/A' }</td>
                                        
                                    </td>
                                    {/* <td aria-aria-description="payment_mode">{['offline'].includes(order.mode)}</td> */}
                                    <td aria-description="transaction_id">{_order.tnxId ? _order.tnxId.payment_tnx_id : 'N/A'}</td>
                                    <td aria-description="transaction_status">
                                        {/* <span className={`status-services status--${_order.tnxId?.status}`}>    
                                            {_order.tnxId 
                                            ?   _order.tnxId.status === 'refund_initiated'
                                                ? 'Refund Initiated'
                                                :  _order.tnxId.status
                                            :   'N/A'
                                            }
                                        </span> */}
                                        {['offline'].includes(_order.mode)
                                        ?   <span className='status-services'>Offline</span>
                                        :   <span className={`status-services status--${_order.tnxId?.status || 'failed'}`}>
                                                {_order.tnxId?.status === 'refund_initiated' ? 'Refund Initiated' : _order.tnxId?.status || 'failed'}
                                            </span>
                                        }
                                    </td>
                                    <td aria-description="order_status">
                                        <span className={`status-services status--${_order.status}`}>
                                            {_order.status}
                                        </span>
                                    </td>
                                    <td>
                                        <Button
                                            onClick={() => handleShowModal(_order)}
                                        >
                                            View
                                        </Button>
                                    </td>
                                    {/* <td className="edit-service"> 
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <AiOutlineEye />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <BiEdit /> Edit
                                            </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {loading.loadMore ? <h2> Loading... </h2> : null}
            </>
        )
    }

    const showAddress = (address) => {
        let _add = ''
        if (address.street) {
            _add += address.street
            _add += ', '
        }

        if (address.landmark) {
            _add += address.landmark
            _add += ', '
        }
        if (address.city) {
            _add += address.city
            _add += ', '
        }
        if (address.state) {
            _add += address.state
            _add += ', '
        }

        if (address.country) {
            _add += address.country
            _add += ', '
        }

        if (address.postalCode) {
            _add += address.postalCode
            _add += ', '
        }

        return _add
    }

    const renderOrderDetails = () => {
        return (
            <Modal
                size="lg"
                show={modal.order}
                onHide={() => handleCloseModal()}
                backdrop="static"
                keyboard={false}
            >
                {/* <Modal.Header closeButton closeLabel='Close' title={order._id}/> */}
                <Modal.Header closeButton>
                    <Modal.Title className='order-id-popup-o'>#{order.order_id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6><strong> Order Details </strong></h6>
                    <div className='table-model-popup'>
                        <table className='striped table'>
                            <tr>
                                <td>Status</td>
                                <td><span className={`status-services status--${order.status}`}>{order.status} </span></td>
                            </tr>
                            <tr>
                                <td>Service</td>
                                <td>{order.associatedWithService.bbservice.name}</td>
                            </tr>
                            <tr>
                                <td>Order Date</td>
                                <td>{momentFormat.dateTimeFormat(order.createdAt)}</td>
                            </tr>
                            <tr>
                                <td>Appointment Date</td>
                                <td>
                                    <span className='mr-3'>
                                        {momentFormat.fullDate(order.timeSlot.date)}
                                    </span>
                                    {(order.timeSlot.start_time && order.timeSlot.end_time) 
                                    && <span>
                                        {order.timeSlot.start_time} - {order.timeSlot.end_time}
                                    </span>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Last Updated
                                </td>
                                <td>
                                    {momentFormat.dateTimeFormat(order.updatedAt)}
                                </td>
                            </tr>
                        </table>
                        <h6><strong>Customer Details</strong></h6>
                        <table className='striped table'>
                            <tbody>
                                <tr>
                                    <td >Name</td>
                                    <td style={{ textTransform: 'capitalize' }}>{order.associatedWithUser.name}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{order.associatedWithUser.email}</td>
                                </tr>
                                <tr>
                                    <td>Contact Number</td>
                                    <td>{order.associatedWithUser.phoneNumber}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Address
                                    </td>
                                    <td>
                                        {Object.values(order.associatedWithUser.address).every(v => v === null || v === '')
                                            ? 'N/A'
                                            : showAddress(order.associatedWithUser.address)
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h6><strong>Business Details</strong></h6>
                        <table className="table striped">
                            <tbody>
                                {(order.associatedWithService.associatedWithUser.associatedWithSeller.length > 0) &&
                                <h6>Service Provider Details</h6>}
                                <tr>
                                    <td>Name</td>
                                    <td>{order.associatedWithService.associatedWithUser.name}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{order.associatedWithService.associatedWithUser.email}</td>
                                </tr>
                                <tr>
                                    <td>Contact Number</td>
                                    <td>{order.associatedWithService.associatedWithUser.phoneNumber}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{order.associatedWithService.associatedWithUser.seller_business_address || showAddress(order.associatedWithService.associatedWithUser.address) }</td>
                                </tr>
                                {(order.associatedWithService.associatedWithUser.associatedWithSeller.length > 0) &&
                                <>
                                    <h6>Seller's Details</h6>
                                    <tr>
                                        <td>Name</td>
                                        <td>{order.associatedWithService.seller.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{order.associatedWithService.seller.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Contact Number</td>
                                        <td>{order.associatedWithService.seller.phoneNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Address</td>
                                        <td>{order.associatedWithService.seller.seller_business_address || 'N/A'}</td>
                                    </tr>
                                </>
                                }
                            </tbody>
                        </table>
                        <h6><strong>Payment Details</strong></h6>
                        <table className='table striped'>
                            {['offline'].includes(order.mode) 
                            ?   <tbody>
                                    <tr>
                                        <td>Payment Mode</td>
                                        <td>Offline</td>
                                    </tr>
                                </tbody>
                            :   <tbody>
                                    <tr>
                                        <td>Transaction Id</td>
                                        <td>{order.tnxId.payment_tnx_id}</td>
                                    </tr>
                                    <tr>
                                        <td>Transaction Date</td>
                                        <td>{momentFormat.dateTimeFormat(order.tnxId.createdAt)}</td>
                                    </tr>
                                    <tr>
                                        <td>Amount</td>
                                        <td>${formatPrice(order.tnxId.total)}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>
                                            <span className={`status-services status--${order.tnxId.status}`} >
                                                {order.tnxId.status === 'refund_initiated' ? 'Refund Initiated' : order.tnxId.status}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Last Updated</td>
                                        <td>{momentFormat.dateTimeFormat(order.tnxId.updatedAt)}</td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer className='button-order-deatils-v'>
                    {order.status === 'initiated' &&
                        <Button
                            className='disablebutton'
                            onClick={() => (checkToApprove || loading['approve']) ? null : handleApprove(order._id)}
                            disabled={checkToApprove || loading['approve']}
                        >
                            {loading['approve'] ? 'wait...' : 'Approve Order'}
                        </Button>
                    }
                    {order.status === 'accepted' &&
                        <Button
                            className='disablebutton'
                            onClick={() => loading['complete'] ? null : handleCompleteOrder(order._id)}
                            disabled={loading['complete'] || checkTimeSlotPassedToCompleteOrder(order)}
                        >
                            {loading['complete'] ? 'Wait...' : 'Complete Order'}
                    </Button>}
                    {!['accepted', 'pending', 'completed', 'cancelled'].includes(order.status) &&
                        <Button
                            className='disablebutton'
                            onClick={() => (checkToApprove || loading['cancel']) ? null : setShowCancelModal(true) }
                            disabled={checkToApprove || loading['cancel']}
                        >
                            {loading['cancel'] ? 'wait...' : 'Cancel Order'}
                        </Button>
                    }
                </Modal.Footer> */}
            </Modal>
        )
    }

    return (
        <section className="popular--Wrapper edit--page services-page-all">
            <Container fluid>
                <Row>
                    <Col sm={12} className="px-0">
                        <div className="sidebar--wrapper">
                            <div className="admin--content">
                                <Container>
                                    <Row>
                                        <div className="all-servicess-Wrap">
                                            <h2>Orders</h2>
                                        </div>
                                        <div className="service--filter">
                                            <div className="filter--type">
                                                <Form.Label>Order Status</Form.Label>
                                                <Form.Select 
                                                    aria-label="service type"
                                                    name="orderStatus"
                                                    className="filter--service--type"
                                                    onChange={(e)=>handleChange(e)}
                                                    value={orderValue}

                                                >
                                                    <option value="null">All</option>
                                                    {orderStatus.map((item ,idx)=>{
                                                        return (
                                                            <option value={item.value} key={idx}>{item.label}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </div> 
                                            <div className="filter--type ms-3 me-3">
                                                <Form.Label>Transaction Status</Form.Label>
                                                <Form.Select 
                                                    aria-label="service type"
                                                    name="transactionStatus"
                                                    className="filter--service--type"
                                                    onChange={(e)=>handleChange(e)}
                                                    value={txnValue}

                                                >
                                                    <option value="null">All</option>
                                                    {transactionStatus.map((item ,idx)=>{
                                                        return (
                                                            <option value={item.value} key={idx}>{item.label}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </div> 
                                            <div className="filter--type search__by">
                                                <Form.Label> Search By </Form.Label>
                                                <Form.Select 
                                                    aria-label="service type"
                                                    name="searchBy"
                                                    className="filter--service--type"
                                                    onChange={(e)=>handleSearchBy(e)}
                                                    value={searchBy}

                                                >
                                                    <option value="">Select</option>
                                                    {orderSearchBy.map((item ,idx)=>{
                                                        return (
                                                            <option value={item.value} key={idx}>{item.label}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                {renderSearchBox()}
                                            </div> 
                                        </div>
                                        {loading.initial 
                                            ?   <LoadingView />
                                            :   <Accordion defaultActiveKey="0" className="services-main-wraper mb-3">
                                                    {orders.length > 0
                                                        ?   renderTable()
                                                        :   <h4>No Record Available</h4>
                                                    }
                                                </Accordion>
                                        }
                                    </Row>
                                </Container>
                                { modal.order && renderOrderDetails()}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default OrderList;